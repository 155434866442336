import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdOutlinePermMedia } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  uploadFile,
} from "../../Apis/firebaseApi";
import BackButtons from "../../Components/Buttons/BackButtons";
import { CheckIcon } from "@chakra-ui/icons";
import useUserStore from "../../Hooks/Zustand/Store";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../Config/firebase";

function EditMenuPage() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [stationsData, setStationsData] = useState();
  const [outLetsData, setOutletsData] = useState([]);
  const [satuan, setSatuan] = useState([]);
  const [files, setFiles] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const [prices, setPrices] = useState([]);
  const globalState = useUserStore();
  const param = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const fetchDataProduct = async () => {
    try {
      const q = query(
        collection(db, "suppliers"),
        where("companyId", "==", globalState.currentCompany),
        where("projectId", "==", globalState.currentProject),
        where("outletId", "==", globalState.currentOutlet)
      );
      const querySnapshot = await getDocs(q);
      const suppliersData = [];

      querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        suppliersData.push({ id: doc.id, ...doc.data() });
      });
      setSupplier(suppliersData);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  };

  useEffect(() => {
    fetchDataProduct();
  }, [
    globalState.currentCompany,
    globalState.currentProject,
    globalState.currentOutlet,
  ]);

  const categoryData = [
    "makanan",
    "minuman",
    "perlengkapan makan",
    "perlengkapan minum",
    "bumbu",
    "alat makan",
  ];

  const validateForm = () => {
    if (data?.name.length === 0) {
      toast({
        title: "Error",
        description: "Masukan Nama Menu",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.description === undefined) {
      toast({
        title: "Error",
        description: "Pilih description",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.category === undefined) {
      toast({
        title: "Error",
        description: "Masukan Kategori",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.stock === undefined) {
      toast({
        title: "Error",
        description: "Masukan Stock",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      handleSave();
    }
  };

  const handleSave = async () => {
    if (filesImage[0]) {
      const resImage = await uploadFile(data.name, "warehouse", filesImage[0]);
      data.image_url = resImage;
    }
    data.companyId = globalState.currentCompany;
    setDocumentFirebase("warehouse", param.id, data);
    navigate(-1);
    toast({
      title: "Success",
      description: "Menu sudah di buat dan di tambahkan",
      position: "top",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;
    if (newFiles.length) {
      const newFileArray = [...files];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);
        };
      }
      setFilesImage(newFiles); // Mengubah state filesImage menjadi array baru dari selectedFiles
    }
  };

  const getdata = () => {
    getSingleDocumentFirebase("rms", globalState.currentProject).then((x) => {
      setStationsData(x.stations);
    });

    getSingleDocumentFirebase("warehouse", param.id).then((x) => {
      setData(x);
    });

    getCollectionFirebase("outlets", [
      {
        field: "companyId",
        operator: "==",
        value: globalState.currentCompany,
      },
    ]).then((x) => setOutletsData(x));
  };

  useEffect(() => {
    if (globalState.currentProject) getdata();

    return () => {
      setData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.currentProject]);

  const handlePriceChange = (index, value) => {
    const updatedPrices = [...prices];
    updatedPrices[index] = value;
    setPrices(updatedPrices);
  };


  return (
    <Box p="50" width="100%">
      <BackButtons />

      <Flex direction="row" justifyContent="space-evenly" gap={10}>
        <Box>
          <HStack>
            <Heading mb={30} mt={30}>
              {data ? data.name : <></>}
            </Heading>
          </HStack>

          <Text fontSize="3xs">ID: {param.id}</Text>

          <HStack>
            {files.length > 0 ? (
              <Stack>
                <Image
                  src={files[0].file}
                  boxSize="100%"
                  maxWidth={300}
                  borderRadius="xl"
                  alt={files[0].name}
                  shadow="sm"
                />
              </Stack>
            ) : (
              <Image
                boxSize="100%"
                src={
                  data?.image_url
                    ? data.image_url
                    : "https://bit.ly/dan-abramov"
                }
                alt="Dan Abramov"
              />
            )}
          </HStack>

          <Stack>
            <Input
              type="file"
              onChange={handleFileInputChange}
              display="none"
              id="fileInput"
            />

            <label htmlFor="fileInput">
              <HStack cursor={"pointer"}>
                <Stack>
                  <MdOutlinePermMedia />
                </Stack>
                <Text fontSize={"sm"} color="blue.600" fontStyle={"italic"}>
                  Add Image
                </Text>
              </HStack>
            </label>
          </Stack>
        </Box>

        <Box>
          <FormControl mb={3} isRequired>
            <FormLabel>Menu name</FormLabel>

            <Input
              value={data?.name}
              isInvalid={data?.title?.length === 0}
              errorBorderColor="crimson"
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </FormControl>


          <FormControl mb={3} isRequired>
            <FormLabel>Stock</FormLabel>
            <Input
              placeholder={data?.stock}
              type={"number"}
              onChange={(e) =>
                setData({ ...data, stock: Number(e.target.value) })
              }
            />
            <Select
              placeholder="Select option"
              onChange={(e) => setData({ ...data, quantity_type: e.target.value })}
            >
              <option value={"qty"}>Quantity</option>
              <option value={"gram"}>Gram</option>
              <option value={"liter"}>Liter</option>
            </Select>
          </FormControl>

          <FormControl mb={3} isRequired>
            <FormLabel>Category</FormLabel>
            <Stack>
              <Select
                m="0"
                isInvalid={data?.category?.length === 0}
                placeholder="category"
                value={data?.category}
                onChange={(e) => setData({ ...data, category: e.target.value })}
              >
                {categoryData?.map((x, i) => (
                  <option key={i} value={x}>
                    <Text textTransform={"capitalize"}>{x}</Text>
                  </option>
                ))}
              </Select>
            </Stack>
          </FormControl>

          <Box>
            <FormLabel htmlFor="username">Supplier</FormLabel>
            <Select
              placeholder="Select option"
              onChange={(e) => setData({ ...data, supplier: e.target.value })}
            >
              {supplier.map((k, index) => (
                <option key={index} value={k.name}>
                  {k.name}
                </option>
              ))}
            </Select>
          </Box>

          <HStack>
            {data?.price_type === "multiple" ? (
              <FormControl>
                <FormLabel>Prices</FormLabel>

                {prices.map((price, index) => (
                  <Input
                    key={index}
                    placeholder={`Price ${index + 1}`}
                    isInvalid={data?.price?.length === 0}
                    type="number"
                    onChange={(e) => handlePriceChange(index, e.target.value)}
                  />
                ))}

                <Button onClick={() => setPrices([...prices, ""])}>
                  Add Price
                </Button>
              </FormControl>
            ) : (
              <FormControl>
                <FormLabel>Price</FormLabel>
                <Input
                  isInvalid={data?.price?.length === 0}
                  placeholder="Price"
                  type="number"
                  value={data?.price}
                  onChange={(e) => setData({ ...data, price: e.target.value })}
                />
              </FormControl>
            )}
          </HStack>

          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              placeholder="Here is a sample placeholder"
              value={data?.description}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
          </FormControl>

          {/* <Button w="full" mt="2" onClick={() => console.log(data)}>
              Check Data
            </Button> */}

          <Button
            w="full"
            h={50}
            colorScheme="green"
            mt="2"
            leftIcon={<CheckIcon />}
            onClick={() => validateForm()}
          >
            Save
          </Button>
        </Box>
      </Flex>
    </Box>
  );
}

export default EditMenuPage;
