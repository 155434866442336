import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { MemberTable } from "./MemberTable";
import { OrderTable } from "./OrderTable";

export const TableComponent = (props, onChangeSearch) => {
  let title = "Members";
  if (props.title) title = props.title;

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  return (
    <Box
      bg="bg-surface"
      boxShadow={{
        base: "none",
        md: "sm",
      }}
      borderRadius={{
        base: "none",
        md: "lg",
      }}
    >
      <Stack spacing="5">
        {/* <Box p={3}>
          <Stack
            direction={{
              base: "column",
              md: "row",
            }}
            justify="space-between"
          >
            <InputGroup maxW="xs">
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input placeholder="Search" />
            </InputGroup>
          </Stack>
        </Box> */}
        <Box overflowX="auto">
          {/* <MemberTable data={members} /> */}
          <OrderTable data={props.data} />
        </Box>
        {/* <Box
          px={{
            base: "4",
            md: "6",
          }}
          pb="5"
        >
          <HStack spacing="3" justify="space-between">
            {!isMobile && (
              <Text color="muted" fontSize="sm">
                Showing 1 to 5 of 42 results
              </Text>
            )}
            <ButtonGroup
              spacing="3"
              justifyContent="space-between"
              width={{
                base: "full",
                md: "auto",
              }}
              variant="secondary"
            >
              <Button>Previous</Button>
              <Button>Next</Button>
            </ButtonGroup>
          </HStack>
        </Box> */}
      </Stack>
    </Box>
  );
};
