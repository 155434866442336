import {
  Box,
  Button,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  Grid,
  Spacer,
} from "@chakra-ui/react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setDocumentFirebase } from "../../Apis/firebaseApi";
import BackButtons from "../../Components/Buttons/BackButtons";
import { db } from "../../Config/firebase";
import useUserStore from "../../Hooks/Zustand/Store";

function StationViewPage() {
  const param = useParams();
	const globalState = useUserStore();
  const [data, setData] = useState();
  const [filterData, setFilterData] = useState();

  const navigate = useNavigate();

  const handleUpdate = (parentIndex, childIndex, status) => {
    if (status) {
      const newArr = data[parentIndex];
      newArr.orders[childIndex].status = status;

      setDocumentFirebase("orders", newArr.id, newArr)
        .then((x) => console.log(x))
        .catch((e) => console.log(e.message));
    }
  };

  const filteringData = (filterKey) => {
    let result = [];
    let status = "";
    if (filterKey === "newOrder") {
      result = filterData.filter((y) => y.status === undefined);
      status = "onProgress";
    } else if (filterKey === "onProgress") {
      result = filterData.filter((y) => y.status === "onProgress");
      status = "ready";
    } else if (filterKey === "ready") {
      result = filterData.filter((y) => y.status === "ready");
    }

    result = result.sort(
      (a, b) => a?.updated_bill?.seconds - b?.updated_bill?.seconds
    );
    return result.map((x, i) => {
      const currentTime = moment();
      const createdAtTime = moment.unix(x?.updated_bill?.seconds);
      const timeDifference = currentTime.diff(createdAtTime, "minutes");

      let bgColor = "";
      if (timeDifference >= 20 && timeDifference < 30) {
        bgColor = "yellow.200";
      } else if (timeDifference >= 30) {
        bgColor = "red.200";
      } else if (
        x.paymentStatus === "cancel" ||
        x.paymentStatus === "refund" ||
        x.paymentStatus === "open"
      ) {
        bgColor = "gray.200";
      } else {
        bgColor = "green.100";
      }


      return (
        <Box
          key={i}
          w="100%"
          mt={5}
          p="5"
          borderRadius="xl"
          shadow="md"
          bgColor={bgColor}
        >
          <Stack>
            <Text fontWeight={"bold"} textAlign="center">
              Table # {x.table}
            </Text>

            <Text fontWeight={"bold"} textAlign="center">
              {x.title}
            </Text>

            <Text>Quantity : {x.quantity} </Text>

            {x.price_type && (
              <HStack>
                <Text>Type : </Text>
                <Text fontWeight={"bold"}>{x.price_type}</Text>
              </HStack>
            )}

            {x.variant && (
              <HStack>
                <Text>Variant : </Text>
                <Text fontWeight={"bold"}>{x.variant}</Text>
              </HStack>
            )}

            {x.notes && (
              <HStack>
                <Text>Notes : </Text>
                <Text fontWeight={"bold"}>{x.notes}</Text>
              </HStack>
            )}

            {x.paymentStatus && (
              <HStack>
                <Text>Status : </Text>
                <Text fontWeight={"bold"}>{x.paymentStatus}</Text>
              </HStack>
            )}

            <Text>
              Order Date:
              {moment.unix(x?.createdAt?.seconds).format("hh:mm:ss")}
            </Text>
            <Text>
              Bill Date:
              {moment.unix(x?.updated_bill?.seconds).format("hh:mm:ss")}
            </Text>
          </Stack>
          <Button
            w="full"
            mt={5}
            colorScheme="orange"
            backgroundColor="#F05A28"
            color="white"
            borderRadius="lg"
            onClick={() => handleUpdate(x.parentIndex, x.indexChild, status)}
          >
            Next Step
          </Button>
        </Box>
      );
    });
  };

  useEffect(() => {
    let collectionRef = collection(db, "orders");
    collectionRef = query(
      collectionRef,
      where("orderStatus", "!=", "finished")
    );
    collectionRef = query(
      collectionRef,
      where("outlet", "==", globalState?.currentOutlet)
    );

    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const newData = [];
      const filteredData = [];
      let parentIndex = 0;
      querySnapshot.forEach((doc, i) => {
        const dataQ = doc.data();
        const parentId = doc.id;
        newData.push({ id: parentId, ...dataQ });
        dataQ?.orders.map((y, j) => {
          if (
            y?.stations?.find(
              // (z) => z === param.id && dataQ?.paymentStatus !== "open"
              (z) => z === param.id 
            )
          )
            return filteredData.push({
              ...y,
              createdAt: dataQ.createdAt,
              updated_bill: dataQ.updated_bill,
              parentId: parentId,
              indexChild: j,
              parentIndex: parentIndex,
              table: dataQ.table,
              paymentStatus: dataQ?.paymentStatus,
            });
          return {};
        });
        parentIndex++;
      });
      setData(newData);
      setFilterData(filteredData);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState?.currentCompany, param?.id]);

  return (
    <Box sx={{ width: "100%" }}>
      <Flex direction={["column", "column", "row"]} gap={3} justifyContent="space-between" mb={5} mt={5}>
        <BackButtons />

        <Spacer />

        <Heading as="h3" size="lg">
          Station - {param.id?.toUpperCase()}
        </Heading>
      </Flex>

      <hr />

      {param?.id === "checker" ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          gap={10}
          sx={{ height: "80vh" }}
        >
          <Box
            w={250}
            borderWidth="1px"
            borderRadius="xl"
            borderColor="#F05A28"
            p={10}
            shadow="md"
          >
            <Text fontWeight={500} fontSize={20} textAlign="center">
              Checker 1
            </Text>

            <Button
              w="full"
              mt={5}
              colorScheme="orange"
              backgroundColor="#F05A28"
              color="white"
              borderRadius="lg"
              onClick={() => {
                navigate("/stations/checker_1");
              }}
            >
              See Checker 1
            </Button>
          </Box>

          <Box
            w={250}
            borderWidth="1px"
            borderRadius="xl"
            borderColor="#F05A28"
            p={10}
            shadow="md"
          >
            <Text fontWeight={500} fontSize={20} textAlign="center">
              Checker 2
            </Text>

            <Button
              w="full"
              mt={5}
              colorScheme="orange"
              backgroundColor="#F05A28"
              color="white"
              borderRadius="lg"
              onClick={() => {
                navigate("/stations/checker_2");
              }}
            >
              See Checker 2
            </Button>
          </Box>
        </Flex>
      ) : (
        <Grid templateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }} gap="2" p={1} mt={5}>
          <Box
            border="1px"
            borderWidth="1px"
            borderRadius="xl"
            borderColor="#F05A28"
            p={5}
            shadow="md"
            maxH={'650px'}
            overflowY='scroll'
          >
            <Heading as="h3" size="lg" textAlign="center" mb={3}>
              New Order
            </Heading>

            <hr />

            {data ? filteringData("newOrder") : <></>}
          </Box>

          <Box
            border="1px"
            borderWidth="1px"
            borderRadius="xl"
            borderColor="#F05A28"
            p={5}
            shadow="md"
            maxH={'650px'}
            overflowY='scroll'
          >
            <Heading as="h3" size="lg" textAlign="center" mb={3}>
              On Progress
            </Heading>

            <hr />

            {data ? filteringData("onProgress") : <></>}
          </Box>

          <Box
            border="1px"
            borderWidth="1px"
            borderRadius="xl"
            borderColor="#F05A28"
            p={5}
            shadow="md"
            maxH={'650px'}
            overflowY='scroll'
          >
            <Heading as="h3" size="lg" textAlign="center" mb={3}>
              Ready to serve
            </Heading>

            <hr />

            {data ? filteringData("ready") : <></>}
          </Box>
        </Grid>
      )}
    </Box>
  );
}

export default StationViewPage;
