import {
  Badge,
  Button,
  HStack,
  IconButton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useState, useEffect, useRef } from "react";
import { FiEdit2 } from "react-icons/fi";
import { updateDocumentFirebase } from "../../Apis/firebaseApi";
import OrdersDetailPaymentModal from "../../Pages/Orders/OrderDetailPaymentModal";
import OrdersDetailModal from "../../Pages/Orders/OrdersDetailModal";
import { formatFrice } from "../../Utils/numberUtil";

export const OrderTable = (props) => {
  const data = props.data;

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isDetailOpen, setDetailOpen] = useState(false);
  const [isInvoiceOpen, setInvoiceOpen] = useState(false);

  const openDetailModal = (order) => {
    setSelectedOrder(order);
    setDetailOpen(true);
  };

  const closeDetailModal = () => {
    setSelectedOrder(null);
    setDetailOpen(false);
  };

  const openInvoiceModal = (order) => {
    setSelectedOrder(order);
    setInvoiceOpen(true);
  };

  const closeInvoiceModal = () => {
    setSelectedOrder(null);
    setInvoiceOpen(false);
  };

  return (
    <>
      <Table {...props} variant="striped" bgcolor="white" shadow={"md"}>
        <Thead>
          <Tr>
            <Th>Created Date</Th>
            <Th>Order</Th>
            <Th>Table</Th>
            <Th>Updated Date</Th>
            <Th>Payment Status</Th>
            <Th>Order Status</Th>
            <Th>Order Value</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((x, index) => (
            <Tr key={x.id}>
              <Td fontSize="2xs">
                <Text fontWeight={500}>
                  {moment(x?.createdAt?.seconds * 1000).format("MMMM Do YYYY")}
                </Text>
                <Text fontWeight={500}>
                  {moment(x?.createdAt?.seconds * 1000).format("h:mm:ss a")}
                </Text>
              </Td>

              <Td>
                <Text fontWeight={500} fontSize="sm">
                  {x.name}
                </Text>
                <Text fontSize="2xs">Order ID: {x.id}</Text>
              </Td>

              <Td>
                <Text fontWeight={500}>#{x.table ? x.table : 0}</Text>
              </Td>

              <Td fontSize="2xs">
                <Text>
                  {moment(x?.lastUpdated?.seconds * 1000).format(
                    "MMMM Do YYYY"
                  )}
                </Text>
                <Text>
                  {moment(x?.lastUpdated?.seconds * 1000).format("h:mm:ss a")}
                </Text>
              </Td>

              <Td>
                <Badge
                  borderRadius="md"
                  variant={"solid"}
                  p={1}
                  textAlign="center"
                  colorScheme={
                    x.paymentStatus === "complete" ||
                    x.paymentStatus === "compliment"
                      ? "green"
                      : "red"
                  }
                >
                  {x.paymentStatus}
                </Badge>
              </Td>

              <Td>
                <Stack>
                  {x.on_report_at !== undefined && (
                    <Badge
                      borderRadius="md"
                      p={1}
                      colorScheme={"green"}
                      textAlign="center"
                      variant={"solid"}
                    >
                      {x.on_report_shift}
                    </Badge>
                  )}
                  <Badge
                    borderRadius="md"
                    p={1}
                    colorScheme={x.orderStatus === "done" ? "green" : "red"}
                    textAlign="center"
                    variant={"solid"}
                  >
                    {x.orderStatus}
                  </Badge>
                </Stack>
              </Td>

              <Td>
                <Text color="muted" fontSize={"sm"}>
                  {x.orders.length} Menu
                </Text>
                <Text color="muted" fontSize={"sm"} fontWeight={500}>
                  Rp.
                  {formatFrice(
                    (x.totalPrice - x.discount || 0) +
                      ((x.totalPrice - x.discount || 0) * x.tax) / 100
                  )}
                </Text>
              </Td>

              <Td>
                <HStack spacing="1">
                  <IconButton
                    icon={<FiEdit2 fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Edit member"
                    onClick={() => openDetailModal(x)}
                  />
                  {x.paymentStatus === "open" && (
                    <Button
                      size={"sm"}
                      colorScheme={"green"}
                      onClick={() => openInvoiceModal(x)}
                    >
                      Pay
                    </Button>
                  )}
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <OrdersDetailModal
        order={selectedOrder}
        isOpen={isDetailOpen}
        onClose={closeDetailModal}
      />

      <OrdersDetailPaymentModal
        order={selectedOrder}
        isOpen={isInvoiceOpen}
        onClose={closeInvoiceModal}
      />
    </>
  );
};
