import {
  FcCalendar,
  FcComments,
  FcConferenceCall,
  FcEditImage,
  FcKindle,
  FcLineChart,
  FcNews,
  FcSettings,
  FcShare,
  FcSms,
  FcSurvey,

  FcParallelTasks,
  FcPackage,

  FcGlobe,
  FcContacts,
  FcBusinessContact,
  FcAutomatic,
  FcBriefcase,
  FcShop,
  FcLibrary,
  FcCollaboration,
  FcDisplay,
  FcCustomerSupport,
  FcFile,
  FcLike,
  FcCloseUpMode,
  FcFullTrash,
  FcFactory,
  FcRules,
  FcStatistics,
  FcClearFilters,
  FcDataSheet,
  FcClock
} from "react-icons/fc";

export const data = [


  {
    name: "Scoreboard",
    icon: FcDisplay,
    link: '/'
  },

  {
    name: "Transaction",
    icon: FcBusinessContact,
    link: '/transaction'
  },

  {
    name: "Reports",
    icon: FcRules,
    link: '/reports'
  },

  // {
  //   name: "Profiles",
  //   icon: FcSms,
  //   link: '/profiles'
  // },

  // {
  //   name: "Chat",
  //   icon: FcSms,
  //   submenu: [
  //     { name: "Chat", icon: FcEditImage, link: "/chat" },
  //   ],
  // },



  {
    name: "Warehouse",
    icon: FcFactory,
    submenu: [
      { name: "Menus", icon: FcLike, link: "/menus" },
      { name: "Waste Food", icon: FcClearFilters, link: "/wastefood" },
      { name: "Stock", icon: FcSurvey, link: "/stock" },
      { name: "Suppliers", icon: FcConferenceCall, link: "/suppliers" },
      { name: "Activity", icon: FcClock, link: "/activity" },
    ],

  },

  {
    name: "Restaurant",
    icon: FcStatistics,
    submenu: [
      { name: "Outlets", icon: FcShop, link: "/outlets" },
      { name: "Orders", icon: FcDataSheet, link: "/orders" },
      { name: "Stations", icon: FcCalendar, link: "/stations" },

    ],
  },
  // {
  //   name: "Form",
  //   icon: FcSurvey,
  //   submenu: [
  //     { name: "Form", icon: FcCalendar, link: "/form-builder" },
  //   ],
  // },

  // {
  //   name: "Listing",
  //   icon: FcConferenceCall,
  //   submenu: [
  //     { name: "Listing", icon: FcEditImage, link: "/listing" },
  //   ],
  // },
  // {
  //   name: "News",
  //   icon: FcNews,
  //   submenu: [
  //     { name: "News", icon: FcEditImage, link: "/news" },
  //   ],
  // },
  // {
  //   name: "Pipeline",
  //   icon: FcKindle,
  //   submenu: [
  //     { name: "Pipeline", icon: FcEditImage, link: "/pipeline" },
  //     { name: "Membership", icon: FcSettings, link: "/chat" },
  //   ],
  // },
  // {
  //   name: "Configuration",
  //   icon: FcSettings,
  //   submenu: [
  //     { name: "Data", icon: FcFile, link: "/configuration/data" },
  //     { name: "Domain", icon: FcGlobe, link: "/configuration/domain" },
  //     { name: "Integration", icon: FcAutomatic, link: "/configuration/integration" },
  //     { name: "User", icon: FcConferenceCall, link: "/configuration/user" },
  //     { name: "Project", icon: FcBriefcase, link: "/configuration/project" },
  //     { name: "Outlet", icon: FcShop, link: "/configuration/outlet" },
  //   ],
  // },


];


export const dataApps = [
  {
    name: 'AI',
    icon: FcCustomerSupport,
    link: 'https://ai.deoapp.com/'
  }, {
    name: 'LMS',
    icon: FcLibrary,
    link: 'https://lms.deoapp.com/'
  }, {
    name: 'HR',
    icon: FcCollaboration,
    link: ''
  }, {
    name: 'RMS',
    icon: FcShop,
    link: 'https://rms.deoapp.com/'
  }
]