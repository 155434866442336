import axios from "axios";
import { ref, set, remove, get, child } from "firebase/database";
import { database } from "../../Config/firebase";
import { removeSymbols } from "../../Utils/Helper";

const getDeviceType = () => {
  const screenWidth = window.innerWidth;
  const mobileThreshold = 768;
  const tabletThreshold = 1024;

  if (screenWidth < mobileThreshold) {
    return 'mobile';
  } else if (screenWidth < tabletThreshold) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};

const logoutIfExpired = async (hostName, email, pathLink) => {
  const deviceType = getDeviceType();

  try {
    const snapshot = await get(
      ref(
        database,
        `onlineUsers/${removeSymbols(hostName)}-${pathLink}-${deviceType}-${removeSymbols(
          email
        )}`
      )
    );
    const userData = snapshot.val();

    if (userData) {
      const currentTime = Date.now();
      const loginTime = new Date(userData.loginTime).getTime(); // Konversi waktu login dari string ke milidetik

      if (currentTime - loginTime > 5 * 60 * 60 * 1000) {
        // 5 jam dalam milidetik
        await remove(
          ref(
            database,
            `onlineUsers/${removeSymbols(hostName)}-${pathLink}-${deviceType}-${removeSymbols(
              email
            )}`
          )
        );
        return true; // Pengguna berhasil logout
      }
    }

    return false; // Pengguna tidak perlu logout
  } catch (error) {
    console.log(error);
    return false;
  }
};

const loginUserWithIp = async (hostName, email, pathLink) => {
  const deviceType = getDeviceType();

  const checkAccess = await checkUserAccess(hostName, email, pathLink);

  if (!checkAccess) {
    return false;
  }

  try {
    await set(
      ref(
        database,
        `onlineUsers/${removeSymbols(hostName)}-${pathLink}-${deviceType}-${removeSymbols(
          email
        )}`
      ),
      {
        loginTime: new Date().toString(),
      }
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const logoutUserWithIp = async (hostName, email, pathLink) => {
  const deviceType = getDeviceType();

  try {
    await remove(
      ref(
        database,
        `onlineUsers/${removeSymbols(hostName)}-${pathLink}-${deviceType}-${removeSymbols(
          email
        )}`
      )
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const checkUserAccess = async (hostName, email, pathLink) => {
  const deviceType = getDeviceType();

  try {
    const snapshot = await get(
      child(
        ref(database),
        `onlineUsers/${removeSymbols(hostName)}-${pathLink}-${deviceType}-${removeSymbols(
          email
        )}`
      )
    );
    const userData = snapshot.val();
    return userData ? false : true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export { loginUserWithIp, logoutUserWithIp, checkUserAccess, logoutIfExpired, getDeviceType };
