import {
    Badge,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import useUserStore from "../../Hooks/Zustand/Store";
import { getCompanyName, getStoreName } from "../../Utils/nameUtil";
import { formatFrice } from "../../Utils/numberUtil";

const OrderRefundDetailModal = ({ order: initialOrder, isOpen, onClose }) => {
	const globalState = useUserStore();

    const [order, setOrder] = useState();

    const [dataOrdered, setDataOrdered] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState("");

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const calculateTotalPrice = useCallback(() => {
        let total = 0;
        if (order && order.order_refund) {
            order.order_refund.forEach((order) => {
                total += parseInt(Number(order.base_price) * Number(order.quantity));
            });
        }
        return total;
    }, [order]);




    useEffect(() => {
        if (isOpen) {
            setOrder(initialOrder);
        } else {
            setOrder(null);
            setDataOrdered([]);
            setPaymentMethod("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState.currentOutlet, globalState.currentCompany, isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    <Text>Invoice Table: {order?.table || 0}</Text>
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody>
                    {order && (
                        <Stack spacing={2}>
                            <HStack alignItems={'flex-end'} justifyContent='flex-end'>
                                <Text fontSize={'sm'} color='gray.700'>{moment(order?.lastUpdated?.seconds * 1000).format('MMMM Do YYYY')}</Text>
                                <Text fontSize={'sm'} color='gray.700'>{moment(order?.lastUpdated?.seconds * 1000).format('h:mm:ss a')}</Text>
                            </HStack>


                            <HStack justifyContent="space-between">
                                <Text color={'gray.600'}>ID:</Text>
                                <Spacer />
                                <Text fontWeight={500}>{order?.id}</Text>
                            </HStack>

                            <HStack>
                                <Text color='gray.600'>Company :</Text>
                                <Spacer />
                                <Text textTransform={'capitalize'} fontWeight={500}>{getCompanyName(globalState, order.companyId)}</Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>Store:</Text>
                                <Spacer />
                                <Text textTransform={'capitalize'} fontWeight={500}>{getStoreName(globalState, order?.outlet)}</Text>
                            </HStack>


                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>Name:</Text>
                                <Spacer />
                                <Text fontWeight={500}> {order?.name}</Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color={'gray.600'}>Payment Status:</Text>
                                <Spacer />
                                <Badge colorScheme="green" p={1} textAlign='center' variant={'solid'} borderRadius='md'>
                                    {order?.paymentStatus}
                                </Badge>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color={'gray.600'}>Payment Refund Status:</Text>
                                <Spacer />
                                <Badge
                                    p={1} textAlign='center' variant={'solid'} borderRadius='md'
                                    colorScheme={
                                        order.paymentRefundStatus === "complete" ? "green"
                                            : "red"
                                    }
                                >
                                    {order?.paymentRefundStatus ? order?.paymentRefundStatus : "open"}
                                </Badge>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color={'gray.600'}> Order Status:</Text>
                                <Spacer />
                                <Badge colorScheme="green" p={1} textAlign='center' variant={'solid'} borderRadius='md'>
                                    {order?.orderStatus}
                                </Badge>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color={'gray.600'}> Payment Method:</Text>
                                <Spacer />
                                <Badge colorScheme="green" p={1} textAlign='center' variant={'solid'} borderRadius='md'>
                                    {order?.paymentMethod}
                                </Badge>
                            </HStack>

                            <hr style={{ marginTop: 10, marginBottom: 10 }} />


                            <Text fontWeight={500}>Update Payment Method:</Text>
                            <Select
                                borderRadius="lg"
                                placeholder="Payment method"
                                onChange={(e) =>
                                    setOrder({ ...order, paymentMethod: e.target.value })
                                }
                                value={order.paymentMethod}
                            >
                                <option value={"cash"}>
                                    <Text textTransform={"capitalize"}>Cash</Text>
                                </option>
                                <option value={"transfer_bank"}>
                                    <Text textTransform={"capitalize"}>Transfer Bank</Text>
                                </option>
                                <option value={"edc"}>
                                    <Text textTransform={"capitalize"}>Pay with EDC</Text>
                                </option>
                                <option value={"online"}>
                                    <Text textTransform={"capitalize"}>Online</Text>
                                </option>
                            </Select>


                            <Text fontWeight={500} style={{ marginTop: 10 }}>
                                Orders:
                            </Text>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>Title</Th>
                                        <Th>Quantity</Th>
                                        <Th>Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {order?.order_refund?.map((order, index) => {
                                        return (
                                            <Tr key={index}>
                                                <Td>
                                                    <Text textTransform="capitalize" fontSize={'sm'}>{order?.title}</Text>
                                                    <Text textTransform="capitalize" fontSize={'sm'}>{order?.price_type}</Text>
                                                    <Text textTransform="capitalize" fontSize={'sm'} fontWeight={'bold'} >
                                                        {order?.variant}
                                                    </Text>
                                                    <Text textTransform="capitalize" fontSize={'sm'} color="red.300">
                                                        {order?.notes}
                                                    </Text>
                                                </Td>
                                                <Td fontSize={'sm'}>
                                                    <HStack>
                                                        <Text>{order?.quantity}</Text>
                                                        <Badge p={1} textAlign='center' variant={'solid'} borderRadius='md'>{order?.status}</Badge>
                                                    </HStack>
                                                </Td>
                                                <Td fontWeight={500}>
                                                    Rp.{formatFrice(parseInt(order.base_price))}
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>



                            <HStack p={2}>
                                <Text color={'gray.600'}>Total Price: </Text>
                                <Spacer />
                                <Text fontWeight={500} color='red.500'>
                                    Rp. {formatFrice(calculateTotalPrice())}
                                </Text>
                            </HStack>


                        </Stack>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default OrderRefundDetailModal;
