import {
  Button,
  Heading,
  HStack,
  Spacer,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Textarea,
  Box,
  useToast,
  Td,
  Image,
  SimpleGrid,
  Divider,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { FcPlus } from "react-icons/fc";
import {
  addDocumentFirebase,
  getCollectionFirebase,
  uploadFile,
} from "../../Apis/firebaseApi";
import { checkTransactionValidity } from "../../Hooks/Middleware/UserMiddleWare";
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import { TransactionTable } from "../../Components/Tables/TransactionTable";
import { formatFrice } from "../../Utils/numberUtil";
import useUserStore from "../../Hooks/Zustand/Store";
import { FaRegCalendar } from "react-icons/fa";
import DatePicker from "../../Components/Calendar/DatePicker";

function TransactionPage() {
  const [view, setView] = useState("Transaction");

  const [originData, setOriginData] = useState([]);
  const [dataTransaction, setDataTransaction] = useState([]);

  const [modalAdd, setModalAdd] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState();

  const [refreshData, setRefreshData] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const [transaction, setTransaction] = useState({
    amount: 0,
    type: "",
    paymentMethod: "",
    notes: "",
    image_url: "",
  });

  const toast = useToast();
  const globalState = useUserStore();
  const modalFilterDate = useDisclosure();

  const handleSaveTransaction = async () => {
    let updateTransaction = transaction;
    updateTransaction.module = "rms";
    updateTransaction.assignment_status = "pending";
    updateTransaction.outlet = globalState.currentOutlet;

    if (filesImage[0]) {
      const resImage = await uploadFile(
        `RMS-${moment(new Date().valueOf())}-${transaction.type}`,
        "transactions",
        filesImage[0]
      );
      updateTransaction.image_url = resImage;
    }

    const validationResult = checkTransactionValidity(
      transaction.amount,
      transaction?.type?.length,
      transaction?.paymentMethod?.length,
      transaction?.notes?.length,
      transaction.image_url?.length
    );

    if (!validationResult.success) {
      toast(validationResult.error);
      return;
    }

    try {
      addDocumentFirebase(
        "transactions",
        updateTransaction,
        globalState.currentCompany
      ).then((x) =>
        //   createPaymentVA(x).then((x) => console.log(x))
        toast({
          title: "Success",
          description: "Transaction Berhasil di tambahkan",
          position: "top",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      );
    } catch (error) {
      console.log(error, "ini error");
    }

    setTransaction({
      created_at: new Date(),
      amount: 0,
      type: "",
      paymentMethod: "",
      notes: "",
    });
    setModalAdd(false);
    setRefreshData(true);
  };

  // const getTransactionItem = () => {
  //   //GET data transaction from local storage
  //   const items = JSON.parse(localStorage.getItem("transaction"));
  //   if (items) {
  //     setDataTransaction(items);
  //   } else {
  //     localStorage.setItem("transaction", JSON.stringify([]));
  //   }
  // };

  const getData = async () => {
    try {
      const q = query(
        collection(db, "transactions"),
        where("module", "==", "rms"),
        where("outlet", "==", globalState.currentOutlet),
        orderBy("createdAt", "desc"),
        limit(25)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        });
        setDataTransaction(data);
        setOriginData(data);
      });

      // Unsubscribe from the snapshot listener when the component unmounts
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const getDataFilter = async () => {
    const conditions = [
      {
        field: "module",
        operator: "==",
        value: "rms",
      },
      {
        field: "createdAt",
        operator: ">=",
        value: selectedDateRange?.startDate,
      },
      {
        field: "createdAt",
        operator: "<=",
        value: selectedDateRange?.endDate,
      },
      {
        field: "outlet",
        operator: "==",
        value: globalState?.currentOutlet,
      },
    ];

    try {
      const filteredOrders = await getCollectionFirebase(
        "transactions",
        conditions
      );
      setDataTransaction(filteredOrders);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const valueIncome = useCallback(() => {
    let totalRefund = 0;
    let arrPrice = [];

    const valueData = dataTransaction?.filter((data) => {
      return data?.type === "income" && data?.assignment_status === "approved";
    });

    valueData?.map((data) => {
      return arrPrice?.push(data?.amount);
    });

    arrPrice?.forEach((item) => {
      totalRefund += parseInt(item);
    });

    return totalRefund;
  }, [dataTransaction]);

  const valueOutcome = useCallback(() => {
    let totalRefund = 0;
    let arrPrice = [];

    const valueData = dataTransaction?.filter((data) => {
      return data?.type === "outcome" && data?.assignment_status === "approved";
    });

    valueData?.map((data) => {
      return arrPrice?.push(data?.amount);
    });

    arrPrice?.forEach((item) => {
      totalRefund += parseInt(item);
    });

    return totalRefund;
  }, [dataTransaction]);

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;
    if (newFiles.length) {
      const newFileArray = [...files];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);
        };
      }
      setFilesImage(newFiles); // Mengubah state filesImage menjadi array baru dari selectedFiles
    }
  };

  const handleSearchFunction = (text) => {
    if (text !== "") {
      const newData = dataTransaction?.filter((transaction) => {
        const itemData = transaction.on_report_person
          ? transaction.on_report_person.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataTransaction(newData);
      // setInputSearch(text);
    } else {
      setDataTransaction(originData);
      // setInputSearch(text);
    }
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  // useEffect(() => {
  //   getData();

  //   return () => {};
  // }, [globalState.currentOutlet]);

  useEffect(() => {
    if (!selectedDateRange) {
      getData();
    } else {
      getDataFilter();
    }
  }, [globalState.currentOutlet, selectedDateRange]);

  // useEffect(() => {
  //   getTransactionItem();
  //   setRefreshData(false);
  // }, [refreshData]);

  return (
    <Stack>
      <Stack p={[1, 1, 5]} flexDirection={["column", "row", "row"]}>
        <Heading textTransform={"capitalize"} size="lg">
          Transaction
        </Heading>
        <Spacer />
        <HStack justifyContent="end" gap={5}>
          <Button
            onClick={() => setModalAdd(true)}
            bgColor={"white"}
            shadow="md"
            variant="outline"
            borderColor="#F05A28"
            color="#F05A28"
          >
            <HStack>
              <FcPlus />
              <Text>Transaction</Text>
            </HStack>
          </Button>
        </HStack>
      </Stack>

      {view === "Transaction" && (
        <Stack p={[1, 1, 5]} spacing={10}>
          <SimpleGrid columns={[1, 3, 3]} gap={5}>
            <Stack alignItems={"center"} justifyContent="center">
              <Stack
                bgColor={"white"}
                w={"100%"}
                h="100px"
                py={2}
                borderRadius="2xl"
                shadow={"md"}
              >
                <Heading size={"md"} textAlign="center">
                  Income Total
                </Heading>

                <Divider />

                <Stack alignItems={"center"} justifyContent="center" h={"80%"}>
                  <Heading textAlign={"center"} size={"md"} color="green.500">
                    {" "}
                    Rp. {formatFrice(valueIncome())}
                  </Heading>
                </Stack>
              </Stack>
            </Stack>

            <Stack alignItems={"center"} justifyContent="center">
              <Stack
                bgColor={"white"}
                w={"100%"}
                h="100px"
                py={2}
                borderRadius="2xl"
                shadow={"md"}
              >
                <Heading size={"md"} textAlign="center" pt={2}>
                  Outcome Total
                </Heading>

                <Divider />

                <Stack alignItems={"center"} justifyContent="center" h={"80%"}>
                  <Heading textAlign={"center"} size={"md"} color="red.500">
                    {" "}
                    Rp. {formatFrice(valueOutcome())}
                  </Heading>
                </Stack>
              </Stack>
            </Stack>

            <Stack alignItems={"center"} justifyContent="center">
              <Stack
                bgColor={"white"}
                w={"100%"}
                h="100px"
                py={2}
                borderRadius="2xl"
                shadow={"md"}
              >
                <Heading size={"md"} textAlign="center" pt={2}>
                  Total
                </Heading>

                <Divider />

                <Stack alignItems={"center"} justifyContent="center" h={"80%"}>
                  <Heading
                    textAlign={"center"}
                    size={"md"}
                    color={
                      valueIncome() > valueOutcome() ? "green.500" : "red.500"
                    }
                  >
                    {" "}
                    Rp. {formatFrice(valueIncome() - valueOutcome())}
                  </Heading>
                </Stack>
              </Stack>
            </Stack>
          </SimpleGrid>

          <Box overflowX={"scroll"}>
            <HStack>
              <Input
                bg={"white"}
                my={2}
                placeholder={"Search transactions ... "}
                w={250}
                sx={inputStyles}
                onChange={(e) => handleSearchFunction(e.target.value)}
              />
              <Spacer />
              <Button
                onClick={modalFilterDate.onOpen}
                bgColor={"white"}
                shadow="md"
                variant="outline"
                borderColor="#F05A28"
                color="#F05A28"
              >
                <HStack>
                  <FaRegCalendar />
                  <Text>Filter date</Text>
                </HStack>
              </Button>
            </HStack>
            {dataTransaction && <TransactionTable data={dataTransaction} />}
          </Box>
        </Stack>
      )}

      <Modal
        isOpen={modalAdd}
        onClose={() => {
          setModalAdd(false);
          setTransaction({
            amount: 0,
            type: "",
            pic: "",
            paymentMethod: "",
            notes: "",
          });
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Transacrtion</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Box>
              <Text fontWeight={500} fontSize={14} mb={2}>
                Amount Transaction
              </Text>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                  children="Rp."
                />
                <Input
                  placeholder="Amount"
                  type="number"
                  onChange={(e) => {
                    setTransaction({
                      ...transaction,
                      amount: Number(e.target.value),
                    });
                  }}
                />
              </InputGroup>
            </Box>

            <Box mt={5}>
              <Text fontWeight={500} fontSize={14} mb={2}>
                Type Transcation
              </Text>
              <Select
                borderRadius="lg"
                placeholder="Type Transaction"
                value={transaction?.type}
                onChange={(e) => {
                  setTransaction({ ...transaction, type: e.target.value });
                }}
              >
                <option value={"outcome"}>
                  <Text textTransform={"capitalize"}>Outcome</Text>
                </option>
                <option value={"income"}>
                  <Text textTransform={"capitalize"}>Income</Text>
                </option>
              </Select>
            </Box>

            <Box mt={5}>
              <Text fontWeight={500} fontSize={14} mb={2}>
                Methdoe Transaction
              </Text>
              <Select
                borderRadius="lg"
                placeholder="Methode Transaction"
                value={transaction?.paymentMethod}
                onChange={(e) => {
                  setTransaction({
                    ...transaction,
                    paymentMethod: e.target.value,
                  });
                }}
              >
                <option value={"cash"}>
                  <Text textTransform={"capitalize"}>Cash</Text>
                </option>
                <option value={"edc"}>
                  <Text textTransform={"capitalize"}>EDC</Text>
                </option>
                <option value={"online"}>
                  <Text textTransform={"capitalize"}>Online</Text>
                </option>
                <option value={"transfer_bank"}>
                  <Text textTransform={"capitalize"}>Transfer Bank</Text>
                </option>
              </Select>
            </Box>

            <Box mt={5}>
              <Text fontWeight={500} fontSize={14} mb={2}>
                Notes Transcation
              </Text>
              <Textarea
                placeholder="Notes"
                onChange={(e) => {
                  setTransaction({ ...transaction, notes: e.target.value });
                }}
              />
            </Box>

            <Box mt={5}>
              <Text fontWeight={500} fontSize={14} mb={2}>
                Image
              </Text>
              {files?.length > 0 && (
                <Image
                  src={files[0].file}
                  alt={files[0].name}
                  marginRight="auto"
                  marginLeft="auto"
                  mt={3}
                  mb={3}
                />
              )}

              <input
                type="file"
                name="image"
                onChange={handleFileInputChange}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                setModalAdd(false);
                setTransaction({
                  amount: 0,
                  type: "",
                  pic: "",
                  paymentMethod: "",
                  notes: "",
                });
              }}
            >
              Close
            </Button>

            <Button
              colorScheme="green"
              onClick={() => {
                handleSaveTransaction();
              }}
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalFilterDate.isOpen}
        onClose={modalFilterDate.onClose}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalBody>
            <Center>
              <DatePicker onDateChange={handleDateRangeChange} />
            </Center>

            <HStack>
              <HStack spacing={4}>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={"semibold"}>Start Date: </Text>
                  <Text>
                    {moment(selectedDateRange?.startDate).format("LLL")}
                  </Text>
                </Stack>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={"semibold"}>End Date:</Text>
                  <Text>
                    {moment(selectedDateRange?.endDate).format("LLL")}
                  </Text>
                </Stack>
              </HStack>
              <Spacer />
              <Button
                size={"xs"}
                colorScheme="blue"
                onClick={() => setSelectedDateRange()}
              >
                Clear Filter
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default TransactionPage;
