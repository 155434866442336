import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import { TableComponent } from "../../Components/Tables/TableComponent";
import { ReportTable } from "../../Components/Tables/ReportTable";
import useUserStore from "../../Hooks/Zustand/Store";
import DatePicker from "../../Components/Calendar/DatePicker";
import moment from "moment";
import { getCollectionFirebase } from "../../Apis/firebaseApi";
import { FaRegCalendar } from "react-icons/fa";

function ReportsPage() {
  const globalState = useUserStore();

  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState();

  const modalFilterDate = useDisclosure();

  const getData = async () => {
    try {
      const q = query(
        collection(db, "reports"),
        where("module", "==", "rms"),
        where("outlet", "==", globalState.currentOutlet),
        limit(25)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        });

        const dataSort = data.sort(
          (a, b) => b.createdAt.seconds - a.createdAt.seconds
        );
        setData(dataSort);
        setOriginData(dataSort);
      });

      // Unsubscribe from the snapshot listener when the component unmounts
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const getDataFilter = async () => {
    const conditions = [
      {
        field: "module",
        operator: "==",
        value: "rms",
      },
      {
        field: "createdAt",
        operator: ">=",
        value: selectedDateRange?.startDate,
      },
      {
        field: "createdAt",
        operator: "<=",
        value: selectedDateRange?.endDate,
      },
      {
        field: "outlet",
        operator: "==",
        value: globalState?.currentOutlet,
      },
    ];

    try {
      const filteredOrders = await getCollectionFirebase("reports", conditions);
      setData(filteredOrders);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handleSearchFunction = (text) => {
    if (text !== "") {
      const newData = data.filter((report) => {
        const itemData = report.person_in_charge
          ? report.person_in_charge.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setData(newData);
      // setInputSearch(text);
    } else {
      setData(originData);
      // setInputSearch(text);
    }
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  useEffect(() => {
    if (!selectedDateRange) {
      getData();
    } else {
      getDataFilter();
    }
  }, [globalState.currentOutlet, selectedDateRange]);

  //   useEffect(() => {
  //     getData();

  //     return () => {};
  //   }, [globalState.currentOutlet]);

  return (
    <Stack p={[1, 1, 5]}>
      <Stack>
        <Heading size={"lg"}>Reports</Heading>
      </Stack>
      <Stack py={5}>
        <Box overflowX={"auto"}>
          <HStack pb={2}>
            <Input
              bg={"white"}
              my={2}
              placeholder={"Search PIC ... "}
              w={250}
              sx={inputStyles}
              onChange={(e) => handleSearchFunction(e.target.value)}
            />
            <Spacer />
            <Button
              onClick={modalFilterDate.onOpen}
              bgColor={"white"}
              shadow="md"
              variant="outline"
              borderColor="#F05A28"
              color="#F05A28"
            >
              <HStack>
                <FaRegCalendar />
                <Text>Filter date</Text>
              </HStack>
            </Button>
          </HStack>
          {data ? <ReportTable data={data} /> : <></>}
        </Box>
      </Stack>

      <Modal
        isOpen={modalFilterDate.isOpen}
        onClose={modalFilterDate.onClose}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalBody>
            <Center>
              <DatePicker onDateChange={handleDateRangeChange} />
            </Center>

            <HStack>
              <HStack spacing={4}>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={"semibold"}>Start Date: </Text>
                  <Text>
                    {moment(selectedDateRange?.startDate).format("LLL")}
                  </Text>
                </Stack>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={"semibold"}>End Date:</Text>
                  <Text>
                    {moment(selectedDateRange?.endDate).format("LLL")}
                  </Text>
                </Stack>
              </HStack>
              <Spacer />
              <Button
                size={"xs"}
                colorScheme="blue"
                onClick={() => setSelectedDateRange()}
              >
                Clear Filter
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default ReportsPage;
