import { Box, Button, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useUserStore from '../../Hooks/Zustand/Store';

function OutletsPage() {

	const globalState = useUserStore();

    const [dataOutlets, setDataOutles] = useState([])


    const getDataOutlets = () => {
        setDataOutles(globalState?.outlets)

    }

    useEffect(() => {
        getDataOutlets()

        return () => {
        }
    }, [])

    return (
        <Stack p={[1, 1, 5]} spacing={10} minH='80vh'>
            <Stack>
                <Heading size="lg">Outlets</Heading>
            </Stack>

            <Stack alignItems={'center'} justifyContent='center'>
                <Stack>

                    <SimpleGrid columns={[4]} gap={5}>
                        {dataOutlets?.length > 0 && (
                            dataOutlets.map((x, index) => {
                                return (
                                    <Box
                                        w={250}
                                        borderWidth="1px"
                                        borderRadius="xl"
                                        borderColor="#F05A28"
                                        p={10}
                                        shadow="md"
                                        key={index}
                                    >
                                        <Text fontWeight={500} fontSize={20} textAlign="center">
                                            {x.name?.toUpperCase()}
                                        </Text>

                                        <Link to={`${x.id}`}>
                                            <Button
                                                w="full"
                                                mt={5}
                                                colorScheme="orange"
                                                backgroundColor="#F05A28"
                                                color="white"
                                                borderRadius="lg"
                                            >
                                                Go to Outlet
                                            </Button>
                                        </Link>

                                    </Box>
                                )
                            })
                        )}
                    </SimpleGrid>
                </Stack>

            </Stack>
        </Stack>
    )
}

export default OutletsPage