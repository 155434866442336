import { Box, Button, Heading, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackButtons from '../../Components/Buttons/BackButtons'
import useUserStore from '../../Hooks/Zustand/Store'

function OutletsDetailPage() {

	const globalState = useUserStore();

  const param = useParams()
  const navigate = useNavigate()

  const [detailOutlets, setDetailOutlets] = useState('')

  const getDataOutlets = () => {
    const data = globalState.outlets.find((x) => x.id === param.id)
    setDetailOutlets(data)
  }

  useEffect(() => {
    getDataOutlets()

    return () => {
    }
  }, [])

  return (
    <Stack p={[1, 1, 5]} spacing={10}>
      <HStack>
        <BackButtons />
        <Spacer />

        <Heading size={'lg'} textTransform='capitalize'>{detailOutlets.name}</Heading>

      </HStack>


      <Stack>
        <Box>
          <Button onClick={() => navigate(`/tables/${detailOutlets.id}`)} bgColor={'white'} shadow='md' variant='outline' borderColor='#F05A28' color='#F05A28'>
            <HStack>
              <Text>Table</Text>
            </HStack>
          </Button>
        </Box>
      </Stack>

    </Stack>
  )
}

export default OutletsDetailPage