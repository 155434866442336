import React from 'react'
import ActivityPage from '../Pages/Activity/ActivityPage'

const ActivityRouter = [
	{
		path: "/activity",
		element: <ActivityPage />
	},

]

export default ActivityRouter