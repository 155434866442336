import { AddIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { FcPlus } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'
import { arrayUnionFirebase, setDocumentFirebase } from '../../Apis/firebaseApi'
import { clientTypessense } from '../../Apis/Typesense'
import { db } from '../../Config/firebase'
import useUserStore from '../../Hooks/Zustand/Store'
import OutletCard from './OutletCard'
import ProjectCard from './ProjectCard'



export const ProfilePage = () => {
    const [projectData, setProjectData] = useState([]);
    const [outletData, setOutletData] = useState([]);
    const [userProject, setUserProject] = useState([]);
    const [outletActive, setOutletActive] = useState("");
    const [projectActive, setProjectActive] = useState("");
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);
    const [modalUser, setModalUser] = useState(false);
    const [modalOutletUser, setModalOutletUser] = useState(false);
    const [modalProjectUser, setModalProjectUser] = useState(false);
    const [modalProjectUserTeam, setModalProjectUserTeam] = useState(false);
    const [inputSearch, setInputSearch] = useState("");
    const [userSearch, setUserSearch] = useState([]);

	const globalState = useUserStore();

    const [searchResult, setSearchResult] = useState('')

    const toast = useToast({ position: "top", align: "center" });

    const navigate = useNavigate()

    const getDataProjects = async () => {
        const q = query(
            collection(db, "projects"),
            where("users", "array-contains", globalState.uid),
            where("companyId", "==", globalState.currentCompany)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const promises = snapshot.docs.map(async (doc) => {
                const projectData = { id: doc.id, ...doc.data() };
                const filteredProjects = projectData.modules?.includes("rms");

                if (filteredProjects) {
                    const userSnapshot = await getDocs(
                        collection(db, `projects/${doc.id}/users`)
                    );
                    const userData = userSnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    projectData.usersProjectData = userData;
                    return projectData;
                }

                return null;
            });

            Promise.all(promises).then((resolvedProjects) => {
                const filteredProjects = resolvedProjects.filter((project) => project !== null);
                setProjectData(filteredProjects);
            });
        });

        // Unsubscribe from the snapshot listener when the component unmounts
        return () => unsubscribe();
    };


    const handleUserClick = (userId) => {
        setSelectedUserIds((prevIds) => {
            if (prevIds.includes(userId)) {
                return prevIds.filter((id) => id !== userId);
            } else {
                return [...prevIds, userId];
            }
        });
    };

    const handleUserProjectClick = (userId) => {
        setSelectedUserProjectIds((prevIds) => {
            if (prevIds.includes(userId)) {
                return prevIds.filter((id) => id !== userId);
            } else {
                return [...prevIds, userId];
            }
        });
    }

    const handleAddTeam = async () => {
        const userId = selectedUserIds.map((x) => x.id);
        const collectionName = 'outlets';
        const docName = outletActive.id;
        const field = 'users';
        const values = userId;

        try {
            await arrayUnionFirebase(collectionName, docName, field, values);

            toast({
                title: "Berhasil",
                description: "berhasil menambahkan team",
                status: "success",
                duration: 9000,
                isClosable: true,
            });

            await Promise.all(selectedUserIds.map(async (x) => {
                const collectionName = `outlets/${outletActive.id}/users`;
                const docName = x.id;
                const data = x;

                try {
                    const result = await setDocumentFirebase(collectionName, docName, data);
                    console.log(result); // Pesan toast yang berhasil
                } catch (error) {
                    console.log('Terjadi kesalahan:', error);
                }
            }));

            setSelectedUserIds([]);
            setOutletActive("");
            setUserProject([]);
            setUserSearch([]);
            setModalUser(false);
            getDataProjects();
            getDataOutlets();

        } catch (error) {
            console.log('Terjadi kesalahan:', error);
        }
    };

    const getDataOutlets = async () => {
        const q = query(
            collection(db, "outlets"),
            where("users", "array-contains", globalState?.uid),
            where("projectId", "==", globalState?.currentProject)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const promisesOutlet = snapshot.docs.map(async (doc) => {
                const outletData = { id: doc.id, ...doc.data() };

                const userSnapshot = await getDocs(
                    collection(db, `outlets/${doc.id}/users`)
                );
                const userData = userSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                outletData.usersOutletData = userData;
                return outletData;
            });

            Promise.all(promisesOutlet).then((resolvedOutlets) => {
                const filteredOutlets = resolvedOutlets.filter((outlet) => outlet !== null);
                setOutletData(filteredOutlets);
            });
        });

        // Unsubscribe from the snapshot listener when the component unmounts
        return () => unsubscribe();
    };


    const searchFilterFunction = (text) => {
        if (text) {
            const newData = userProject.filter((item) => {
                const itemData = item?.name ? item?.name.toUpperCase() : "".toUpperCase();
                const textData = text.toUpperCase();
                return itemData.indexOf(textData) > -1;
            });
            setUserSearch(newData);
            setInputSearch(text);
        } else {
            setUserSearch([]);
            setInputSearch(text);
        }
    };

    const handleOpenModal = (value) => {
        setModalUser(true);
        const projectFilter = projectData.find((x) => x.companyId === value.companyId);
        setUserProject(projectFilter.usersProjectData);
        setUserSearch(projectFilter.usersProjectData);
        setOutletActive(value);
    };

    const handleOpenModalOutlet = (value) => {
        setModalOutletUser(true);
        setOutletActive(value);
    };

    const handleOpenModalProject = (value) => {
        setModalProjectUser(true);
        setProjectActive(value);
    };

    const handleOpenModaProjectTeam = (value) => {
        setModalProjectUserTeam(true);
        setProjectActive(value);
    }

    const handleSearchUsers = (q) => {
        // console.log(q)
        const companyUsers = globalState.companies.find((x) => x.id === globalState.currentCompany)
        const newArr = companyUsers?.users.join(",")

        const searchParameters = {
            q: q,
            query_by: "name,email",
            filter_by: `id: [${newArr}]`,
            sort_by: "_text_match:desc"
        };
        clientTypessense
            .collections("users")
            .documents()
            .search(searchParameters)
            .then((x) => {
                setSearchResult(x)
            });
    }

    const handleAddTeamProject = async () => {


        selectedUserProjectIds.forEach(async (x) => {
            const collectionName = `projects/${projectActive.id}/users`;
            const docName = x.id;
            const data = x;

            try {
                const result = await setDocumentFirebase(collectionName, docName, data);
                console.log(result);

                // Pesan toast yang berhasil
            } catch (error) {
                console.log('Terjadi kesalahan:', error);
            }

        })

        const mapIdUser = selectedUserProjectIds.map((x) => x.id)
        const collectionName = 'projects';
        const docName = `${projectActive.id}`;
        const field = 'users';
        const values = mapIdUser;

        try {
            const result = await arrayUnionFirebase(collectionName, docName, field, values);
            console.log(result); // Pesan toast yang berhasil
        } catch (error) {
            console.log('Terjadi kesalahan:', error);
        }

        setSelectedUserProjectIds([]);
        setProjectActive("");
        setSearchResult([]);
        setModalUser(false);
        getDataProjects();
        getDataOutlets();

    }

    useEffect(() => {
        getDataProjects();

        return () => {

        };
    }, [globalState.currentCompany]);

    useEffect(() => {
        getDataOutlets();

        return () => {

        };
    }, [globalState.currentProject]);

    const role = "manager"

    return (
        <Stack spacing={3}>
            {/* <ProjectCard projectData={projectData} navigate={navigate} handleOpenModalProject={handleOpenModalProject} handleOpenModaProjectTeam={handleOpenModaProjectTeam} />

            <Divider /> */}
            <OutletCard outletData={outletData} handleOpenModal={handleOpenModal} handleOpenModalOutlet={handleOpenModalOutlet} />

            <Modal isOpen={modalUser} onClose={() => setModalUser(false)} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nama</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            type="text"
                            placeholder="Name"
                            onChange={(e) => {
                                searchFilterFunction(e.target.value);
                            }}
                        />
                        <Stack spacing={1} py={3}>
                            {userSearch.length > 0 &&
                                userSearch.map((x, index) => {
                                    const isSelected = selectedUserIds.includes(x);
                                    return (
                                        <HStack cursor={'pointer'} spacing={2} key={index} bgColor='gray.100' p={2} borderRadius='lg' onClick={() => handleUserClick(x)}>
                                            <Stack>
                                                <Avatar size={'sm'} name={x?.name} />
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Text fontSize={'sm'}>{x?.name}</Text>
                                                <Text fontSize={'xs'}>{x?.email}</Text>
                                            </Stack>
                                            <Spacer />
                                            {isSelected ? null : <FcPlus />}
                                        </HStack>
                                    );
                                })
                            }
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Flex gap={5}>
                            <AvatarGroup size='sm' gap='1' max={4}>
                                {selectedUserIds.length > 0 &&
                                    selectedUserIds.map((x, i) => <Avatar key={i} name={x?.name} />)
                                }
                            </AvatarGroup>
                            <Spacer />
                            <Button
                                leftIcon={<AddIcon boxSize={3} />}
                                colorScheme="green"
                                onClick={() => handleAddTeam()}
                            >
                                Add Team
                            </Button>
                            <Button
                                leftIcon={<CloseIcon boxSize={3} />}
                                colorScheme="red"
                                onClick={() => {
                                    setModalUser(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            <Modal isOpen={modalOutletUser} onClose={() => setModalOutletUser(false)} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Team</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={1} py={3}>
                            {outletActive?.usersOutletData?.length > 0 &&
                                outletActive?.usersOutletData?.map((x, index) => {
                                    let roleUser = '';
                                    if (outletActive?.owners?.includes(x.id)) {
                                        roleUser = 'owner';
                                    } else if (outletActive?.managers?.includes(x.id)) {
                                        roleUser = 'manager';
                                    } else if (outletActive?.users?.includes(x.id)) {
                                        roleUser = 'user';
                                    } else if (outletActive?.admin?.includes(x.id)) {
                                        roleUser = 'admin';
                                    }

                                    const handleChangeRole = async (event) => {
                                        // Mengubah role pengguna
                                        const selectedRole = event.target.value;


                                        if (role === "manager") {
                                            const collectionName = 'outlets';
                                            const docName = outletActive.id;
                                            const field = selectedRole === "manager" ? "managers" : selectedRole === "user" ? "users" : "admin";
                                            const values = [x.id];

                                            try {
                                                await arrayUnionFirebase(collectionName, docName, field, values);

                                                toast({
                                                    title: "Berhasil",
                                                    description: "berhasil mengupdate role team",
                                                    status: "success",
                                                    duration: 9000,
                                                    isClosable: true,
                                                });

                                            } catch (error) {
                                                console.log('Terjadi kesalahan:', error);
                                            }

                                        } else {
                                            toast({
                                                title: "Warning",
                                                description: "Kamu tidak mempunyai akses untuk mengubah role",
                                                status: "warning",
                                                duration: 9000,
                                                isClosable: true,
                                            });
                                        }



                                        // Implementasikan logika untuk mengubah role pengguna sesuai dengan kebutuhan Anda
                                    };

                                    return (
                                        <HStack
                                            cursor={'pointer'}
                                            spacing={2}
                                            key={index}
                                            bgColor='gray.100'
                                            p={2}
                                            borderRadius='lg'
                                        >
                                            <Stack>
                                                <Avatar size={'sm'} name={x?.name} />
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Text fontSize={'sm'} fontWeight={500} textTransform='capitalize'>
                                                    {x?.name}
                                                </Text>
                                                <Text fontSize={'xs'}>{x?.email}</Text>
                                            </Stack>
                                            <Spacer />
                                            <Stack>

                                                <Select
                                                    size='xs'
                                                    defaultValue={roleUser}
                                                    onChange={handleChangeRole}
                                                    variant='outline'
                                                    fontWeight='normal'
                                                >
                                                    <option value='manager'>Manager</option>
                                                    <option value='admin'>Admin</option>
                                                    <option value='user'>User</option>
                                                </Select>

                                            </Stack>
                                        </HStack>
                                    );
                                })}
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Flex gap={5}>
                            <Button
                                leftIcon={<CloseIcon boxSize={3} />}
                                colorScheme='red'
                                onClick={() => {
                                    setModalOutletUser(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={modalProjectUser} onClose={() => setModalProjectUser(false)} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Project Team</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={1} py={3}>
                            <HStack m='1'>
                                <Input type='text' placeholder='Search users' onChange={(e) => handleSearchUsers(e.target.value)} />
                            </HStack>
                            {searchResult?.found > 0 ?
                                searchResult.hits.map((x, index) => {

                                    return (
                                        <HStack key={index} p='2' borderBottom='1px'>
                                            <Avatar name={x.document.name} src={x.document.image ? x.document.image : ''} />
                                            <Box>
                                                <Text>{x.document.name}</Text>
                                                <Text>{x.document.email}</Text>
                                            </Box>
                                            <Spacer />
                                            <Button colorScheme='green' onClick={() => handleUserProjectClick(x.document)}>+</Button>
                                        </HStack>
                                    )

                                })
                                : <></>}
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Flex gap={5}>
                            <AvatarGroup size='sm' gap='1' max={4}>
                                {selectedUserProjectIds.length > 0 &&
                                    selectedUserProjectIds.map((x, i) => <Avatar key={i} name={x?.name} />)
                                }
                            </AvatarGroup>
                            <Spacer />
                            <Button
                                leftIcon={<AddIcon boxSize={3} />}
                                colorScheme="green"
                                onClick={() => handleAddTeamProject()}
                            >
                                Add Team
                            </Button>
                            <Button
                                leftIcon={<CloseIcon boxSize={3} />}
                                colorScheme='red'
                                onClick={() => {
                                    setModalProjectUser(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={modalProjectUserTeam} onClose={() => setModalProjectUserTeam(false)} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Team</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={1} py={3}>
                            {projectActive?.usersProjectData?.length > 0 &&
                                projectActive?.usersProjectData?.map((x, index) => {
                                    let roleUser = '';
                                    if (projectActive?.owners?.includes(x.id)) {
                                        roleUser = 'owner';
                                    } else if (projectActive?.managers?.includes(x.id)) {
                                        roleUser = 'manager';
                                    } else if (projectActive?.users?.includes(x.id)) {
                                        roleUser = 'user';
                                    } else if (projectActive?.admin?.includes(x.id)) {
                                        roleUser = 'admin';
                                    }

                                    const handleChangeRoleProject = async (event) => {
                                        // Mengubah role pengguna
                                        const selectedRole = event.target.value;


                                        if (role === "manager") {
                                            const collectionName = 'projects';
                                            const docName = projectActive.id;
                                            const field = selectedRole === "manager" ? "managers" : selectedRole === "user" ? "users" : "admin";
                                            const values = [x.id];

                                            try {
                                                await arrayUnionFirebase(collectionName, docName, field, values);

                                                toast({
                                                    title: "Berhasil",
                                                    description: "berhasil mengupdate role team",
                                                    status: "success",
                                                    duration: 9000,
                                                    isClosable: true,
                                                });

                                            } catch (error) {
                                                console.log('Terjadi kesalahan:', error);
                                            }

                                        } else {
                                            toast({
                                                title: "Warning",
                                                description: "Kamu tidak mempunyai akses untuk mengubah role",
                                                status: "warning",
                                                duration: 9000,
                                                isClosable: true,
                                            });
                                        }



                                        // Implementasikan logika untuk mengubah role pengguna sesuai dengan kebutuhan Anda
                                    };

                                    return (
                                        <HStack
                                            cursor={'pointer'}
                                            spacing={2}
                                            key={index}
                                            bgColor='gray.100'
                                            p={2}
                                            borderRadius='lg'
                                        >
                                            <Stack>
                                                <Avatar size={'sm'} name={x?.name} />
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Text fontSize={'sm'} fontWeight={500} textTransform='capitalize'>
                                                    {x?.name}
                                                </Text>
                                                <Text fontSize={'xs'}>{x?.email}</Text>
                                            </Stack>
                                            <Spacer />
                                            <Stack>

                                                <Select
                                                    size='xs'
                                                    defaultValue={roleUser}
                                                    onChange={handleChangeRoleProject}
                                                    variant='outline'
                                                    fontWeight='normal'
                                                >
                                                    <option value='manager'>Manager</option>
                                                    <option value='admin'>Admin</option>
                                                    <option value='user'>User</option>
                                                </Select>

                                            </Stack>
                                        </HStack>
                                    );
                                })}
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Flex gap={5}>
                            <Button
                                leftIcon={<CloseIcon boxSize={3} />}
                                colorScheme='red'
                                onClick={() => {
                                    setModalProjectUserTeam(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
    );
};