import { ArrowRightIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  color,
  Flex,
  Heading,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
  Button,
  Divider,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { BiEditAlt, BiHistory } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import useUserStore from "../../Hooks/Zustand/Store";
import { convertMilisecond } from "../../Utils/timeUtil";

function BasicCardComponent(props) {
  const globalState = useUserStore();

  const navigate = useNavigate()

  let data = {};
  if (props.data) data = props.data;
  else
    data = {
      image: "https://bit.ly/dan-abramov",
      title: "ini title",
      subTitle: [],
      createdAt: { seconds: 124124124 },
      linkTo: "",
      editLink: data.id,
      deleteLink: "",
    };

  return (
    <Box
      minH="40px"
      borderRadius="md"
      shadow="xl"
      p={3}
      border="1px"
      borderColor="#F05A28"
      width="full"
      alignItems={"flex-start"}
      justifyContent="flex-start"
    >
      <Flex direction={["column", "column", 'row']} align="center" gap={5}>
        <Image
          width="150px"
          height={"100px"}
          borderRadius="lg"
          objectFit={'cover'}
          src={data?.image_url ? data.image_url : "https://bit.ly/dan-abramov"}
          alt={data.title}
        />

        <Stack spacing={1} fontSize="sm">
          <HStack>
            <Heading fontSize="md">{data.title}</Heading>

            <HStack>
              {data?.subTitle ? (
                data.subTitle.map((x) => <Text fontWeight={500}>{x}</Text>)
              ) : (
                <></>
              )}
              <Text fontWeight={500}>
                {convertMilisecond(data.createdAt.seconds)}
              </Text>
            </HStack>
          </HStack>

          <HStack>
            <Text>Category :</Text>
            <Text fontWeight={500}> {data.category}</Text>
          </HStack>

          <HStack>
            <Text>Stock :</Text>
            <Text fontWeight={500}> {data.stock}</Text>
          </HStack>

          <HStack alignItems={"flex-start"} justifyContent="flex-start">
            <Text>Stations : </Text>
            <Text fontSize={"sm"} fontWeight={500}>
              {data?.stations?.length > 0 &&
                data?.stations.map((x) => x).join(", ")}
            </Text>


          </HStack>

          <HStack alignItems={"flex-start"} justifyContent="flex-start">
            <Text>Outlets : </Text>
            <Stack spacing={0}>
              {globalState.outlets
                .filter((datas) => data?.outlets?.[0] === datas?.id)
                .map((x) => (
                  <Text key={x.id} fontWeight={500}>
                    {x.name}
                  </Text>
                ))}
            </Stack>
          </HStack>
        </Stack>
      </Flex>

      <Divider my={2} />

      <Flex direction={["column", "row", "row"]} gap={5} alignItems='center' justifyContent={'center'}>

        <Button
          size={'sm'}
          colorScheme="red"
          leftIcon={<DeleteIcon />}
          onClick={() => {
            props.setModalDelete(true);
            props.setMenuID(data.id);
          }}
        >
          Delete Menu
        </Button>

        <Stack  >
          <Button
            size={'sm'}
            colorScheme="gray" leftIcon={<BiEditAlt />} onClick={() => navigate(`${data.id}/edit`)}>
            Edit Menu
          </Button>
        </Stack>

        <Stack  >
          <Button
            size={'sm'}
            colorScheme="gray" leftIcon={<BiHistory />} onClick={() => props.openModalStock(data)}>
            History
          </Button>
        </Stack>


      </Flex>
    </Box>
  );
}

export default BasicCardComponent;
