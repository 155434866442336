import { Box, Button, Heading, HStack, Input, Spacer, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { formatFrice } from '../../Utils/numberUtil';

function OrderPanel({
    order,
    handleDecreaseQuantity,
    handleIncreaseQuantity,
    setOrder,
    totalOrder,
    setTotalOrder,
    onOpen,
    tax,
    orderSave,
    handleOrderPay,
    handleOrderSave,
}) {
    return (
        <Stack>
                {order?.length > 0 ? (
                    order?.map((x, i) => {
                        return (
                            <Stack key={i}>
                                <HStack>
                                    <Box>
                                        <Text fontSize={"md"}>{x.title}</Text>
                                        {x.price_type && (
                                            <Text fontSize={"xs"}>Type : {x.price_type}</Text>
                                        )}
                                        {x.variant && (
                                            <Text fontSize={"xs"}>
                                                Variant : {x?.variant}
                                            </Text>
                                        )}
                                    </Box>
                                    <Spacer />
                                    <Stack>
                                        <Text fontSize={"sm"}>
                                            Rp.{formatFrice(parseFloat(x.price))}
                                        </Text>
                                        <HStack>
                                            <Button
                                                size={"sm"}
                                                onClick={() => handleDecreaseQuantity(i)}
                                            >
                                                -
                                            </Button>
                                            <Text>{x.quantity}</Text>
                                            <Button
                                                size={"sm"}
                                                onClick={() => handleIncreaseQuantity(i)}
                                            >
                                                +
                                            </Button>
                                        </HStack>
                                    </Stack>
                                </HStack>
                                <Input
                                    size="sm"
                                    placeholder={"Tambah catatan"}
                                    onChange={(e) => {
                                        const updatedOrder = [...order];
                                        updatedOrder[i].notes = e.target.value;
                                        setOrder(updatedOrder);
                                    }}
                                    value={x.notes}
                                />
                            </Stack>
                        );
                    })
                ) : (
                    <></>
                )}

                <HStack p={2}>
                    <Text size={"md"}>Order Harga</Text>
                    <Spacer />
                    <Text size={"md"}>
                        Rp. {formatFrice(totalOrder.totalPrice)}
                    </Text>
                </HStack>
                <HStack p={2}>
                    <Text size={"md"}>Diskon</Text>
                    <Spacer />
                    <Text size={"md"}>
                        Rp. {formatFrice(totalOrder.discount)}
                    </Text>
                </HStack>

                <HStack p={2}>
                    <Text size={"md"}>Tax ({tax}%)</Text>
                    <Spacer />
                    <Text size={"md"} color='red.300'>Rp. {formatFrice(totalOrder.tax)}</Text>
                </HStack>

                <HStack p={2}>
                    <Heading size={"sm"}>Total </Heading>
                    <Spacer />
                    <Heading size={"sm"}>
                        Rp.
                        {formatFrice(
                            totalOrder.totalPrice +
                            totalOrder.tax -
                            totalOrder.discount
                        )}
                    </Heading>
                </HStack>
                {orderSave ? (
                    <Stack alignItems={'center'} justifyContent='center'>
                        <Heading size="md" color={'gray.400'} >Please wait your order's</Heading>
                    </Stack>

                ) : (
                    <Stack spacing={2}>
                        <Spacer />
                        <HStack>
                            <Text>Customer name:</Text>
                            <Spacer />
                            <Input w={'50%'} size={'sm'} placeholder="name" onChange={(e) => setTotalOrder({
                                ...totalOrder,
                                name: e.target.value
                            })} />
                        </HStack>
                        <Button
                            w="full"
                            size={"sm"}
                            colorScheme="blue"
                            onClick={onOpen}
                            p={2}
                        >
                            Kode Diskon
                        </Button>
                        <HStack w="full" s>
                            <Button
                                w="full"
                                colorScheme="red"
                                onClick={() => handleOrderPay()}
                                isDisabled={order.length === 0}
                            >
                                Transfer
                            </Button>
                            <Button
                                w="full"
                                colorScheme="green"
                                onClick={() => handleOrderSave()}
                                isDisabled={order.length === 0}
                            >
                                Order to cashier
                            </Button>
                        </HStack>
                    </Stack>
                )}

        </Stack>
    )
}

export default OrderPanel