import {
  Box,
  Button,
  Container,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useToast,
  FormControl,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Badge,
  Spacer,
} from "@chakra-ui/react";
import { and, collection, getDocs, or, query, where } from "firebase/firestore";
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import { db } from "../../Config/firebase";

import "swiper/css";
import axios from "axios";

import OrderCustomerCartDrawer from "./OrderCustomerCartDrawer";
import OrderCustomerPayDrawer from "./OrderCustomerPayDrawer";
import OrderCustomerSuccesPayDrawer from "./OrderCustomerSuccessPayDrawer";
import { FcMenu, FcPlus, FcTodoList, FcVoicePresentation } from "react-icons/fc";
import HelpPanel from "../../Components/Pageview/HelpPanel";
import MenuPanel from "../../Components/Pageview/MenuPanel";
import OrderPanel from "../../Components/Pageview/OrderPanel";
import { formatFrice } from "../../Utils/numberUtil";

// check status of the outlet / table

function OrdersCustomerPage() {
  const [order, setOrder] = useState([]);
  const [dataProject, setDataProject] = useState("")
  const [categoryData, setCategoryData] = useState([]);
  const [totalOrder, setTotalOrder] = useState({
    name: "",
    totalPrice: 0,
    tax: 0,
    discount: 0,
  });
  const [menuSelected, setMenuSelected] = useState({});
  const [loading, setLoading] = useState(false)
  const [imageCarouselDashboard, setImageCarouselDashboard] = useState([])

  const [orderPayDrawer, setOrderPayDrawer] = useState(false);
  const [addToCartDrawer, setAddToCartDrawer] = useState(false);
  const [sucessPayDrawer, setSucessPayDrawer] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedPaymentMethodImage, setSelectedPaymentMethodImage] =
    useState("");

  const [filterCategory, setFilterCategory] = useState("all");

  const [orderSave, setOrderSave] = useState(false)
  const [panelActive, setPanelActive] = useState("menu")

  const [dataOutlet, setDataOutlet] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [panelActive]);

  const handleTabChange = (menu) => {
    setPanelActive(menu);
  };

  const [orderId, setOrderId] = useState("");
  const [orderSummary, setOrderSummary] = useState();
  const [tax, setTax] = useState(0)

  // const companyId = "xEU2UrHGKOOlQIDdPNlE";


  const banks = [
    {
      name: "BNI",
      uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png",
    },
    {
      name: "MANDIRI",
      uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png",
    },
    {
      name: "PERMATA",
      uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png",
    },
    {
      name: "BRI",
      uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png",
    },
  ];

  const param = useParams();

  const toast = useToast({
    position: "top",
    align: "center",
  });
  // console.log(param.outletId, 'outlet')
  //=========================================================================================================
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputDiscount, setInputDiscount] = useState("");
  const [discountType, setDiscountType] = useState({});
  const [dataMenu, setDataMenu] = useState([]);

  const [paymentVA, setPaymentVA] = useState("");
  const [adminFee, setAdminFee] = useState(4000)
  const [loadingPay, setLoadingPay] = useState(false)


  const dummyDiscount = [
    {
      amount: 20,
      title: "DISCOUNTFIRST",
      type: "percentage",
    },
    {
      amount: 50000,
      title: "DISCOUNTKARYAWAN",
      type: "value",
    },
  ];

  const getData = async () => {

    try {
      const result = await getSingleDocumentFirebase('outlets', param.outletId)
      if (result) {
        setDataOutlet(result)

        getDataDashboard(result.companyId, result.projectId)

        const conditions = [
          { field: "companyId", operator: "==", value: result.companyId },
          { field: "outlets", operator: "array-contains", value: param.outletId },
          // { field: "nama_field_2", operator: ">", value: "nilai_2" },
        ];

        getCollectionFirebase("menus", conditions).then((x) => {
          const filteredData = x.filter(
            (y) => y?.outlets?.includes(param.outletId) && y.prices || y.price
          );
          const sortData = filteredData.sort((a, b) => a.title.localeCompare(b.title))
          setDataMenu(sortData);

          // Mapping data berdasarkan kategori
          const mappedData = {};
          filteredData.forEach((menu) => {
            const category = menu.category;
            if (!mappedData[category]) {
              mappedData[category] = [];
            }
            mappedData[category].push(menu);
          });

          setCategoryData(mappedData);
        });

        const resultTax = await getSingleDocumentFirebase('tax', result.companyId)
        setTax(resultTax.amount)

      }
    } catch (error) {
      console.log(error, 'ini errors')
    }

  };

  const getDataDashboard = async (companyId, projectId) => {

    try {
            const result = await getSingleDocumentFirebase('rms', projectId)
            setDataProject(result)
            setImageCarouselDashboard(result.image_dashboard)
          } catch (error) {
            console.log(error)
          }


  };

  const sucessOrder = () => {
    // setOrder([]);
    // setTotalOrder({ name: "anonymous", totalPrice: 0 });
    setSucessPayDrawer(true);
    setOrderSave(true)
  };

  const handleAddToCart = (x) => {
    setMenuSelected({ ...x, quantity: 1 });
    setAddToCartDrawer(true);
  };

  const handleOrderSave = () => {
    const updatedOrdersChecker = order.map((order, index) => {
      const stationIndex = (index % 2) + 1; // Menghitung indeks stasiun (1 atau 2) berdasarkan indeks order
      const suffix = `_${stationIndex}`;

      const updatedStations = order.stations.map((station) => {
        return station.replace("checker", `checker${suffix}`);
      });

      return {
        ...order,
        stations: updatedStations,
      };
    });

    if(totalOrder.name === ""){
      return  toast({
        title: 'Warning!',
        description: 'please input your name order.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    }

    const newOrder = {
      ...totalOrder,
      orders: updatedOrdersChecker,
      paymentStatus: "open",
      orderStatus: "onProcess",
      outlet: param.outletId,
      table: param.table,
      outletId: dataOutlet.outletId,
      projectId: dataOutlet.projectId,
    };
    newOrder.paymentStatus = "open";
    newOrder.orderStatus = "onProcess";
    newOrder.module = "rms";
    newOrder.tax = tax;
    newOrder.discount = newOrder.discount ? newOrder.discount : 0;


    addDocumentFirebase("orders", newOrder, dataOutlet.companyId).then((x) => {
      setDiscountType({});
      setOrderSummary(newOrder);
      sucessOrder(x);
    });
  };

  const getDiscount = () => {
    if (totalOrder.totalPrice === 0) {
      toast({
        title: "Sambal Bakar",
        description: "Harus Beli Terlebih Dahulu",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      const findDiscountTitle = dummyDiscount.find(
        (x) => x.title === inputDiscount
      );

      if (findDiscountTitle) {
        setDiscountType(findDiscountTitle);
        toast({
          title: "Sambal Bakar",
          description: "Berhasil Claim Diskon",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: "Sambal Bakar",
          description: "Gagal Claim Diskon",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setInputDiscount("");
  };

  const calculateTotalPrice = useCallback(() => {
    let discount = 0;

    const totalPrice = order.reduce(
      (acc, item) => acc + parseInt(item.price),
      0
    );

    if (order.length > 0) {
      if (discountType) {
        if (discountType.type === "percentage") {
          discount = (totalPrice * discountType.amount) / 100;
        }
        if (discountType.type === "value") {
          discount = discountType.amount;
        }
      }
    }

    setTotalOrder((prev) => ({
      ...prev,
      totalPrice,
      tax: ((totalPrice - discount || 0) * tax) / 100,
      discount,
    }));
  }, [order, discountType]);

  const handleOrderPay = () => {
    setOrderPayDrawer(true);
  };

  const handleOrderPayConfirm = async () => {
    setPaymentVA("");

    const updatedOrdersChecker = order.map((order, index) => {
      const stationIndex = (index % 2) + 1; // Menghitung indeks stasiun (1 atau 2) berdasarkan indeks order
      const suffix = `_${stationIndex}`;

      const updatedStations = order.stations.map((station) => {
        return station.replace("checker", `checker${suffix}`);
      });

      return {
        ...order,
        stations: updatedStations,
      };
    });

    const updatedOrder = {
      ...totalOrder,
      orders: updatedOrdersChecker,
      paymentStatus: "open",
      orderStatus: "onProcess",
      outlet: param.outletId,
      outletId: dataOutlet.outletId,
      projectId: dataOutlet.projectId,
      table: param.table,
      paymentMethod: "transfer_bank",
      module: "rms",
      tax: tax,
      discount: totalOrder.discount ? totalOrder.discount : 0,
    };

    const fixPrice =
      (((updatedOrder.totalPrice - updatedOrder.discount || 0)) +
        ((updatedOrder.totalPrice - updatedOrder.discount || 0) * updatedOrder.tax) / 100) //b


    addDocumentFirebase("orders", updatedOrder, dataOutlet.companyId).then((x) => {
      setOrderSummary(updatedOrder);
      return handlePaymentTransfer(x, updatedOrder, fixPrice);
    });
  };

  const handlePaymentTransfer = async (id, updatedOrder, fixPrice) => {
    setOrderId(id);
    setLoadingPay(true)

    if(updatedOrder.name === ""){
      return  toast({
        title: 'Warning!',
        description: 'please input your name order.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    }

    const baseUrl =
      "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/";

    const data = {
      xenditId: "6479f64913999eb3b3fe7283",
      orderId: id,
      amount: fixPrice,
      bankCode: selectedPaymentMethod,
      name: updatedOrder.name,
      companyId: dataOutlet.companyId,
      outletId: param.outletId,
      projectId: dataOutlet.projectId,
      module: "rms",
      userId: "rifqyganteng",
      feeRule: true
    };

    console.log(data, 'ini data')

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_PAYMENT_KEY,
      },
    };


    try {
      const res = await axios.post(`${baseUrl}/paymentCreateVA`, data, options);
      if (res.data.status === true) {
        setPaymentVA(res.data.data);
        setLoadingPay(false)
      } else {
        console.log(res.data.data);

        toast({
          title: 'Warning!',
          description: 'Terjadi Kesalah Generate VA, Silahkan menghubungi kasir.',
          status: 'warning',
          duration: 9000,
          isClosable: true,
        })

        setLoadingPay(false)

      }
    } catch (error) {
      console.log(error, "ini error");

      toast({
        title: 'Error!',
        description: 'Terjadi Kesalah, Silahkan menghubungi kasir.',
        status: error,
        duration: 9000,
        isClosable: true,
      })

      setLoadingPay(false)

    }
    setLoadingPay(false)

  };

  const handleCancelPayment = async () => {
    try {
      const result = await deleteDocumentFirebase("orders", orderId);
      if (result) {
        setOrderId("");
        setPaymentVA("");
      }
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleInputPayment = async () => {
    try {
      const result = await getSingleDocumentFirebase("payments", orderId);
      if (result.status === "PENDING") {
        toast({
          title: "Sambal Bakar",
          description:
            "Kamu Belum Melakukan Transfer, harap segera transfer atau bayar di cashier.",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }
      if (result.status === "PAID") {
        updateDocumentFirebase("orders", orderId, {
          paymentStatus: "complete",
          updated_bill: new Date(),
        }).then((res) => {
          if (res) {
            sucessOrder(res);
            console.log(res);

            // // update stock when success pay
            order.map((val) => {
              dataMenu
                .filter((list) => list.id === val.id)
                .map((data) => {
                  updateDocumentFirebase("menus", data.id, {
                    stock: data.stock - val.quantity,
                  });
                });
            });
            setDiscountType({});
            setOrderPayDrawer(false);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
    const imageFind = banks.find((x) => x.name === method);
    setSelectedPaymentMethodImage(imageFind.uri);
  };

  const getOrderActive = async () => {
    // console.log(param);
    const q = query(
      collection(db, "orders"),
      and(
        where("outletsId", "==", param.outletId),
        or(
          where("paymentStatus", "==", "open"),
          where("orderStatus", "==", "onProcess")
        )
      )
    );
    // const q = query(collection(db, "orders"), where("orderStatus", "==", "onProcess"));
    try {
      const querySnapshot = await getDocs(q);
      const lastOrder = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        lastOrder.push({ ...doc.data(), id: doc.id });
      });
      setOrder(lastOrder[0].orders);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleAddOrder = (menu, index) => {
    const newOrder = [...order];

    const indexSameOrder = order.findIndex((x) => x.id === menu.id);

    if (menu?.price_type === 'multiple' && menu?.priceIndex === 0 && !menu?.variant) {

      return toast({
        title: 'Pilih Sambal',
        description: 'Pilih sambal Bakka (level 1), Barra (level 2),  atau Gajja (level 3)',
        status: 'warning',
        position: 'bottom',
        isClosable: true,
        duration: 9000
      })
    }

    if (indexSameOrder >= 0) {
      const { price, quantity } = newOrder[indexSameOrder];
      const quantityPerPrice = price / quantity;

      newOrder[indexSameOrder] = {
        ...newOrder[indexSameOrder],
        quantity: quantity + menu.quantity,
        price: quantityPerPrice * (quantity + menu.quantity),
      };
    } else {
      if (menu.price_type === "multiple") {
        let price_type = "";
        if (menu.priceIndex === 0) {
          price_type = "komplit";
        } else if (menu.priceIndex === 1) {
          price_type = "ala carte";
        }

        newOrder.push({
          id: menu.id,
          title: menu.title,
          base_price: menu.prices[menu.priceIndex],
          price:
            menu.variant === "barra"
              ? (parseInt(menu.prices[menu.priceIndex]) + 1000) * menu.quantity
              : menu.prices[menu.priceIndex] * menu.quantity,
          stations: menu.stations,
          quantity: menu.quantity,
          price_type: price_type,
          category: menu.category,
          notes: menu.notes,
          variant_type: menu.variant_type ? menu.variant_type : "multiple",
          variant: price_type === "komplit" ? menu.variant ? menu.variant : "" : ""
        });
      } else {
        newOrder.push({
          id: menu.id,
          title: menu.title,
          base_price: menu.price,
          price:
            menu.variant === "barra"
              ? (parseInt(menu.price) + 1000) * menu.quantity
              : menu.price * menu.quantity,
          stations: menu.stations,
          quantity: menu.quantity,
          category: menu.category,
          notes: menu.notes,
          variant_type: menu.variant_type ? menu.variant_type : "single",
        });
      }

    }

    setAddToCartDrawer(false)
    toast({
      title: `Berhasil menambahkan ${menu?.title}`,
      description:
        `Jumlah : ${menu?.quantity}`,
      status: "success",
      isClosable: true,
      duration: 9000
    })

    setOrder(newOrder);

    // console.log(newOrder, 'xxx');

  };

  const handleIncreaseQuantity = (index) => {
    const updatedOrder = [...order];
    const quantityPerPrice =
      updatedOrder[index].price / updatedOrder[index].quantity; // Hitung harga item per quantity

    updatedOrder[index].quantity += 1;
    updatedOrder[index].price = quantityPerPrice * updatedOrder[index].quantity;
    setOrder(updatedOrder);
  };

  const handleDecreaseQuantity = (index) => {
    const updatedOrder = [...order];
    const quantity = updatedOrder[index].quantity;

    if (quantity > 1) {
      const quantityPerPrice =
        updatedOrder[index].price / updatedOrder[index].quantity;
      updatedOrder[index].quantity -= 1;
      updatedOrder[index].price =
        quantityPerPrice * updatedOrder[index].quantity;
      setOrder(updatedOrder);
    } else if (quantity === 1) {
      updatedOrder.splice(index, 1); // Menghapus orderan dari daftar
      setOrder(updatedOrder);
    }
  };

  useEffect(() => {
    getData();
    getOrderActive();

    return () => { };
  }, []);

  const activeSlide =
    Object.keys(categoryData).indexOf(filterCategory) >= 0
      ? Object.keys(categoryData).indexOf(filterCategory)
      : 0;

  // Buat fungsi total harga ganti secara dynamic
  useEffect(() => {
    calculateTotalPrice();
  }, [calculateTotalPrice]);

  return (
    <>
      <Container maxW="xl" shadow="base" minH="100vh" position={'relative'}>
        <Stack py={4} bgColor={dataProject.color_view || "blue.300"} shadow={"md"} color='white'>
          <Heading textAlign="center" size={"md"} textTransform='uppercase'>
            {dataOutlet.name}
          </Heading>
        </Stack>


        <Stack
          isLazy
          isFitted
          h={"75vh"} overflowY='scroll'
        >
          {
            panelActive === "menu" ?
              <MenuPanel
                loading={loading}
                imageCarouselDashboard={imageCarouselDashboard}
                activeSlide={activeSlide}
                setFilterCategory={setFilterCategory}
                filterCategory={filterCategory}
                categoryData={categoryData}
                dataMenu={dataMenu}
                handleAddToCart={handleAddToCart}
                totalOrder={totalOrder}
                handleTabChange={handleTabChange}
                order={order}
              />
              : panelActive === "help" ? <HelpPanel /> :
                <OrderPanel
                  order={order}
                  handleDecreaseQuantity={handleDecreaseQuantity}
                  handleIncreaseQuantity={handleIncreaseQuantity}
                  setOrder={setOrder}
                  totalOrder={totalOrder}
                  setTotalOrder={setTotalOrder}
                  onOpen={onOpen}
                  tax={tax}
                  orderSave={orderSave}
                  handleOrderPay={handleOrderPay}
                  handleOrderSave={handleOrderSave}
                />
          }


        </Stack>
        
        <Stack
          w='100%' // Tambahkan properti bottom: 0
          position={'sticky'}
          bottom={0}
        >

          <Stack
            direction={{ base: "column", md: "row" }}
            w="full"
            color="white"
            bgColor={dataProject.color_view || "blue.300"}
            p="3"
            align={{ base: "normal", md: "center" }}
            zIndex={10}
            borderTopRadius='lg'
          >
            <Box>
              <Text fontSize={"sm"}>Total Price</Text>
              <Stack direction={{ base: "column", lg: "row" }}>
                <Heading size={"md"} as={totalOrder.discount ? "del" : ""}>
                  Rp. {formatFrice((totalOrder.totalPrice) + totalOrder.tax)}
                </Heading>
                {totalOrder.discount !== 0 && (
                  <Heading size={"md"} color="white">
                    Rp.{" "}
                    {formatFrice(
                      totalOrder.totalPrice +
                      totalOrder.tax -
                      totalOrder.discount
                    )}
                  </Heading>
                )}
              </Stack>
            </Box>
            <Spacer />
            <Button
              size={"sm"}
              color="black"
              onClick={() => handleTabChange("order")}
              style={{ position: "relative", display: "inline-block" }}
            >
              {order?.length > 0 ? (
                <Badge
                  top={0}
                  right={0}
                  pos="absolute"
                  rounded="full"
                  color="white"
                  bg="red"
                >
                  {order?.length}
                </Badge>
              ) : (
                <></>
              )}
              <Text>Lihat Pesanan Saya</Text>
            </Button>
          </Stack>

            <HStack
              position="sticky"
              bottom={0}
              width="100%"
              display="flex"
              backgroundColor="white"
              borderTop="1px solid gray"
              zIndex={10}
              p={3}
              shadow='lg'
              alignItems={'center'}
              justifyContent='space-around'
            >
              <Stack cursor={'pointer'} onClick={() => handleTabChange("menu")}>
                <Stack alignItems={'center'} justifyContent='center' spacing={0}>
                  <FcMenu size={20} />
                  <Text fontSize={'xs'}>Menu</Text>
                </Stack>
              </Stack>
              <Stack cursor='pointer' onClick={() => handleTabChange("help")}>
                <Stack alignItems={'center'} justifyContent='center' spacing={0}>
                  <FcVoicePresentation size={20} />
                  <Text fontSize={'xs'}>Help</Text>
                </Stack>
              </Stack>
              <Stack cursor={'pointer'} onClick={() => handleTabChange("order")}
              >
                
                <Stack alignItems={'center'} justifyContent='center' spacing={0}>
                  <FcTodoList size={20} />
                  <Text fontSize={'xs'}>Order List</Text>
                </Stack>
              </Stack>
            </HStack>

        </Stack>


      </Container>

      <OrderCustomerCartDrawer
        store={dataOutlet.name}
        isOpen={addToCartDrawer}
        onClose={() => setAddToCartDrawer(false)}
        menu={menuSelected}
        handleAddOrder={handleAddOrder}
        order={orderSummary}
      />

      <OrderCustomerPayDrawer
        store={dataOutlet.name}
        isOpen={orderPayDrawer}
        onClose={() => setOrderPayDrawer(false)}
        param={param}
        order={order}
        totalOrder={totalOrder}
        paymentVA={paymentVA}
        selectedPaymentMethod={selectedPaymentMethod}
        selectedPaymentMethodImage={selectedPaymentMethodImage}
        handleInputPayment={handleInputPayment}
        handleCancelPayment={handleCancelPayment}
        handleOrderPayConfirm={handleOrderPayConfirm}
        handlePaymentMethodSelect={handlePaymentMethodSelect}
        banks={banks}
        loading={loadingPay}
      />

      <OrderCustomerSuccesPayDrawer
        store={dataOutlet.name}
        isOpen={sucessPayDrawer}
        onClose={() => {
          setSucessPayDrawer(false);
          setOrderSummary();
        }}
        orderData={orderSummary}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Masukkan Kode Diskon</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Kode Diskon</FormLabel>
              <Input
                textTransform="uppercase"
                onChange={(event) => {
                  setInputDiscount(event.target.value.toUpperCase());
                }}
                placeholder="Masukkan kode Diskon"
                focusBorderColor="green"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={getDiscount}>
              Get Discount
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default OrdersCustomerPage;
