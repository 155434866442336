import React from 'react'
import TransactionPage from '../Pages/Transaction/TransactionPage'

const TransactionRouter = [
	{
		path: "/transaction",
		element: <TransactionPage />
	},
]

export default TransactionRouter