import {
  Box,
  Button,
  Heading,
  HStack,
  Spacer,
  Stack,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BackButtons from "../../Components/Buttons/BackButtons";
import useUserStore from "../../Hooks/Zustand/Store";
import {
  getCollectionFirebase,
  getCollectionFirebaseV2,
} from "../../Apis/firebaseApi";
import { ActivityTable } from "../../Components/Tables/ActivityTable";
import { FaRegCalendar } from "react-icons/fa";
import DatePicker from "../../Components/Calendar/DatePicker";
import moment from "moment";

function ActivityPage() {
  const globalState = useUserStore();
  const modalFilterDate = useDisclosure();

  const [data, setData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState();

  const itemsPerPage = 5;
  const [startIndex, setStartIndex] = useState(0);

  const fetchData = async () => {
    const conditions = [
      { field: "companyId", operator: "==", value: globalState.currentCompany },
      { field: "projectId", operator: "==", value: globalState.currentProject },
      { field: "outletId", operator: "==", value: globalState.currentOutlet },
    ];
    const sortBy = { field: "createdAt", direction: "desc" };

    const limitValue = startIndex + itemsPerPage;

    try {
      const res = await getCollectionFirebaseV2(
        "warehouse_activity",
        conditions,
        sortBy,
        limitValue
      );

      console.log(data, "xx");
      setData(res);
      setOriginData(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const getDataFilter = async () => {
    const conditions = [
      {
        field: "createdAt",
        operator: ">=",
        value: selectedDateRange?.startDate,
      },
      {
        field: "createdAt",
        operator: "<=",
        value: selectedDateRange?.endDate,
      },
      { field: "companyId", operator: "==", value: globalState.currentCompany },
      { field: "projectId", operator: "==", value: globalState.currentProject },
      {
        field: "outletId",
        operator: "==",
        value: globalState?.currentOutlet,
      },
    ];

    try {
      const filteredOrders = await getCollectionFirebase(
        "warehouse_activity",
        conditions
      );
      setData(filteredOrders);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleSearchFunction = (text) => {
    if (text !== "") {
      const newData = data.filter((order) => {
        const itemData = order.name_items
          ? order.name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setData(newData);
      // setInputSearch(text);
    } else {
      setData(originData);
      // setInputSearch(text);
    }
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  // useEffect(() => {
  //   fetchData();

  //   return () => {};
  // }, [globalState.currentOutlet, startIndex]);

  useEffect(() => {
    if (!selectedDateRange) {
      fetchData();
    } else {
      getDataFilter();
    }
  }, [globalState.currentOutlet, selectedDateRange, startIndex]);

  return (
    <Stack p={[1, 1, 5]}>
      <Stack>
        <HStack>
          <BackButtons />
          <Heading>Activity</Heading>
          <Spacer />
        </HStack>
      </Stack>

      <Stack>
        <Box overflowX={"scroll"}>
          <HStack>
            <Input
              bg={"white"}
              my={2}
              placeholder={"Search items ... "}
              w={250}
              sx={inputStyles}
              onChange={(e) => handleSearchFunction(e.target.value)}
            />
            <Spacer />
            <Button
              onClick={modalFilterDate.onOpen}
              bgColor={"white"}
              shadow="md"
              variant="outline"
              borderColor="#F05A28"
              color="#F05A28"
            >
              <HStack>
                <FaRegCalendar />
                <Text>Filter date</Text>
              </HStack>
            </Button>
          </HStack>
          {data ? <ActivityTable data={data} /> : <></>}
        </Box>
      </Stack>

      <Stack alignItems={"center"} justifyContent="center" py={5}>
        <Box>
          {data?.length > startIndex && (
            <Button colorScheme={"green"} onClick={handleLoadMore} size="sm">
              Load More
            </Button>
          )}
        </Box>
      </Stack>

      <Modal
        isOpen={modalFilterDate.isOpen}
        onClose={modalFilterDate.onClose}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalBody>
            <Center>
              <DatePicker onDateChange={handleDateRangeChange} />
            </Center>

            <HStack>
              <HStack spacing={4}>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={"semibold"}>Start Date: </Text>
                  <Text>
                    {moment(selectedDateRange?.startDate).format("LLL")}
                  </Text>
                </Stack>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={"semibold"}>End Date:</Text>
                  <Text>
                    {moment(selectedDateRange?.endDate).format("LLL")}
                  </Text>
                </Stack>
              </HStack>
              <Spacer />
              <Button
                size={"xs"}
                colorScheme="blue"
                onClick={() => setSelectedDateRange()}
              >
                Clear Filter
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default ActivityPage;
