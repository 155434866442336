import {
  Badge,
  Button,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import {
  updateDocumentFirebase,
  getCollectionFirebase,
  setDocumentFirebase,
} from "../../Apis/firebaseApi";
import { formatFrice } from "../../Utils/numberUtil";
import axios from "axios";
import {
  checkOrderEditValidity,
} from "../../Hooks/Middleware/UserMiddleWare";
import { getCompanyName, getStoreName } from "../../Utils/nameUtil";
import useUserStore from "../../Hooks/Zustand/Store";

const OrdersDetailModal = ({ order: initialOrder, isOpen, onClose }) => {

  const globalState = useUserStore();

  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false)

  const [dataOrdered, setDataOrdered] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [inputDisc, setInputDisc] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [refundItem, setRefundItem] = useState([]);
  const toast = useToast();

  const dummyDiscount = [
    {
      amount: 20,
      title: "DISCOUNTFIRST",
      type: "percentage",
    },
    {
      amount: 50000,
      title: "DISCOUNTKARYAWAN",
      type: "value",
    },
  ];

  const calculateRefundPrice = useCallback(() => {
    let totalRefund = 0;
    let arrPrice = [];

    refundItem.map((data) => {
      return arrPrice.push(data?.base_price * data.quantity);
    });

    arrPrice.forEach((item) => {
      totalRefund += parseInt(item);
    });

    return totalRefund;
  }, [refundItem]);

  const calculateTotalPrice = useCallback(() => {
    let total = 0;
    if (order && order.orders) {
      order.orders.forEach((order) => {
        total += parseInt(order.price);
      });
    }
    return total;
  }, [order]);

  const calculatedTaxPrice = useCallback(() => {
    const totalPrice = calculateTotalPrice();
    return ((totalPrice - order.discount || 0) * (order?.tax || 0)) / 100;
  }, [calculateTotalPrice, order]);

  const calculateTotalFixPrice = useCallback(() => {
    const totalPrice = calculateTotalPrice();
    const taxPrice = calculatedTaxPrice();

    return totalPrice + taxPrice;
  }, [calculateTotalPrice, calculatedTaxPrice]);

  const getDiscount = useCallback(() => {
    let updateOrder = order;
    let appliedDiscount = order.discount || 0;

    if (
      // order.paymentStatus === "refund" ||
      order.paymentStatus === "compliment" ||
      order.paymentStatus === "cancel"
    ) {
      appliedDiscount = order.totalPrice;
      updateOrder.discount = appliedDiscount;
    }

    if (discountCode) {
      const getDiscountType = dummyDiscount.find(
        (x) => x.title === discountCode
      );
      if (getDiscountType) {
        const { type, amount } = getDiscountType;
        if (type === "percentage") {
          appliedDiscount = (calculateTotalPrice() * amount) / 100;
        }
        if (type === "value") {
          appliedDiscount = amount;
        }
      }
      // setOrder({ ...order, discount: appliedDiscount });
      updateOrder.discount = appliedDiscount;
      setDiscountCode("");
      setInputDisc();
    }

    return appliedDiscount;
  }, [
    calculateTotalFixPrice,
    calculateTotalPrice,
    discountCode,
    dummyDiscount,
    order,
  ]);

  const handleApplyDiscount = (event) => {
    event.preventDefault();
    if (!order.discount) {
      setDiscountCode(inputDisc);
    } else {
      const updatedOrder = { ...order };
      delete updatedOrder.discount;
      setOrder(updatedOrder);
    }
  };

  const getData = () => {
    getCollectionFirebase("menus", [
      {
        field: "companyId",
        operator: "==",
        value: globalState.currentCompany,
      },
    ])
      .then((menus) => {
        const filteredMenus = menus?.filter((menu) =>
          menu?.outlets?.includes(globalState.currentOutlet)
        );

        // get all data menu then filter to compare stock and quantitiy
        // when user canceled or refund orders
        let arrOrder = [];
        order?.orders.map((x) => {
          const valueMenus = filteredMenus.filter((datas) => datas.id === x.id);
          return arrOrder.push(...valueMenus);
        });
        setDataOrdered(arrOrder);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePaymentStatus = async (orderId) => {
    setLoading(true)
    const collectionName = "orders";
    const docName = orderId;
    const dataPayload = {
      ...order,
      paymentStatus: order.paymentStatus || "complete",
      paymentMethod: order.paymentMethod,
      updated_bill: new Date(),
      discount: order.discount || 0,
    };

    const fixPrice =
      order.totalPrice -
      (order.discount || 0) +
      ((order.totalPrice - (order.discount || 0)) * order.tax) / 100;

    const validationResult = checkOrderEditValidity(
      order.paymentMethod,
      fixPrice,
      order.paymentStatus,
      "admin"
    );

    if (!validationResult.success) {
      toast(validationResult.error);
      setLoading(false)
      return;
    }

    if (fixPrice > 0) {
      try {
        const res = await createCashPayment(orderId, fixPrice);

        if (res.data.status === "success") {
          console.log("payment success");
          if (res.data.data.status === "PAID") {
            try {
              await updateDocumentFirebase(
                collectionName,
                docName,
                dataPayload
              );
              handleStockUpdate(order);
              console.log("Pesanan berhasil diperbarui");
              onClose();
            } catch (error) {
              console.log("Terjadi kesalahan:", error);
            }
          }
        } else {
          console.log(res.data.data);
          toast({
            title: "Error",
            description: "Terjadi kesalahan",
            position: "top",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.log(error.message, "ini error");
        toast({
          title: "Error",
          description: "Terjadi kesalahan",
          position: "top",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      finally{
        setLoading(false)
      }
    } else {
      try {
        await updateDocumentFirebase(collectionName, docName, dataPayload);
        handleStockUpdate(order);
        toast({
          title: "Success",
          description: "Pesanan berhasil diperbarui",
          position: "top",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      } catch (error) {
        console.log("Terjadi kesalahan:", error);
        toast({
          title: "Error",
          description: "Terjadi kesalahan",
          position: "top",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      finally{
        setLoading(false)
      }
    }
    setLoading(false)
  };

  const paymentRefund = async (orderID) => {
    setLoading(true)
    const collectionName = "orders";
    const docName = orderID;

    const dataPayload = {
      paymentStatus: order.paymentStatus || "complete",
      paymentMethod: order.paymentMethod,
      updated_bill: new Date(),
      discount: order.discount || 0,
    };



    const dataRefund = {
      order_refund: refundItem,
      totalPrice: calculateRefundPrice(),
      table: order.table,
      id: order.id,
      module: order.module,
      orderStatus: order.orderStatus,
      outlet: order.outlet,
      outletId: globalState.currentOutlet,
      projectId: globalState.currentOutlet,
      paymentStatus: order.paymentStatus || "complete",
      companyId: order.companyId,
      name: order.name,
      paymentMethod: order.paymentMethod,
      tax: order.tax
    };


    const validationResult = checkOrderEditValidity(
      order.paymentMethod,
      order?.totalPrice,
      order.paymentStatus,
      "admin"
    );

    if (!validationResult.success) {
      toast(validationResult.error);
      setLoading(false)
      return;
    }


    try {
      await updateDocumentFirebase(collectionName, docName, dataPayload);
      const result = await setDocumentFirebase("refunds", docName, dataRefund);
      if (result) {
        toast({
          title: "Success",
          description: "Pesanan Refund berhasil diperbarui",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Gagal memperbarui pesanan refund, coba lagi",
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    finally{
      setLoading(false)
    }
  };

  const createCashPayment = async (orderId, amount) => {

    if(!globalState.currentXenditId || globalState.currentXenditId === ""){
      return  toast({
        title: "Error",
        description: "Your Company not have xendit ID",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }


    const baseUrl =
      "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/";
    const data = {
      xenditId: globalState.currentXenditId,
      orderId: orderId,
      amount: amount,
      name: order.name,
      companyId: order.companyId,
      outletId: order.outlet,
      notes: order.paymentMethod,
      projectId: globalState.currentProject,
      module: "rms",
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_PAYMENT_KEY,
      },
    };

    return await axios.post(`${baseUrl}/paymentCreateCash`, data, options);
  };

  const handleStockUpdate = (order) => {
    const collectionName = "menus";
    dataOrdered.map((res) => {
      order?.orders.map((val) => {
        const updateData = {
          stock:
            res.stock +
            (order.paymentStatus === "cancel" ? val.quantity : -val.quantity),
        };
        updateDocumentFirebase(collectionName, res.id, updateData);
      });
    });
  };

  // const handleSetItemRefunded = () => {
  //   // //Set data untuk local storage report order outcome
  //   const items = JSON.parse(localStorage.getItem("refund_items"));
  //   if (items) {
  //     localStorage.setItem(
  //       "refund_items",
  //       JSON.stringify([...items, ...refundItem])
  //     );
  //   } else {
  //     localStorage.setItem("refund_items", JSON.stringify(refundItem));
  //   }

  //   if (items) {
  //     console.log([...items, ...refundItem]);
  //   } else {
  //     console.log(refundItem, "yyy");
  //   }
  // };

  const handleIncreaseQuantity = useCallback(
    (id) => {
      const dataOrder = order?.orders?.filter((data) => {
        return data.id === id;
      });

      const dataRefund = refundItem.filter((data) => {
        return data.id === id;
      });

      dataOrder.map((x) => {
        return setOrder({
          ...order,
          orders: [...order?.orders, (x.quantity += 1)],
        });
      });

      dataRefund.map((x) => {
        return setRefundItem((prev) => [...prev, (x.quantity += 1)]);
      });
      setRefundItem(refundItem);

      return setOrder({ ...order });
    },
    [order, refundItem]
  );

  const handleDecreaseQuantity = useCallback(
    (id) => {
      const dataOrder = order?.orders?.filter((data) => {
        return data.id === id;
      });

      const dataRefund = refundItem.filter((data) => {
        return data.id === id;
      });

      dataOrder.map((x) => {
        return setOrder({
          ...order,
          orders: [...order?.orders, x.quantity === 0 ? 0 : (x.quantity -= 1)],
        });
      });

      dataRefund.map((x) => {
        return setRefundItem((prev) => [
          ...prev,
          x.quantity === 0 ? 0 : (x.quantity -= 1),
        ]);
      });
      setRefundItem(refundItem);

      return setOrder({ ...order });
    },
    [order, refundItem]
  );



  useEffect(() => {
    if (isOpen) {

      setOrder(initialOrder);
      getData();
    } else {
      setOrder(null);
      setDataOrdered([]);
      setPaymentMethod("");
      setRefundItem([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.currentOutlet, globalState.currentCompany, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <Text>Invoice Table: {order?.table || 0}</Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          {order && (
            <Stack spacing={2}>

              <HStack alignItems={'flex-end'} justifyContent='flex-end'>
                <Text fontSize={'sm'} color='gray.700'>{moment(order?.createdAt?.seconds * 1000).format('MMMM Do YYYY')}</Text>
                <Text fontSize={'sm'} color='gray.700'>{moment(order?.createdAt?.seconds * 1000).format('h:mm:ss a')}</Text>
              </HStack>

              <HStack justifyContent="space-between">
                <Text color={'gray.600'}>ID:</Text>
                <Spacer />
                <Text fontWeight={500}>{order?.id}</Text>
              </HStack>

              <HStack>
                <Text color='gray.600'>Company :</Text>
                <Spacer />
                <Text textTransform={'capitalize'} fontWeight={500}>{getCompanyName(globalState, order.companyId)}</Text>
              </HStack>

              <HStack justifyContent="space-between">
                <Text color='gray.600'>Store:</Text>
                <Spacer />
                <Text textTransform={'capitalize'} fontWeight={500}>{getStoreName(globalState, order?.outlet)}</Text>
              </HStack>

              <HStack justifyContent="space-between">
                <Text color={'gray.600'}>Updated Date:</Text>
                <Spacer />
                <Text fontWeight={500}>
                  {moment(order?.lastUpdated?.seconds * 1000).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </Text>
              </HStack>

              <HStack justifyContent="space-between">
                <Text color={'gray.600'}>Name:</Text>
                <Spacer />
                <Text fontWeight={500}> {order?.name}</Text>
              </HStack>

              <HStack justifyContent="space-between">
                <Text color={'gray.600'}>Payment Status:</Text>
                <Spacer />
                <Badge colorScheme="green"  p={1} textAlign='center' variant={'solid'} borderRadius='md'>
                  {order?.paymentStatus}
                </Badge>
              </HStack>

              <HStack justifyContent="space-between">
                <Text color={'gray.600'}> Order Status:</Text>
                <Spacer />
                <Badge colorScheme="green"  p={1} textAlign='center' variant={'solid'} borderRadius='md'>
                  {order?.orderStatus}
                </Badge>
              </HStack>

              <HStack justifyContent="space-between">
                <Text color={'gray.600'}> Payment Method:</Text>
                <Spacer />
                <Badge colorScheme="green"  p={1} textAlign='center' variant={'solid'} borderRadius='md'>
                  {order?.paymentMethod}
                </Badge>
              </HStack>

              <hr style={{ marginTop: 10, marginBottom: 10 }} />

              <Text fontWeight={500}>Update Order Status:</Text>
              <Select
                borderRadius="lg"
                placeholder="Order Status"
                onChange={(e) =>
                  setOrder({ ...order, paymentStatus: e.target.value })
                }
                value={order.paymentStatus}
              >
                <option value={"complete"}>
                  <Text textTransform={"capitalize"}>Complete</Text>
                </option>
                <option value={"refund"}>
                  <Text textTransform={"capitalize"}>Refund</Text>
                </option>
                <option value={"compliment"}>
                  <Text textTransform={"capitalize"}>Compliment</Text>
                </option>
                <option value={"cancel"}>
                  <Text textTransform={"capitalize"}>Cancel</Text>
                </option>
              </Select>

              <Text fontWeight={500}>Update Payment Method:</Text>
              <Select
                borderRadius="lg"
                placeholder="Payment method"
                onChange={(e) =>
                  setOrder({ ...order, paymentMethod: e.target.value })
                }
                value={order.paymentMethod}
              >
                <option value={"cash"}>
                  <Text textTransform={"capitalize"}>Cash</Text>
                </option>
                <option value={"transfer_bank"}>
                  <Text textTransform={"capitalize"}>Transfer Bank</Text>
                </option>
                <option value={"edc"}>
                  <Text textTransform={"capitalize"}>Pay with EDC</Text>
                </option>
                <option value={"online"}>
                  <Text textTransform={"capitalize"}>Online</Text>
                </option>
              </Select>

              {order.paymentStatus !== "refund" &&
                order.paymentStatus !== "compliment" &&
                order.paymentStatus !== "cancel" ? (
                <>
                  <Text fontWeight={500}>Kode Diskon</Text>
                  <HStack>
                    {!order.discount && (
                      <Input
                        borderRadius="lg"
                        type="tel"
                        placeholder="Kode Diskon"
                        textTransform="uppercase"
                        onChange={(event) => {
                          setInputDisc(event.target.value.toUpperCase());
                        }}
                      />
                    )}
                    <Button
                      colorScheme="green"
                      p={5}
                      onClick={handleApplyDiscount}
                    >
                      {order.discount ? "Change discount" : "Add discount"}
                    </Button>
                  </HStack>
                </>
              ) : null}

              <Text fontWeight={500} style={{ marginTop: 10 }}>
                Orders:
              </Text>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Title</Th>
                    <Th>Quantity</Th>
                    <Th>Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {order?.orders?.map((orders, index) => {
                    return (
                      <Tr key={index}>
                        <Td>
                          {order.paymentStatus === "refund" && (
                            <Checkbox
                              position="absolute"
                              left={5}
                              isChecked={
                                refundItem.find((e) => orders?.id === e?.id)
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                // eslint-disable-next-line no-unused-expressions
                                refundItem.find((e) => orders?.id === e?.id)
                                  ? (setRefundItem((prev) =>
                                    [
                                      ...prev,
                                      { ...orders, status: "refund" },
                                    ].filter(
                                      (item) => item?.id !== orders?.id
                                    )
                                  ))
                                  : (setRefundItem((prev) => [
                                    ...prev,
                                    { ...orders, status: "refund" },
                                  ]));
                              }}
                            />
                          )}

                          <Text fontSize={"sm"} textTransform="capitalize">
                            {orders?.title}
                          </Text>

                          <Text textTransform="capitalize" fontSize={"sm"}>
                            {orders?.price_type}
                          </Text>

                          <Text
                            textTransform="capitalize"
                            fontWeight={"bold"}
                            fontSize={"sm"}
                          >
                            {orders?.variant}
                          </Text>

                          <Text
                            textTransform="capitalize"
                            fontSize={"sm"}
                            color="red.500"
                          >
                            {orders?.notes}
                          </Text>
                        </Td>
                        <Stack>
                          <HStack>
                            {order.paymentStatus === "refund" &&
                              refundItem.length !== 0 && (
                                <Button
                                  size={"sm"}
                                  onClick={() => {
                                    handleDecreaseQuantity(orders?.id);
                                  }}
                                >
                                  -
                                </Button>
                              )}

                            <Td fontSize={"sm"}>{orders?.quantity}</Td>

                            {order.paymentStatus === "refund" &&
                              refundItem.length !== 0 && (
                                <Button
                                  size={"sm"}
                                  onClick={() => {
                                    handleIncreaseQuantity(orders?.id);
                                  }}
                                >
                                  +
                                </Button>
                              )}
                          </HStack>
                        </Stack>

                        <Td fontWeight={500}>
                          Rp.{formatFrice(parseInt(orders.price))}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>

              <HStack p={2}>
                <Text color={'gray.600'}>Total Order: </Text>
                <Spacer />
                <Text fontWeight={500}>
                  Rp. {formatFrice(calculateTotalPrice())}
                </Text>
              </HStack>


              <HStack p={2}>
                <Text color={'gray.600'}>Discount</Text>
                <Spacer />
                <Text color={"red.300"}>
                  Rp. {formatFrice(getDiscount())}
                </Text>
              </HStack>
              <HStack p={2}>
                <Text color={'gray.600'}>Tax Fee ({order?.tax}%): </Text>
                <Spacer />
                <Text fontWeight={500}>
                  Rp. {formatFrice(calculatedTaxPrice())}
                </Text>
              </HStack>
              <HStack p={2}>
                <Text color={'gray.600'}>Total Price: </Text>
                <Spacer />
                <Heading size="sm">
                  Rp.
                  {formatFrice(calculateTotalFixPrice() - getDiscount())}
                </Heading>
              </HStack>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Close
          </Button>

          <Button
            colorScheme="green"
            mr={3}
            isLoading={loading}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              order.paymentStatus === "refund"
                ? paymentRefund(order?.id)
                : updatePaymentStatus(order?.id, paymentMethod);
            }}
          >
            Update Pay
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrdersDetailModal;
