import {
  Box,
  Button,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { collection, limit, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { WasteTable } from "../../Components/Tables/WasteTable";
import { db } from "../../Config/firebase";
import useUserStore from "../../Hooks/Zustand/Store";
import WasteNewPage from "./WasteNewPage";

function WasteFoodPage() {
  const [showAddWastePage, setShowAddWastePage] = useState(false);

	const globalState = useUserStore();

  const [dataWaste, setDataWaste] = useState([])


  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [inputSearch, setInputSearch] = useState("");
  const [dataSearched, setDataSearched] = useState([]);
  const [openModalPriceType, setOpenModalPriceType] = useState(false);
  const [totalOrder, setTotalOrder] = useState({
    totalPrice: 0,
  });

  const [localData, setLocalData] = useState({});

  const [waste, setWaste] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [selectedPrice, setSelectedPrice] = useState();

  const [wasteData, setWasteData] = useState([]);

  // Mengambil data dari localStorage saat komponen di-mount
  useEffect(() => {
    const storedWasteData = localStorage.getItem("wasteData");
    const storedLocalData = localStorage.getItem("localData");
    const storedWaste = localStorage.getItem("waste");

    if (storedWasteData) {
      setWasteData(JSON.parse(storedWasteData));
    }

    if (storedLocalData) {
      setLocalData(JSON.parse(storedLocalData));
    }

    if (storedWaste) {
      setWaste(JSON.parse(storedWaste));
    }
  }, []);

  // Memperbarui data di localStorage setiap kali ada perubahan di wasteData, localData, atau waste
  useEffect(() => {
    localStorage.setItem("wasteData", JSON.stringify(wasteData));
  }, [wasteData]);

  useEffect(() => {
    localStorage.setItem("localData", JSON.stringify(localData));
  }, [localData]);

  useEffect(() => {
    localStorage.setItem("waste", JSON.stringify(waste));
  }, [waste]);

  const getData = async () => {
    try {
      const q = query(
        collection(db, "wastes"),
        where("module", "==", "rms"),
        where("outlet", "==", globalState.currentOutlet),
        // orderBy("createdAt", "desc"),
        limit(25)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        });
        setDataWaste(data);
      });

      // Unsubscribe from the snapshot listener when the component unmounts
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, 'ini error')
    }
  };


  useEffect(() => {
    getData()

    return () => {
    }
  }, [globalState.currentOutlet])



  return (
    <Stack>
      {!showAddWastePage ? (
        <Stack>
          <Stack p={[1, 1, 5]} align='center' flexDirection={["column", "column", "row"]} >
            <Heading textTransform={"capitalize"} size="lg">
              Waste Food
            </Heading>
            <Spacer />
            <Button
              onClick={() => setShowAddWastePage(true)}
              bgColor={"white"}
              shadow="md"
              variant="outline"
              borderColor="#F05A28"
              color="#F05A28"
            >
              <HStack>
                <FcPlus />
                <Text>Waste Food</Text>
              </HStack>
            </Button>
          </Stack>
          <Box overflowX={'scroll'}>
            {dataWaste ? <WasteTable data={dataWaste} /> : <></>}
          </Box>
        </Stack>
      ) : (
        <WasteNewPage
          setShowAddWastePage={setShowAddWastePage}
          data={data}
          setData={setData}
          categoryData={categoryData}
          setCategoryData={setCategoryData}
          inputSearch={inputSearch}
          setInputSearch={setInputSearch}
          dataSearched={dataSearched}
          setDataSearched={setDataSearched}
          openModalPriceType={openModalPriceType}
          setOpenModalPriceType={setOpenModalPriceType}
          totalOrder={totalOrder}
          setTotalOrder={setTotalOrder}
          localData={localData}
          setLocalData={setLocalData}
          waste={waste}
          setWaste={setWaste}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          selectedPrice={selectedPrice}
          setSelectedPrice={setSelectedPrice}
          wasteData={wasteData}
          setWasteData={setWasteData}
        />
      )}
    </Stack>
  );
}

export default WasteFoodPage;
