import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Spacer,
  useDisclosure,
  Input,
  Stack,
  Divider,
  AbsoluteCenter,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
  getCollectionFirebaseV2,
  updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import BasicCardComponent from "../../Components/Cards/BasicCardComponent";
import { db } from "../../Config/firebase";
import { AddIcon, CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { FcPlus } from "react-icons/fc";
import useUserStore from "../../Hooks/Zustand/Store";
import ModalStock from "../../Components/Modal/ModalStockComponent";
import axiosInstance from "../../Apis/axiosWithNoBarier";

function MenusPage() {
  const globalState = useUserStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [menuTitle, setMenuTitle] = useState();
  const [modalDelete, setModalDelete] = useState(false);
  const [menuID, setMenuID] = useState("");
  const [categoryData, setCategoryData] = useState({}); // State untuk menyimpan data yang telah dikelompokkan berdasarkan kategori
  const toast = useToast();
  const navigate = useNavigate();
  const [history, setHistory] = useState("");
  const [modalStock, setModalStock] = useState(false)
  const [detailStock, setDetailStock] = useState("")
  const [stock, setStock] = useState("")

  const [ren, setRen] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [dataList, setDataList] = useState([])
  const itemsPerPage = 5;

  const [startIndex, setStartIndex] = useState(0);



  const getData = async () => {
    try {
      const q = query(
        collection(db, "menus"),
        where("outlets", "array-contains", globalState.currentOutlet),
        limit(100)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        });
        // Mapping data berdasarkan kategori
        const mappedData = {};
        data.forEach((menu) => {
          const category = menu.category;
          if (!mappedData[category]) {
            mappedData[category] = [];
          }
          mappedData[category].push(menu);
        });

        setCategoryData(mappedData);
      });

      // Unsubscribe from the snapshot listener when the component unmounts
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, "ini error");
    }
  };




  const handleAddMenu = () => {
    addDocumentFirebase(
      "menus",
      { title: menuTitle },
      globalState.currentCompany
    )
      .then((x) => {
        navigate(`/menus/${x}/edit`);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error",
          description: "Masukan Nama Menu",
          position: "top",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleDeleteMenu = (IdMenu) => {
    deleteDocumentFirebase("menus", IdMenu)
      .then((res) => {
        toast({
          title: "Success",
          description: res,
          position: "top",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error",
          description: "Terjadi Kesalahan",
          position: "top",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (globalState.currentCompany) {
      getData();
    }

    return () => {
    };
  }, [globalState.currentOutlet]);

  const openModalStock = (item) => {
    try {
      setModalStock(true);
      setDetailStock(item.id);
      setStock(item);
      getHistory()
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  }

  const closeModalStock = () => {
    setModalStock(false);
    setDetailStock("");
    setHistory("")
    setStock("");
  }

  const getFilterHistory = async () => {
    const collectionName = 'menus';
    const docName = detailStock;

    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      {
        field: 'outletId',
        operator: '==',
        value: globalState.currentOutlet,
      },
      {
        field: 'createdAt',
        operator: '>=',
        value: startDate,
      },
      {
        field: 'createdAt',
        operator: '<=',
        value: endDate,
      },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };

    try {
      const res = await getCollectionFirebase(
        `${collectionName}/${detailStock}/history`,
        conditions,
        sortBy
      );
      setRen(res);
    } catch (error) {
      console.log(error, 'ini error');
    }
  };

  const handleAddHistory = async () => {
    const collectionName = 'menus';
    const docName = detailStock;
    const val = {
      ...history,
      name: globalState.name,
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject,
      outletId: globalState.currentOutlet,
    };

    const number = history.value;
    const amount = stock.stock;

    const sum = {
      stock: history.status === 'inbound'
        ? parseInt(number) + parseInt(amount)
        : parseInt(amount) - parseInt(number),
    };

    try {
      const result = await updateDocumentFirebase(collectionName, docName, sum);
      console.log('Update Stock Result:', result);

      const docID = await addDocumentFirebase(
        `${collectionName}/${docName}/history`,
        val,
        globalState.currentCompany
      );



      if (history.status === "inbound") {

        const dataCalculate = {
          docId: docName,
          amount: history.value || 0,
          action: history.status || '',
          companyId: globalState?.currentCompany,
          projectId: globalState?.currentProject,
          outletId: globalState?.currentOutlet,
          name: globalState?.name || '',
          uid: globalState?.uid || '',
        };

        await axiosInstance.post('/stock_calculate', dataCalculate);

      }


      const data = {
        id: docName,
        name_items: stock.title || '',
        image: stock.image_url || '',
        type: 'menus',
        value: history.value || 0,
        status: history.status || '',
        companyId: globalState?.currentCompany,
        projectId: globalState?.currentProject,
        outletId: globalState?.currentOutlet,
        name: globalState?.name || '',
        uid: globalState?.uid || '',
      };

      await axiosInstance.post('/stock_activity', data);
    } catch (error) {
      console.log('Error:', error);
    } finally {
      getData();
      setHistory('');
      setStock('');
      setModalStock(false);
    }
  };



  const getHistory = async () => {
    if (detailStock) {
      const collectionName = 'menus';
      const docName = detailStock;


      const conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState.currentCompany,
        },
        {
          field: 'projectId',
          operator: '==',
          value: globalState.currentProject,
        },
        {
          field: 'outletId',
          operator: '==',
          value: globalState.currentOutlet,
        },
      ];
      const sortBy = { field: 'createdAt', direction: 'desc' };

      try {
        const res = await getCollectionFirebase(
          `${collectionName}/${docName}/history`,
          conditions,
          sortBy
        );
        setRen(res);
      } catch (error) {
        console.log(error, 'ini error');
      }
    }

  };

  useEffect(() => {
    if (!startDate && !endDate) {
      getHistory();
    } else {
      getFilterHistory();
    }
  }, [startDate, endDate, detailStock]);

  const handleDateRangeChange = (dateRange) => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
  };

  const paginate = (array, page_size, page_number) => {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const page = Math.ceil((ren?.length || 0) / itemsPerPage) * 10;


  return (
    <Stack p={[1, 1, 5]}>
      <Box>
        <Stack flexDirection={["column", "row", "row"]}>
          <Heading>Menus</Heading>
          <Spacer />

          <Button
            bgColor={"white"}
            onClick={onOpen}
            shadow="md"
            variant="outline"
            borderColor="#F05A28"
            color="#F05A28"
          >
            <HStack>
              <FcPlus />
              <Text>Menu</Text>
            </HStack>
          </Button>
        </Stack>

        <Stack>
          {Object.entries(categoryData).map(([category, categoryMenus]) => (
            <Stack spacing={2} key={category} py={2}>
              <Box position="relative" padding="10">
                <Divider />
                <AbsoluteCenter bg="black" borderRadius="md" p="2">
                  <Text fontWeight={500} fontSize={23} color="white">
                    {category?.toUpperCase()}
                  </Text>
                </AbsoluteCenter>
              </Box>

              <SimpleGrid columns={[1, 1, 2]} gap={5}>
                {categoryMenus.map((x, i) => (
                  <BasicCardComponent
                    key={i}
                    data={x}
                    handleDeleteMenu={handleDeleteMenu}
                    setModalDelete={setModalDelete}
                    setMenuID={setMenuID}
                    openModalStock={openModalStock}
                  />
                ))}
              </SimpleGrid>
            </Stack>
          ))}
        </Stack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Nama Menu</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="text"
              placeholder="Menu Name"
              onChange={(e) => setMenuTitle(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Flex gap={5}>
              <Button
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => handleAddMenu()}
              >
                Add Menu
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalDelete}
        onClose={() => {
          setModalDelete(false);
          setMenuID("");
        }}
        isCentered
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Yakin Hapus Menu Ini?</ModalHeader>
          <ModalCloseButton />
          {/* <ModalBody>
            <Input
              type="text"
              placeholder="Menu Name"
              onChange={(e) => setMenuTitle(e.target.value)}
            />
          </ModalBody> */}

          <ModalFooter>
            <Flex gap={5}>
              <Button
                leftIcon={<CheckIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => {
                  handleDeleteMenu(menuID);
                  setModalDelete(false);
                }}
              >
                Yes
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  setModalDelete(false);
                  setMenuID("");
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalStock
        isOpen={modalStock}
        onClose={closeModalStock}
        history={history}
        setHistory={setHistory}
        handleDateRangeChange={handleDateRangeChange}
        paginate={paginate}
        ren={ren}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        page={page}
        setCurrentPage={setCurrentPage}
        handleAddHistory={handleAddHistory}
        closeModal={closeModalStock}
      />

    </Stack>
  );
}

export default MenusPage;
