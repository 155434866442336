import { Badge, Box, Button, Card, CardBody, Flex, Heading, HStack, Image, Spacer, Spinner, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { FcPlus } from 'react-icons/fc';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { formatFrice } from '../../Utils/numberUtil';
import AppImageSlide from '../Carousel/AppImageSlide';


function MenuPanel({
    loading,
    imageCarouselDashboard,
    activeSlide,
    setFilterCategory,
    filterCategory,
    categoryData,
    dataMenu,
    handleAddToCart,
    totalOrder,
    handleTabChange,
    order
}) {

  return (
    <Stack>
               <Box py={2}>
                {loading ? (
                  <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                    <Spinner />
                  </Stack>
                ) : (
                  imageCarouselDashboard && (
                    <div className='banner'>
                      <Stack w={'100%'}>
                        <AppImageSlide images={imageCarouselDashboard && imageCarouselDashboard} />
                      </Stack>
                    </div>
                  )
                )}

                <Heading size="md" my={4}>
                  Category
                </Heading>
                <Swiper slidesPerView={2} spaceBetween={10} initialSlide={activeSlide}>
                  <SwiperSlide key={0}>
                    <Box
                      borderRadius="md"
                      cursor="pointer"
                      shadow="md"
                      onClick={() => setFilterCategory("all")}
                      align="center"
                      p={2}
                      borderBottomWidth={filterCategory === "all" ? 2 : 0}
                      borderColor={filterCategory === "all" ? "#F05A28" : ""}
                    >
                      <Text
                        fontWeight={
                          filterCategory === "all" ? "bold" : "normal"
                        }
                      >
                        All
                      </Text>
                    </Box>
                  </SwiperSlide>
                  {Object.keys(categoryData).map((category, i) => (
                    <SwiperSlide key={i + 1}>
                      <Box
                        borderRadius="md"
                        cursor="pointer"
                        shadow="md"
                        onClick={() => setFilterCategory(category)}
                        align="center"
                        p={2}
                        borderBottomWidth={filterCategory === category ? 2 : 1}
                        borderColor={filterCategory === category ? "#F05A28" : ""}
                      >
                        <Text
                          fontWeight={
                            filterCategory === category ? "bold" : "normal"
                          }
                          textTransform="capitalize"
                        >
                          {category}
                        </Text>
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>

              {filterCategory === "all" && (
                <Box py={2}>
                  <Heading size="md" mb={4}>
                    Recommendation
                  </Heading>
                  <Box zIndex={0} overflow="hidden">
                    <Swiper slidesPerView={2} spaceBetween={10}>
                      {dataMenu.slice(0, 15).map((menu, i) => (
                        <SwiperSlide key={i}>
                          <Stack
                            key={i}
                            p="2"
                            spacing={2}
                            borderRadius="lg"
                            borderWidth={0.5}
                            shadow="lg"
                            // borderColor="blackAlpha.200"
                            borderColor="#gray.300"
                          >
                            <Image
                              src={menu.image_url}
                              alt={menu.title}
                              borderRadius="lg"
                            />

                            <Stack spacing={1}>
                              <Text
                                fontSize="md"
                                textTransform="capitalize"
                                fontWeight={500}
                              >
                                {menu.title}
                              </Text>

                              <Text fontSize="xs" textTransform="capitalize" noOfLines={1}>
                                {menu.description}
                              </Text>

                              {menu.price_type === "multiple" ? (
                                <HStack justifyContent='space-around'>
                                  {menu.prices.map((y, index) => {
                                    const priceTitle =
                                      index === 0 ? "Komplit" : "Ala Carte";
                                    return (
                                      <Stack key={index}>
                                        <Text fontSize={"sm"}>{priceTitle}</Text>
                                        <Text fontSize={"sm"}>
                                          Rp.{formatFrice(parseFloat(y))}
                                        </Text>
                                      </Stack>
                                    );
                                  })}
                                </HStack>
                              ) : (
                                <Text fontSize={"sm"}>
                                  Rp. {formatFrice(parseFloat(menu.price))}
                                </Text>
                              )}
                            </Stack>

                            <Spacer />

                            <Stack>
                              <Button
                                variant='outline' borderColor='#F05A28' color='#F05A28'
                                size="sm"
                                borderRadius="lg"
                                width="full"
                                isDisabled={
                                  menu.stock === 0
                                    ? true
                                    : menu.stock === undefined
                                      ? true
                                      : false
                                }
                                onClick={() => {
                                  handleAddToCart(menu);
                                }}
                              >
                                <HStack spacing={2}>
                                  <Text>
                                    {menu.stock === 0
                                      ? "Habis"
                                      : menu.stock === undefined
                                        ? "Habis"
                                        : "Pesan"}
                                  </Text>
                                  <FcPlus />

                                </HStack>
                              </Button>
                            </Stack>
                          </Stack>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                </Box>
              )}

              {Object.entries(categoryData).map(([category, categoryMenus]) => {
                if (filterCategory !== "all" && category !== filterCategory) {
                  return null;
                }
                return (
                  <Stack spacing={1} key={category} py={2}>
                    <Heading size="md" textTransform="capitalize">
                      {category}
                    </Heading>
                    {categoryMenus.map((x, i) => (
                      <Card direction="row" overflow="hidden" key={i}>
                        <Flex
                          direction="column"
                          w="30%"
                          justify="center"
                          align="center"
                          p={2}
                        >
                          <Image
                            borderRadius={'md'}
                            objectFit="cover"
                            w="100%"
                            src={x.image_url}
                            alt={x.title}
                          />
                        </Flex>
                        <CardBody w="70%">
                          <Stack spacing={2}>
                            <Heading size="sm">{x.title}</Heading>
                            <Text fontSize="xs">{x.description}</Text>
                            {x.price_type === "multiple" ? (
                              <Stack spacing={0}>
                                {x.prices.map((y, index) => {
                                  const priceTitle =
                                    index === 0 ? "Komplit" : "Ala Carte";
                                  return (
                                    <Text fontSize="xs">
                                      {priceTitle} : Rp{" "}
                                      {formatFrice(parseInt(y))}
                                    </Text>
                                  );
                                })}
                              </Stack>
                            ) : (
                              <Text fontSize="sm">
                                Rp {formatFrice(parseInt(x.price))}
                              </Text>
                            )}
                            <Stack alignItems={'flex-end'}>
                              <Box>
                                <Button
                                  variant='outline' borderColor='#F05A28' color='#F05A28'
                                  size="sm"
                                  width="full"
                                  isDisabled={
                                    x.stock === 0
                                      ? true
                                      : x.stock === undefined
                                        ? true
                                        : false
                                  }
                                  onClick={() => {
                                    handleAddToCart(x);
                                  }}
                                >
                                  <HStack spacing={2}>
                                    <Text>
                                      {x.stock === 0
                                        ? "Habis"
                                        : x.stock === undefined
                                          ? "Habis"
                                          : "Pesan"}
                                    </Text>
                                    <FcPlus />

                                  </HStack>
                                </Button>
                              </Box>
                            </Stack>
                          </Stack>
                        </CardBody>
                      </Card>
                    ))}
                  </Stack>
                );
              })}

    </Stack>
  )
}

export default MenuPanel