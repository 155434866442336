import { Badge, HStack, IconButton, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import useUserStore from '../../Hooks/Zustand/Store';
import WasteDetailModal from '../../Pages/Waste/WasteDetailModal';
import { getStoreName } from '../../Utils/nameUtil';
import { formatFrice } from '../../Utils/numberUtil';

export const WasteTable = (props) => {
    const data = props.data;

	const globalState = useUserStore();

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isDetailOpen, setDetailOpen] = useState(false);

    const openDetailModal = (order) => {
        setSelectedOrder(order);
        setDetailOpen(true);
    };

    const closeDetailModal = () => {
        setSelectedOrder(null);
        setDetailOpen(false);
    };


    return (
        <>
            <Table {...props} variant="striped" bgcolor='white' borderRadius={'md'} shadow='md' m={2}>
                <Thead>
                    <Tr>
                        <Th>Created Date</Th>
                        <Th>PIC</Th>
                        <Th>Waste Bill</Th>
                        <Th>Amount</Th>
                        <Th>Status</Th> {/* Added column for Report Status */}
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((x, index) => {
                        const storeName = getStoreName(globalState, x.outlet);

                        return (
                            <Tr key={index}>
                                <Td fontSize="2xs">
                                    <Text fontWeight={'bold'}>{moment(x?.createdAt?.seconds * 1000).format('MMMM Do YYYY')}</Text>
                                    <Text fontWeight={'bold'}>{moment(x?.createdAt?.seconds * 1000).format('h:mm:ss a')}</Text>
                                </Td>
                                <Td>
                                    <Text  fontSize='sm' fontWeight={500}>{x?.on_report_person}</Text>
                                    <Text textTransform={'capitalize'} fontSize="2xs">Outlets: {storeName}</Text>
                                    <Text textTransform={'capitalize'} fontSize="2xs">Notes: {x?.notes}</Text>
                                </Td>
                                <Td>
                                    <Stack>
                                        <Text fontSize='sm' color={'red.500'}>{x.wastes.length} Menu</Text>
                                    </Stack>

                                </Td>
                                <Td>
                                    <Stack>
                                        <Text fontSize={'sm'} color='red.500' fontWeight={500}>Rp.{formatFrice(x.totalPrice)}</Text>
                                    </Stack>
                                </Td>
                                <Td>
                                    <Stack>
                                        {x?.shift && (
                                            <Badge
                                                textAlign={'center'}
                                                variant="solid"
                                                borderRadius="md"
                                                p={1}
                                                colorScheme={
                                                    "green"
                                                }
                                            >
                                                {x?.shift}
                                            </Badge>
                                        )}

                                        <Badge textAlign={'center'}
                                            variant="solid" colorScheme={x.assignment_status === "pending" ? "yellow" : "green"} p={1} borderRadius='md'>{(x.assignment_status)}</Badge>
                                    </Stack>
                                </Td>
                                <Td>

                                    <HStack spacing="1">
                                        <IconButton
                                            icon={<FiEdit2 fontSize="1.25rem" />}
                                            variant="ghost"
                                            aria-label="Edit member"
                                            onClick={() => openDetailModal(x)}
                                        />
                                    </HStack>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>

            <WasteDetailModal
                order={selectedOrder}
                isOpen={isDetailOpen}
                onClose={closeDetailModal}
            />
        </>
    );
};
