import React from "react";
import { Route, Routes } from "react-router-dom";
import OrdersCustomerPage from "../Pages/Orders/OrdersCustomerPage";
import AuthenticationRouter from "./AuthenticationRouter";


function AuthRouter() {
	const authRouter = [...AuthenticationRouter];
	return (
		<Routes>
			<Route path="/orders/:outletId/:table" element={<OrdersCustomerPage />} />

			{authRouter.map((item, index) => {
				return <Route key={index} path={item.path} element={item.element} />;
			})}
			
		</Routes>
	);
}

export default AuthRouter;
