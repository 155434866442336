import React from 'react'
import StockPage from '../Pages/Stock/StockPage'
import StokEditPage from '../Pages/Stock/StockEditPage'

const StockRouter = [
	{
		path: "/stock",
		element: <StockPage />
	},
	{
		path:"/stock/:id/edit",
		element: <StokEditPage />
	}

]

export default StockRouter