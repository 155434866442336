import React from 'react'
import OrdersNewPage from '../Pages/Orders/OrdersNewPage'
import OrdersPage from '../Pages/Orders/OrdersPage'

const OrdersRouter = [
	{
		path: "/orders",
		element: <OrdersPage />
	},
	{
		path: "/orders/new",
		element: <OrdersNewPage />
	},

]

export default OrdersRouter