import { Box, Button, Heading, Input, Select, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { formatFrice } from '../../Utils/numberUtil'

function ReportOrderTable({
    amountNow,
    amountTaxNow,
    amountDiscountNow,
    dataToday,
    valueOrderDone,
    valueOrderOnProcess,
    amountRefundNow,
    setShift,
    dataRefundToday,
    handleReportModal,
    valueOrderRefundDone,
    setView,
    selectedDate,
    setSelectedDate,
    setReportDetailModal
}) {
    return (
        <>
            <Heading size={"md"} mb={5} mt={15}>
                Report Today
            </Heading>

            <Heading size={"md"} mt={5} mb={5} textAlign="center">
                Income
            </Heading>

            <Box overflowX={'auto'}>
                <Table mb={10} variant="striped" bgcolor="white" shadow={"md"}>
                    <Thead>
                        <Tr>
                            <Th>Income</Th>
                            <Th>Income Tax</Th>
                            <Th>Income Discount</Th>
                            <Th>Income Fix</Th>
                            <Th>Total Order Bill</Th>
                            <Th>Total Menu Done</Th>
                            <Th>Total Menu On Process</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        <Td>
                            <Text textTransform="capitalize" fontWeight={500}>
                                Rp,{formatFrice(amountNow)}
                            </Text>
                        </Td>

                        <Td>
                            <Text textTransform="capitalize" fontWeight={500}>
                                Rp,{formatFrice(amountTaxNow)}
                            </Text>
                        </Td>

                        <Td>
                            <Text
                                textTransform="capitalize"
                                fontWeight={500}
                                color="red.300"
                            >
                                Rp,{formatFrice(amountDiscountNow)}
                            </Text>
                        </Td>
                        <Td>
                            <Text textTransform="capitalize" fontWeight={600}>
                                Rp,{formatFrice(amountNow - amountDiscountNow + amountTaxNow)}
                            </Text>
                        </Td>

                        <Td>
                            <Text fontWeight={500}>{dataToday?.length}</Text>
                        </Td>

                        <Td>
                            <Text fontWeight={500}>{valueOrderDone()}</Text>
                        </Td>

                        <Td>
                            <Text fontWeight={500}>{valueOrderOnProcess()}</Text>
                        </Td>

                        <Td>
                            <Button size={'sm'} onClick={() => setReportDetailModal(true)}>Detail</Button>
                        </Td>
                    </Tbody>
                </Table>
            </Box>


            <Heading size={"md"} mt={5} mb={5} textAlign="center">
                Outcome
            </Heading>

            <Box overflowX={'auto'}>

                <Table mb={10} variant="striped" bgcolor="white" shadow={"md"}>
                    <Thead>
                        <Tr>
                            <Th>Outcome Fix</Th>
                            <Th>Total Refund Bill</Th>
                            <Th>Total Menu Refund</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        <Td>
                            <Text textTransform="capitalize" fontWeight={500}>
                                Rp,{formatFrice(amountRefundNow)}
                            </Text>
                        </Td>

                        <Td>
                            <Text fontWeight={500}>{dataRefundToday.length}</Text>
                        </Td>

                        <Td>
                            <Text fontWeight={500}>{valueOrderRefundDone()}</Text>
                        </Td>

                    </Tbody>
                </Table>
            </Box>

            <Stack w={'auto'} ml="auto" mr="auto">
                <Stack>
                    <Text>Select Date:</Text>
                    <Input

                        type="date"
                        value={selectedDate.toISOString().split("T")[0]}
                        bgColor='white'
                        onChange={(e) => setSelectedDate(new Date(e.target.value))}
                    />
                </Stack>
                <Select
                    borderRadius="lg"
                    bgColor='white'
                    placeholder="select shift"
                    onChange={(e) => setShift(e.target.value)}
                >
                    <option value={"shift_1"}>
                        <Text textTransform={"capitalize"}>Shift 1</Text>
                    </option>
                    <option value={"shift_2"}>
                        <Text textTransform={"capitalize"}>Shift 2</Text>
                    </option>
                </Select>
                <Button
                    borderRadius="lg"
                    colorScheme="green"
                    onClick={() => handleReportModal()}
                >
                    Save Report
                </Button>
                <Button borderRadius="lg" onClick={() => setView("TABLE")}>
                    Back to orders
                </Button>
            </Stack>
        </>
    )
}

export default ReportOrderTable