import { Badge, Button, Divider, HStack, IconButton, Stack, Table, Tag, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../Hooks/Zustand/Store';
import { getStoreName } from '../../Utils/nameUtil';
import { formatFrice } from '../../Utils/numberUtil';

export const ReportTable = (props) => {
  const data = props.data;
  const navigate = useNavigate();

  const getAssignmentStatus = (detailOrder) => {
    const allApproved = detailOrder.every((order) => order.assignment_status === 'approved');
    return allApproved ? 'approved' : 'pending';
  };

  const getAssignmentRefundStatus = (detailOrder) => {
    const allApproved = detailOrder.every((order) => order.assignment_status === 'approved');
    return allApproved ? 'approved' : 'pending';
  };


	const globalState = useUserStore();


  return (
    <>
      <Table {...props} variant="striped" bgcolor='white' borderRadius={'md'} shadow='md' m={2}>
        <Thead>
          <Tr>
            <Th>Report Date</Th>
            <Th>Shift</Th>
            <Th>PIC</Th>
            <Th>Sold</Th>
            <Th>Bill</Th>
            <Th>Payment</Th>
            <Th>Amount</Th>
            <Th>Report Status</Th> {/* Added column for Report Status */}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((x, index) => {
            const storeName = getStoreName(globalState, x.outlet);

            return (
              <Tr key={index}>
                <Td fontSize="2xs">
                  <Text fontWeight={'bold'}>{moment(x?.createdAt?.seconds * 1000).format('MMMM Do YYYY')}</Text>
                  <Text fontWeight={'bold'}>{moment(x?.createdAt?.seconds * 1000).format('h:mm:ss a')}</Text>
                </Td>
                <Td>
                  <Text textTransform={'uppercase'} fontSize='sm' fontWeight={500}>{x.shift}</Text>
                  <Text textTransform={'capitalize'} fontSize="2xs">outlets: {storeName}</Text>
                </Td>
                <Td>
                  <Stack>
                    <Stack>
                      <Text fontSize='sm'>{x.person_in_charge}</Text>
                    </Stack>
                    <Divider />
                    <Stack>
                      <Text fontSize='sm' color='gray.600'>Type Report:</Text>
                      <Text fontSize='sm' textTransform={'capitalize'}>{x.type_reports}</Text>
                    </Stack>
                  </Stack>
                </Td>
                <Td>
                  <Stack>
                    <Stack>
                      <Text fontSize='sm' color='gray.600'>Order:</Text>
                      <Text fontSize='sm' color={'green.500'}>{x.product_stock}</Text>
                    </Stack>

                    <Divider />
                    {x?.product_refund && (
                      <Stack>
                        <Text fontSize='sm' color='gray.600'>Refund:</Text>
                        <Text fontSize='sm' color={'red.500'}>{x.product_refund}</Text>
                      </Stack>

                    )}

                  </Stack>
                </Td>
                <Td>
                  <Stack>
                    <Stack>
                      <Text fontSize='sm' color='gray.600'>Order:</Text>
                      <Text fontSize='sm' color={'green.500'}>{x.detail_order.length}</Text>
                    </Stack>
                    <Divider />
                    {x?.detail_order_refund && (
                      <Stack>
                        <Text fontSize='sm' color='gray.600'>Refund:</Text>
                        <Text fontSize='sm' color={'red.500'}>{x.detail_order_refund.length}</Text>
                      </Stack>

                    )}
                  </Stack>
                </Td>
                <Td>
                  <Stack>
                    <Stack>
                      {Object.entries(x.payment_total_methods).map(([method, amount]) => (
                        <Stack key={'method'} spacing={0}>
                          <Text fontSize={'sm'} textTransform={'capitalize'} color='gray.600'>{method}:</Text>
                          <Text fontSize={'sm'} color='green.500' fontWeight={500}>
                            {`Rp.${formatFrice(amount)}`}
                          </Text>
                        </Stack>
                      ))}
                    </Stack>
                    <Divider />
                    <Stack>
                      {Object.entries(x.payment_total_refund_methods).map(([method, amount]) => (
                        <Stack key={'method'} spacing={0}>
                          <Text fontSize={'sm'} textTransform={'capitalize'} color='gray.600' >{method}:</Text>
                          <Text fontSize={'sm'} color='red.500' fontWeight={500}>
                            {`Rp.${formatFrice(amount)}`}
                          </Text>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </Td>
                <Td>
                  <Stack>
                    <Stack>
                      <Text fontSize='sm' color='gray.600'>Income:</Text>
                      <Text color={'green.500'} fontSize={'sm'} fontWeight={500}>Rp.{formatFrice(x.payment_total - x.payment_total_discount + x.payment_total_tax)}</Text>
                    </Stack>

                    <Divider />

                    <Stack>
                      <Text fontSize='sm' color='gray.600'>Outcome:</Text>
                      <Text color={'red.500'} fontSize={'sm'} fontWeight={500}>Rp.{formatFrice(x.payment_total_refund)}</Text>
                    </Stack>
                  </Stack>
                </Td>
                <Td>
                  <Stack>
                    <Stack>
                      <Text fontSize='sm' color='gray.600'>Order:</Text>
                      <Badge variant="solid" colorScheme={getAssignmentStatus(x.detail_order) === "pending" ? "yellow" : "green"} p={1}  textAlign={'center'} borderRadius="md">{getAssignmentStatus(x.detail_order)}</Badge>
                    </Stack>
                    <Divider />
                    <Stack>
                      <Text fontSize='sm' color='gray.600'>Refund:</Text>
                      <Badge  textAlign={'center'}
                                                variant="solid"
                                                borderRadius="md" colorScheme={getAssignmentRefundStatus(x.detail_order_refund) === "pending" ? "yellow" : "green"} p={1} >{getAssignmentStatus(x.detail_order_refund)}</Badge>
                    </Stack>
                  </Stack>
                </Td>
                <Td>
                  <HStack spacing="1">
                    {/* <IconButton icon={<FiTrash2 fontSize="1.25rem" />} variant="ghost" aria-label="Delete member" /> */}
                    <IconButton
                      icon={<FiEdit2 fontSize="1.25rem" />}
                      variant="ghost"
                      aria-label="Edit member"
                      onClick={() => navigate(`/reports/${x.id}`, { state: x })}
                    />
                  </HStack>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </>
  );
};
