import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Container,
  Flex,
  Image,
  Stack,
  Heading,
  Text,
  HStack,
  Button,
  Box,
  Textarea,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import { formatFrice } from "../../Utils/numberUtil";
import { FcPlus } from "react-icons/fc";
import { IoMdArrowBack } from 'react-icons/io';

const OrderCustomerCartDrawer = ({ store, isOpen, onClose, menu, handleAddOrder }) => {
  const [menuSelected, setMenuSelected] = useState({});
  const [priceIndex, setPriceIndex] = useState(0);
  

  useEffect(() => {
    if (isOpen) {
      setMenuSelected(menu);
    } else {
      setMenuSelected(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleQuantityOnAdd = (type) => {
    if (type === "+")
      setMenuSelected({
        ...menuSelected,
        quantity: parseInt(menuSelected?.quantity) + 1,
      });
    if (
      type === "-" &&
      menuSelected?.quantity !== 0 &&
      menuSelected?.quantity !== "0" &&
      menuSelected?.quantity !== 1 &&
      menuSelected?.quantity !== "1"
    )
      setMenuSelected({
        ...menuSelected,
        quantity: parseInt(menuSelected?.quantity) - 1,
      });
  };

  return (
    <Drawer maxW="xl" placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton onClick={() => onClose()} />

        <DrawerHeader borderBottomWidth="1px" textAlign="center">
          <Text
            onClick={() => handleAddOrder(menuSelected)}
            textAlign="center"
            fontSize="xl"
            fontWeight="bold"
            mb="4"
          >
            Tambahkan pesanan :
          </Text>
        </DrawerHeader>
        <Flex w='full'>
          <Button w='full' borderTopRadius='0' borderBottomRadius='lg' variant='outline' color='#F05A28' shadow='lg' borderColor="#F05A28" onClick={() => onClose()}>
            <Flex flexDir='row' justifyContent='space-bewtween' alignItems='center'>
              <IoMdArrowBack />
              <Text>Kembali</Text>
            </Flex>
          </Button>
        </Flex>
        <DrawerBody>
          <Container p="2">
            <Flex gap="2" flexDir={{ base: "column", md: "row" }}>
              <Image src={menuSelected?.image_url} borderRadius='lg' />
            
            </Flex>
            {menuSelected?.price_type === "multiple" && (
              <Stack my={5} spacing={5}>
                  <Stack mx={2} grow={1}>
                <Heading size="lg">{menuSelected?.title}</Heading>
                <Text>{menuSelected?.description}</Text>
                <HStack alignItems="stretch" h="100%">
                  <Button
                    size="md"
                    variant="outline"
                    onClick={() => handleQuantityOnAdd("-")}
                  >
                    <Icon
                      as={BiMinus}
                      name="remove-outline"
                      size="md"
                      color="#000"
                    />
                  </Button>
                  <Text fontWeight="bold" w="full" textAlign="center">
                    {menuSelected?.quantity || 0}
                  </Text>
                  <Button
                    size="md"
                    variant="outline"
                    onClick={() => handleQuantityOnAdd("+")}
                  >
                    <Icon
                      as={BsPlus}
                      name="add-outline"
                      size="xs"
                      color="#000"
                    />
                  </Button>
                </HStack>
                <Text fontSize={'lg'} fontWeight={500}>
                  Harga : Rp.
                  {formatFrice(
                    menuSelected?.price_type === "multiple"
                      ? (parseInt(menuSelected?.prices[priceIndex]) +
                        (menuSelected?.variant === "barra" ? 1000 : 0)) *
                      menuSelected?.quantity
                      : (parseInt(menuSelected?.price) +
                        (menuSelected?.variant === "barra" ? 1000 : 0)) *
                      menuSelected?.quantity
                  )}
                </Text>
              </Stack>
                <Heading size="md">Pilih Tipe Harga</Heading>
                <Flex
                  gap="2"
                  justify="center"
                  flexDir={"row"}
                >
                  <Box
                    cursor="pointer"
                    shadow="md"
                    onClick={() => setPriceIndex(0)}
                    borderRadius="xl"
                    p={2}
                    borderWidth={priceIndex === 0 ? 2 : 0}
                    borderColor={priceIndex === 0 ? "#F05A28" : ""}
                  >
                    <Text fontSize={22} fontWeight={600}>
                      Komplit{" "}
                      {`( Rp. ${formatFrice(
                        parseInt(menuSelected.prices[0])
                      )} )`}
                    </Text>
                    <Text>
                       Size <strong>Large</strong>
                    </Text>
                  </Box>
                  <Box
                    cursor="pointer"
                    shadow="md"
                    onClick={() => setPriceIndex(1)}
                    borderRadius="xl"
                    p={2}
                    borderWidth={priceIndex === 1 ? 2 : 0}
                    borderColor={priceIndex === 1 ? "#F05A28" : ""}
                  >
                    <Text fontSize={22} fontWeight={600}>
                      Ala Carte
                      {`( Rp. ${formatFrice(
                        parseInt(menuSelected.prices[1])
                      )} )`}
                    </Text>
                    <Text>
                      Size <strong>Medium</strong>
                    </Text>
                  </Box>
                </Flex>
              </Stack>
            )}


            {menuSelected?.variant_type === "multiple" && priceIndex === 0 && (
              <Stack my={5} spacing={5}>
                <Heading size="md">Pilih Tipe Variant</Heading>
                <Flex
                  my={5}
                  gap="2"
                  justify="center"
                  flexDir={"row"}
                >
                  <Box
                    cursor="pointer"
                    shadow="md"
                    onClick={() =>
                      setMenuSelected({ ...menuSelected, variant: "bakka" })
                    }
                    borderRadius="xl"
                    p={2}
                    borderWidth={menuSelected?.variant === "bakka" ? 2 : 0}
                    borderColor={
                      menuSelected?.variant === "bakka" ? "#F05A28" : ""
                    }
                  >
                    <Text fontSize={22} fontWeight={600}>
                      Bakka
                    </Text>
                    <Text>Sambal Tomat (pedas manja)</Text>
                  </Box>
                  <Box
                    cursor="pointer"
                    shadow="md"
                    onClick={() =>
                      setMenuSelected({ ...menuSelected, variant: "barra" })
                    }
                    borderRadius="xl"
                    p={2}
                    borderWidth={menuSelected?.variant === "barra" ? 2 : 0}
                    borderColor={
                      menuSelected?.variant === "barra" ? "#F05A28" : ""
                    }
                  >
                    <Text fontSize={22} fontWeight={600}>
                      Barra {`(+ Rp. ${formatFrice(1000)} )`}
                    </Text>
                    <Text>Sambal Bawang (pedas sopan)</Text>
                  </Box>
                  <Box
                    cursor="pointer"
                    shadow="md"
                    onClick={() =>
                      setMenuSelected({ ...menuSelected, variant: "Gajja" })
                    }
                    borderRadius="xl"
                    p={2}
                    borderWidth={menuSelected?.variant === "Gajja" ? 2 : 0}
                    borderColor={
                      menuSelected?.variant === "Gajja" ? "#F05A28" : ""
                    }
                  >
                    <Text fontSize={22} fontWeight={600}>
                      Gajja
                    </Text>
                    <Text>Sambal Ijo (pedas abiss)</Text>
                  </Box>
                </Flex>
              </Stack>
            )}
            <Flex my={5}>
              <Stack w="full">
                <Heading size="md">Notes: </Heading>
                <Textarea
                  onChange={(e) =>
                    setMenuSelected({
                      ...menuSelected,
                      notes: e.target.value,
                    })
                  }
                  placeholder={`Masukkan notes untuk ${menuSelected?.title}...`}
                />
              </Stack>
            </Flex>
            <Button
              w="full"
              variant='outline' borderColor='#F05A28' color='#F05A28'
              mt="4"
              onClick={() => {
                handleAddOrder({
                  ...menuSelected,
                  notes: menuSelected.notes ? menuSelected.notes : "-",
                  priceIndex
                }, onClose);
              }}
            >
              <HStack spacing={2}>
                <Text>
                  Tambahkan Pesanan
                </Text>
                <FcPlus />

              </HStack>
            </Button>
          </Container>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default OrderCustomerCartDrawer;
