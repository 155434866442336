import { Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'

function HelpPanel() {
  return (
    <Flex direction="column" justify="center" align="center">
    <Image
      src="https://img.freepik.com/free-vector/customer-support-flat-design-illustration_23-2148889374.jpg?w=740&t=st=1687429018~exp=1687429618~hmac=fd5c68583d5f7ab98d3502f4345519c4c0e4820df66ed23c9ad74fc2b6dce07e"
      alt="Help Illustration"
      mb={4}
      width={"40%"}
    />
    <Heading as="h1" mb={4}>
      Pusat Bantuan
    </Heading>
    <VStack spacing={4} align="start">
      <Text>Bagaimana kami dapat membantu Anda hari ini?</Text>
      <Text>
        Hubungi tim dukungan kami untuk bantuan lebih lanjut.
      </Text>
    </VStack>
  </Flex>
  )
}

export default HelpPanel