import { useState } from 'react';
import {
	Badge,
	Button,
	Checkbox,
	HStack,
	IconButton,
	Stack,
	Table,
	Tag,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { formatFrice } from '../../Utils/numberUtil';
import { updateDocumentFirebase } from '../../Apis/firebaseApi';
import { auth } from '../../Config/firebase';

export const ReportDetailTable = ({ data, id }) => {
	const [allChecked, setAllChecked] = useState(false);
	const [checkedItems, setCheckedItems] = useState([]);

	const handleCheckboxChange = (event, id) => {
		if (event.target.checked) {
			setCheckedItems([...checkedItems, id]);
		} else {
			setCheckedItems(checkedItems.filter((item) => item !== id));
		}
	};

	const handleAllCheckboxChange = (event) => {
		const checked = event.target.checked;
		setAllChecked(checked);
		if (checked) {
			const allIds = data?.detail_order?.map((item) => item.id);
			setCheckedItems(allIds);
		} else {
			setCheckedItems([]);
		}
	};

	const handleUpdateStatus = async () => {
		// Update the assignment status of checked items to "approved" using the updateDocumentFirebase function
		let updated = data
		const updatedData = data?.detail_order?.map((item) => {
			if (checkedItems.includes(item.id)) {
				return {
					...item,
					assignment_status: 'approved',
				};
			}
			return item;
		});
		// Call the updateDocumentFirebase function with the updated data
		updated.detail_order = updatedData
		updated.approved_by = auth.currentUser.email
		updated.approved_at = new Date()


		try {
			const result = await updateDocumentFirebase('reports', data.id, updated);
			console.log(result); // Pesan toast yang berhasil
		} catch (error) {
			console.log('Terjadi kesalahan:', error);
		}
		// Clear the checked items
		setCheckedItems([]);
		setAllChecked(false);
	};

	const updatedData = data?.detail_order?.map((x) => {
		const servingOrders = x.orders.filter((order) => order.status === 'serving');
		const allServing = servingOrders.length === x.orders.length;
		const allFinished = x.orders.every((order) => order.status === 'finished');
		let updatedOrderStatus = x.orderStatus;

		if (allFinished) {
			updatedOrderStatus = 'Done';
		} else if (allServing) {
			updatedOrderStatus = 'Served';
		}

		return {
			...x,
			orderStatus: updatedOrderStatus,
		};
	});

	return (
		<Stack key={id} py={5} spacing={5}>
			<Table {...data} variant='striped' bgcolor='white' borderRadius={'md'} shadow='md'>
				<Thead>
					<Tr>
						<Th>
							<HStack>
								<Checkbox isChecked={allChecked} onChange={handleAllCheckboxChange} />
								<Text >All</Text>
							</HStack>
						</Th>
						<Th>Updated Date</Th>
						<Th>Order</Th>
						<Th>Payment Status</Th>
						<Th>Order Status</Th>
						<Th>Order Value</Th>
						<Th>Assignment Status</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{updatedData.map((x) => (
						<Tr key={x.id}>
							<Td>
								<Checkbox isChecked={checkedItems.includes(x.id)} onChange={(e) => handleCheckboxChange(e, x.id)} />
							</Td>
							<Td fontSize="2xs">
								<Text fontWeight={500}>{moment(x?.lastUpdated?.seconds * 1000).format('MMMM Do YYYY')}</Text>
								<Text fontWeight={500}>{moment(x?.lastUpdated?.seconds * 1000).format('h:mm:ss a')}</Text>
							</Td>
							<Td>
								<Text textTransform={'capitalize'} fontSize='sm'>{x.name}</Text>
								<Text fontSize="2xs">Order ID: {x.id}</Text>
							</Td>
							<Td>
								<Badge
									p={1} textAlign='center' variant={'solid'} borderRadius='md'
									colorScheme={x.paymentStatus === 'complete' || x.paymentStatus === 'compliment' ? 'green' : 'red'}
								>
									{x.paymentStatus}
								</Badge>
							</Td>
							<Td>
								<Badge p={1} textAlign='center' variant={'solid'} borderRadius='md' colorScheme={x.orderStatus === 'Done' ? 'green' : 'yellow'}>
									{x.orderStatus}
								</Badge>
							</Td>
							<Td>
								<Text>Rp.{formatFrice((x.totalPrice - x.discount || 0) + ((x.totalPrice - x.discount || 0) * x.tax / 100))}</Text>
							</Td>
							<Td>
								<Badge p={1} textAlign='center' variant={'solid'} borderRadius='md' colorScheme={x.assignment_status === 'approved' ? 'green' : x.assignment_status === "rejected" ? "red" : "yellow"}>
									{x.assignment_status}
								</Badge>
							</Td>
							<Td>
								<HStack>
									<IconButton
										icon={<FiEdit2 />}
										aria-label="Edit"
										variant="ghost"
										size="sm"
										onClick={() => console.log(x.id)}
									/>
									<IconButton
										icon={<FiTrash2 />}
										aria-label="Delete"
										variant="ghost"
										size="sm"
										onClick={() => console.log(x.id)}
									/>
								</HStack>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
			<Stack direction="row">
				<Button
					colorScheme="green"
					size="sm"
					onClick={handleUpdateStatus}
					isDisabled={checkedItems.length === 0}
				>
					Approved
				</Button>
			</Stack>
		</Stack>
	);
};
