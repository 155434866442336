import {
    Badge,
    Button,
    Heading,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import {
    updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import { formatFrice } from "../../Utils/numberUtil";
import axios from "axios";
import { checkRefundValidity } from "../../Hooks/Middleware/UserMiddleWare";
import useUserStore from "../../Hooks/Zustand/Store";

const OrderRefundDetailPaymentModal = ({ order: initialOrder, isOpen, onClose }) => {
    const globalState = useUserStore();

    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false)

    const [dataOrdered, setDataOrdered] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState("");

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const calculateTotalPrice = useCallback(() => {
        let total = 0;
        if (order && order.order_refund) {
            order.order_refund.forEach((order) => {
                total += parseInt(Number(order.base_price) * Number(order.quantity));
            });
        }
        return total;
    }, [order]);




    const updatePaymentStatus = async (orderId) => {
        setLoading(true)
        const collectionName = "refunds";
        const docName = orderId;
        const dataPayload = {
            ...order,
            paymentRefundMethod: order.paymentMethod,
            updated_bill: new Date(),
            paymentRefundStatus: "complete"
        };



        const fixPrice = (0 - order.totalPrice)

        const validationResult = checkRefundValidity(order.paymentMethod, fixPrice, 'owner');

        if (!validationResult.success) {
            toast(validationResult.error);
            setLoading(false)
            return;
        }

        try {
            const res = await createCashPayment(orderId, fixPrice);

            if (res.data.status === "success") {
                console.log("payment success");
                if (res.data.data.status === "PAID") {
                    try {
                        await updateDocumentFirebase(
                            collectionName,
                            docName,
                            dataPayload
                        );
                        onClose();
                    } catch (error) {
                        console.log("Terjadi kesalahan:", error);
                    }
                    finally {
                        setLoading(false)
                    }
                }
            } else {
                toast({
                    title: "Error",
                    description: "Terjadi kesalahan",
                    position: "top",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                setLoading(false)
            }
        } catch (error) {
            console.log(error.message, "ini error");
            toast({
                title: "Error",
                description: "Terjadi kesalahan",
                position: "top",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
        finally {
            setLoading(false)
        }
        setLoading(false)
    };

    const createCashPayment = async (orderId, amount) => {

        if (!globalState.currentXenditId || globalState.currentXenditId === "") {
            return toast({
                title: "Error",
                description: "Your Company not have xendit ID",
                position: "top",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

        const baseUrl =
            "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/";
        const data = {
            xenditId: globalState.currentXenditId,
            orderId: orderId,
            amount: amount,
            name: order.name,
            companyId: order.companyId,
            outletId: order.outlet,
            notes: order.paymentMethod,
            projectId: globalState?.currentProject,
            module: "rms"
        };

        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_PAYMENT_KEY,
            },
        };

        return await axios.post(`${baseUrl}/paymentCreateCash`, data, options);
    };


    useEffect(() => {
        if (isOpen) {
            setOrder(initialOrder);
        } else {
            setOrder(null);
            setDataOrdered([]);
            setPaymentMethod("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState.currentOutlet, globalState.currentCompany, isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    <Text>Invoice Table: {order?.table || 0}</Text>
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody>
                    {order && (
                        <Stack spacing={2}>
                            <HStack justifyContent="space-between">
                                <Text>ID:</Text>
                                <Text fontWeight={500}>{order?.id}</Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text>Created Date:</Text>
                                <Text fontWeight={500}>
                                    {moment(order?.createdAt?.seconds * 1000).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                </Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text>Updated Date:</Text>
                                <Text fontWeight={500}>
                                    {moment(order?.lastUpdated?.seconds * 1000).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                </Text>
                            </HStack>


                            <HStack justifyContent="space-between">
                                <Text>Name:</Text>
                                <Text fontWeight={500}> {order?.name}</Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text>Payment Status:</Text>
                                <Badge colorScheme="green" fontSize="sm" borderRadius="lg">
                                    {order?.paymentStatus}
                                </Badge>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text>Payment Refund Status:</Text>
                                <Badge
                                    borderRadius="lg"
                                    fontSize="sm"
                                    colorScheme={
                                        order.paymentRefundStatus === "complete" ? "green"
                                            : "red"
                                    }
                                >
                                    {order?.paymentRefundStatus ? order?.paymentRefundStatus : "open"}
                                </Badge>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text> Order Status:</Text>
                                <Badge colorScheme="green" fontSize="sm" borderRadius="lg">
                                    {order?.orderStatus}
                                </Badge>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text> Payment Method:</Text>
                                <Badge colorScheme="green" fontSize="sm" borderRadius="lg">
                                    {order?.paymentMethod}
                                </Badge>
                            </HStack>

                            <hr style={{ marginTop: 10, marginBottom: 10 }} />


                            <Text fontWeight={500}>Update Payment Method:</Text>
                            <Select
                                borderRadius="lg"
                                placeholder="Payment method"
                                onChange={(e) =>
                                    setOrder({ ...order, paymentMethod: e.target.value })
                                }
                                value={order.paymentMethod}
                            >
                                <option value={"cash"}>
                                    <Text textTransform={"capitalize"}>Cash</Text>
                                </option>
                                <option value={"transfer_bank"}>
                                    <Text textTransform={"capitalize"}>Transfer Bank</Text>
                                </option>
                                <option value={"edc"}>
                                    <Text textTransform={"capitalize"}>Pay with EDC</Text>
                                </option>
                                <option value={"online"}>
                                    <Text textTransform={"capitalize"}>Online</Text>
                                </option>
                            </Select>


                            <Text fontWeight={500} style={{ marginTop: 10 }}>
                                Orders:
                            </Text>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>Title</Th>
                                        <Th>Quantity</Th>
                                        <Th>Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {order?.order_refund?.map((order, index) => {
                                        return (
                                            <Tr key={index}>
                                                <Td>
                                                    <Text textTransform="capitalize" fontSize={'sm'}>{order?.title}</Text>
                                                    <Text textTransform="capitalize" fontSize={'sm'}>{order?.price_type}</Text>
                                                    <Text textTransform="capitalize" fontSize={'sm'} fontWeight={'bold'} >
                                                        {order?.variant}
                                                    </Text>
                                                    <Text textTransform="capitalize" fontSize={'sm'} color="red.300">
                                                        {order?.notes}
                                                    </Text>
                                                </Td>
                                                <Td fontSize={'sm'}>
                                                    <HStack>
                                                        <Text>{order?.quantity}</Text>
                                                        <Badge>{order?.status}</Badge>
                                                    </HStack>
                                                </Td>
                                                <Td fontWeight={500}>
                                                    Rp.{formatFrice(parseInt(order.base_price))}
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>



                            <HStack p={2}>
                                <Text>Total Price: </Text>
                                <Spacer />
                                <Text fontWeight={500}>
                                    Rp. {formatFrice(calculateTotalPrice())}
                                </Text>
                            </HStack>


                        </Stack>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" mr={3} onClick={onClose}>
                        Close
                    </Button>

                    <Button
                        colorScheme="green"
                        mr={3}
                        isLoading={loading}
                        onClick={() => updatePaymentStatus(order?.id, paymentMethod)}
                    >
                        Update Pay
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default OrderRefundDetailPaymentModal;
