import React from 'react'
import OutletsDetailPage from '../Pages/Outlets/OutletsDetailPage'
import OutletsPage from '../Pages/Outlets/OutletsPage'
import TablesPage from '../Pages/Tables/TablesPage'

const OutletsRouter = [
	{
		path: "/outlets",
		element: <OutletsPage />
	},

	{
		path: "/outlets/:id",
		element: <OutletsDetailPage />
	},

]

export default OutletsRouter