import {
    Badge,
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useState, useEffect } from "react";
import {
    updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import { formatFrice } from "../../Utils/numberUtil";
import { checkWasteValidity } from "../../Hooks/Middleware/UserMiddleWare";
import { auth } from "../../Config/firebase";
import { getCompanyName, getStoreName } from "../../Utils/nameUtil";
import useUserStore from "../../Hooks/Zustand/Store";

const WasteDetailModal = ({ order: initialOrder, isOpen, onClose }) => {

    const globalState = useUserStore();

    const [order, setOrder] = useState();
    const [shift, setShift] = useState("")
    const [loading, setLoading] = useState(false)

    const toast = useToast({
        position: "top",
        align: "center",
    });





    const updatePaymentStatus = async (wasteId) => {
        setLoading(true)
        const collectionName = "wastes";
        const docName = wasteId;
        const dataPayload = {
            ...order,
            on_report: true,
            on_report_at: new Date(),
            on_report_person: auth.currentUser.email,
            assignment_status: "approved",
            shift: shift
        };

        const fixPrice = order.totalPrice

        const validationResult = checkWasteValidity(shift, fixPrice, 'owner');

        if (!validationResult.success) {
            toast(validationResult.error);
            setLoading(false)
            return;
        }


        try {
            await updateDocumentFirebase(
                collectionName,
                docName,
                dataPayload
            );
            onClose()
        } catch (error) {
            toast({
                title: "Error",
                description: "Terjadi kesalahan:", error,
                position: "top",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
        finally{
            setLoading(false)
        }

    };





    useEffect(() => {
        if (isOpen) {
            setOrder(initialOrder);
        } else {
            setOrder(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState.currentOutlet, globalState.currentCompany, isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    <Text>Waste Detail</Text>
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody>
                    {order && (
                        <Stack spacing={2}>

                            <HStack alignItems={'flex-end'} justifyContent='flex-end'>
                                <Text fontSize={'sm'} color='gray.700'>{moment(order?.createdAt?.seconds * 1000).format('MMMM Do YYYY')}</Text>
                                <Text fontSize={'sm'} color='gray.700'>{moment(order?.createdAt?.seconds * 1000).format('h:mm:ss a')}</Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>ID:</Text>
                                <Spacer />
                                <Text fontWeight={500}>{order?.id}</Text>
                            </HStack>

                            <HStack>
                                <Text color='gray.600'>Company :</Text>
                                <Spacer />
                                <Text textTransform={'capitalize'} fontWeight={500}>{getCompanyName(globalState, order.companyId)}</Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>Store:</Text>
                                <Spacer />
                                <Text  textTransform={'capitalize'}  fontWeight={500}>{getStoreName(globalState, order?.outlet)}</Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>CreatedBy:</Text>
                                <Text fontWeight={500}> {order?.createdBy}</Text>
                            </HStack>


                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>Assignment Status:</Text>
                                <Spacer />
                                <HStack>
                                    <Badge
                                        p={1} textAlign='center' variant={'solid'} borderRadius='md'
                                        colorScheme={
                                            order?.assignment_status === "pending" ? "yellow"
                                                : "green"
                                        }
                                    >
                                        {order?.assignment_status}
                                    </Badge>

                                    <Badge
                                       p={1} textAlign='center' variant={'solid'} borderRadius='md'
                                        colorScheme={
                                            "green"
                                        }
                                    >
                                        {order?.shift}
                                    </Badge>
                                </HStack>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>On Report At:</Text>
                                <Spacer />
                                <Text fontWeight={500}>
                                    {moment(order?.on_report_at?.seconds * 1000).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                </Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>PIC :</Text>
                                <Spacer />
                                <Text fontWeight={500}>
                                    {order?.on_report_person}
                                </Text>
                            </HStack>

                            <hr style={{ marginTop: 10, marginBottom: 10 }} />

                            <Select
                                borderRadius="lg"
                                placeholder="select shift"
                                onChange={(e) => setShift(e.target.value)}
                            >
                                <option value={"shift_1"}>
                                    <Text textTransform={"capitalize"}>Shift 1</Text>
                                </option>
                                <option value={"shift_2"}>
                                    <Text textTransform={"capitalize"}>Shift 2</Text>
                                </option>
                            </Select>

                            <Text fontWeight={500} style={{ marginTop: 10 }}>
                                Wastes:
                            </Text>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>Title</Th>
                                        <Th>Quantity</Th>
                                        <Th>Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {order?.wastes?.map((order, index) => {
                                        return (
                                            <Tr key={index}>
                                                <Td>
                                                    <Text textTransform="capitalize" fontSize={'sm'}>{order?.title}</Text>
                                                    <Text textTransform="capitalize" fontSize={'sm'}>{order?.price_type}</Text>
                                                    <Text textTransform="capitalize" fontSize={'sm'} fontWeight={'bold'} >
                                                        {order?.variant}
                                                    </Text>
                                                    <Text textTransform="capitalize" fontSize={'sm'} color="red.300">
                                                        {order?.notes}
                                                    </Text>
                                                </Td>
                                                <Td fontSize={'sm'}>
                                                    <HStack>
                                                        <Text>{order?.quantity}</Text>
                                                    </HStack>
                                                </Td>
                                                <Td fontWeight={500}>
                                                    Rp.{formatFrice(parseInt(order.price))}
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>



                            <HStack p={2}>
                                <Text color={'gray.600'}>Total Price: </Text>
                                <Spacer />
                                <Text fontWeight={500} color='red.500'>
                                    Rp. {formatFrice(order?.totalPrice)}
                                </Text>
                            </HStack>


                        </Stack>
                    )}
                </ModalBody>
                <ModalFooter>
                    {order?.assignment_status === "pending" && (
                        <>
                            <Button colorScheme="red" mr={3} onClick={onClose}>
                                Close
                            </Button>

                            <Button
                                colorScheme="green"
                                mr={3}
                                isLoading={loading}
                                onClick={() => updatePaymentStatus(order?.id)}
                            >
                                Update Pay
                            </Button>
                        </>
                    )}

                </ModalFooter>

            </ModalContent>
        </Modal>
    );
};

export default WasteDetailModal;
