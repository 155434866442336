import React from 'react';
import { Card, Image, Stack, CardBody, Heading, Text, CardFooter, Flex, Button, Box, HStack, Divider, Spacer } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { BiEditAlt, BiHistory } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import moment from 'moment';

const StockCard = ({ x, navigate, handleDelete, openModal }) => {
    return (
        <Box
            borderRadius="md"
            shadow="xl"
            p={2}
            border="1px"
            borderColor="#F05A28"
            width="full"
            alignItems={"flex-start"}
            justifyContent="flex-start"
        >
            <Flex direction={["column", "column", 'row']} align="center" gap={5}>
                <Image
                    borderRadius={'lg'}
                    shadow='md'
                    objectFit="cover"
                    w={'200px'}
                    h='150px'
                    src={x.image_url}
                    alt="Caffe Latte"
                />

                <Stack spacing={1} fontSize="sm">
                    <Text color={'gray.600'} textTransform={'capitalize'} fontSize='xs'>
                        {moment(x?.lastUpdated?.seconds * 1000 || x?.createdAt?.seconds * 1000).format("LLLL")}
                    </Text>
                    <Heading textTransform={'capitalize'} size="md">
                        {x.name}
                    </Heading>
                    <Text color={'gray.700'}>
                        {x.description}
                    </Text>
                    <Spacer />

                    <Stack justifyContent='space-around'>

                        <Text fontWeight={500} >Supplier : ({x.supplier})</Text>

                        <Text fontWeight={500} textTransform='capitalize'>
                            Stock: {x.stock} {x.quantity_type}
                        </Text>
                    </Stack>




                </Stack>
            </Flex>

            <Divider my={2} />

            <Flex direction={["column", "row", "row"]} gap={5} alignItems='center' justifyContent={'center'} >
                <Button
                    size={'sm'}
                    colorScheme="red"
                    leftIcon={<AiOutlineDelete />}
                    onClick={() => handleDelete(x)}
                >
                    Delete Menu
                </Button>

                <Stack  >
                    <Button
                        size={'sm'}
                        colorScheme="gray" leftIcon={<BiEditAlt />} onClick={() => navigate(`/stock/${x.id}/edit`)}>
                        Edit Menu
                    </Button>
                </Stack>

                <Stack  >
                    <Button
                        size={'sm'}
                        colorScheme="gray" leftIcon={<BiHistory />} onClick={() => openModal(x)}>
                        History
                    </Button>
                </Stack>
            </Flex>
        </Box>


    );
};

export default StockCard;
