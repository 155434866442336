import React from "react";
import { Route, Routes } from "react-router-dom";
import ChatRouter from "./ChatRouter";
import DashboardRouter from "./DashboardRouter";
import MenusRouter from "./MenusRouter";
import OrdersRouter from "./OrdersRouter";
import OutletsRouter from "./OutletsRoutes";
import ProfileRouter from "./ProfileRouter";
import ReportsRouter from "./ReportsRouter";
import SettingRouter from "./SettingRouter";
import StationRouter from "./StationRouter";
import TablesRouter from "./TablesRouter";
import TransactionRouter from "./TransactionRouter";
import WasteRouter from "./WasteRouter";
import StockRouter from "./StockRouter";
import SupplierRouter from "./SupplierRouter";
import ActivityRouter from "./ActivityRouter";

function MainRouter() {
	const allRouter = [
		...DashboardRouter,
		...MenusRouter,
		...TablesRouter,
		...OrdersRouter,
		...StationRouter,
		...ReportsRouter,
		...OutletsRouter,
		...WasteRouter,
		...TransactionRouter,
		...ProfileRouter,
		...ChatRouter,
		...StockRouter,
		...SupplierRouter,
		...ActivityRouter
	];
	return (
		<Routes>
			<Route path="setting/*" element={<SettingRouter />} />
			{allRouter.map((item, index) => {
				return <Route key={index} path={item.path} element={item.element} />;
			})}
		</Routes>
	);
}

export default MainRouter;
