import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Flex,
  Stack,
} from "@chakra-ui/react";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { Link } from "react-router-dom";
import {
  arrayUnionFirebase,
} from "../../Apis/firebaseApi";
import { db } from "../../Config/firebase";
import useUserStore from "../../Hooks/Zustand/Store";

function StationsPage() {
	const globalState = useUserStore();
  const [data, setData] = useState();
  const [addModal, setAddModal] = useState(false);

  const [stationTitle, setStationTitle] = useState("");

  const getData = () => {
    try {
      const unsubscribe = onSnapshot(doc(db, "rms", globalState?.currentProject), (docData) => {
        setData(docData.data());
      });
      return () => {
        unsubscribe();
    };
    } catch (error) {
      console.log(error, "ini error");
    }

    
  };

  const handleModalAdd = () => {
    setAddModal(true);
  };

  const handleStatonAdd = async () => {
    try {
      const result = await arrayUnionFirebase(
        "rms",
        globalState.currentProject,
        "stations",
        [stationTitle]
      );
      console.log(result); // Pesan toast yang berhasil
      setAddModal(false);
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  };

  useEffect(() => {
    getData();

    return () => {};
  }, [globalState?.currentProject]);

  return (
    <Box sx={{ height: "80vh" }}>
      <Stack flexDirection={["column", "row", "row"]} p={5}>
        <Heading textTransform={'capitalize'} size='lg'>stations</Heading>
        <Spacer />
        <Button onClick={() => handleModalAdd()} bgColor={'white'} shadow='md' variant='outline' borderColor='#F05A28' color='#F05A28'> 
          <HStack>
            <FcPlus />
            <Text>Stations</Text>
          </HStack>
        </Button>
      </Stack>

      <Flex
        justifyContent="center"
        alignItems="center"
        gap={10}
        sx={{ height: "100%" }}
        flexDirection={["column", "row", "row"]}
      >
        {data?.stations &&
          data.stations.map((nameStations, index) => (
            <Box
              w={250}
              borderWidth="1px"
              borderRadius="xl"
              borderColor="#F05A28"
              p={10}
              shadow="md"
              key={index}
            >
              <Text fontWeight={500} fontSize={20} textAlign="center">
                {nameStations?.toUpperCase()}
              </Text>

              <Link to={`${nameStations}`}>
                <Button
                  w="full"
                  mt={5}
                  colorScheme="orange"
                  backgroundColor="#F05A28"
                  color="white"
                  borderRadius="lg"
                >
                  Go to {nameStations}
                </Button>
              </Link>
            </Box>
          ))}
      </Flex>

      <Modal isOpen={addModal} onClose={() => setAddModal(false)}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Input
              type="text"
              placeholder="Stations Name"
              onChange={(e) => setStationTitle(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleStatonAdd()}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default StationsPage;
