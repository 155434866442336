import {
    Badge,
    Button,
    HStack,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spacer,
    Stack,
    Text,
    useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useState, useEffect } from "react";
import {
    updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import { formatFrice } from "../../Utils/numberUtil";
import { checkTransactionAssignValidity } from "../../Hooks/Middleware/UserMiddleWare";
import { auth } from "../../Config/firebase";
import { getCompanyName, getStoreName } from "../../Utils/nameUtil";
import useUserStore from "../../Hooks/Zustand/Store";

const TransactionDetailModal = ({ order: initialOrder, isOpen, onClose }) => {
    
	const globalState = useUserStore();

    const [order, setOrder] = useState();

    const [status, setStatus] = useState("")
    const [loading, setLoading] = useState(false)

    const toast = useToast({
        position: "top",
        align: "center",
    });





    const updatePaymentStatus = async (transactionId) => {
        setLoading(true)
        const collectionName = "transactions";
        const docName = transactionId;
        const dataPayload = {
            ...order,
            on_report: true,
            on_report_at: new Date(),
            on_report_person: auth.currentUser.email,
            assignment_status: status,
        };

        const fixPrice = order.totalPrice

        const validationResult = checkTransactionAssignValidity(fixPrice, 'owner');

        if (!validationResult.success) {
            toast(validationResult.error);
            setLoading(true)
            return;
        }


        try {
            await updateDocumentFirebase(
                collectionName,
                docName,
                dataPayload
            );
            onClose()
        } catch (error) {
            toast({
                title: "Error",
                description: "Terjadi kesalahan:", error,
                position: "top",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
        finally{
            setLoading(false)
        }

    };





    useEffect(() => {
        if (isOpen) {
            setOrder(initialOrder);
        } else {
            setOrder(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState.currentOutlet, globalState.currentCompany, isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    <Text>Transaction Detail</Text>
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody>
                    {order && (
                        <Stack spacing={2}>

                            <HStack alignItems={'flex-end'} justifyContent='flex-end'>
                                <Text fontSize={'sm'} color='gray.700'>{moment(order?.createdAt?.seconds * 1000).format('MMMM Do YYYY')}</Text>
                                <Text fontSize={'sm'} color='gray.700'>{moment(order?.createdAt?.seconds * 1000).format('h:mm:ss a')}</Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>ID:</Text>
                                <Spacer />
                                <Text fontWeight={500}>{order?.id}</Text>
                            </HStack>

                            <HStack>
                                <Text color='gray.600'>Company :</Text>
                                <Spacer />
                                <Text textTransform={'capitalize'} fontWeight={500}>{getCompanyName(globalState, order.companyId)}</Text>
                            </HStack>


                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>Store:</Text>
                                <Spacer />
                                <Text fontWeight={500}> {getStoreName(globalState, order?.outlet)}</Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>Notes:</Text>
                                <Spacer />
                                <Text fontWeight={500}> {order?.notes}</Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>Payment Method:</Text>
                                <Spacer />
                                <HStack>
                                    <Badge
                                        p={1} textAlign='center' variant={'solid'} borderRadius='md'
                                        colorScheme={"green"
                                        }
                                    >
                                        {order?.paymentMethod}
                                    </Badge>
                                    <Badge
                                        p={1} textAlign='center' variant={'solid'} borderRadius='md'
                                        colorScheme={order?.type === "outcome" ? "red" : "green"
                                        }
                                    >
                                        {order?.type}
                                    </Badge>
                                </HStack>
                            </HStack>


                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>Status:</Text>
                                <Spacer />
                                <HStack>
                                    <Badge
                                        p={1} textAlign='center' variant={'solid'} borderRadius='md'
                                        colorScheme={
                                            order?.assignment_status === "pending" ? "yellow" : order?.assignment_status === "rejected" ? "red"
                                                : "green"
                                        }
                                    >
                                        {order?.assignment_status}
                                    </Badge>


                                </HStack>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>On Report At:</Text>
                                <Spacer />
                                <Text fontWeight={500}>
                                    {moment(order?.on_report_at?.seconds * 1000).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                </Text>
                            </HStack>

                            <HStack justifyContent="space-between">
                                <Text color='gray.600'>On Report Person:</Text>
                                <Spacer />
                                <Text fontWeight={500}>
                                    {order?.on_report_person}
                                </Text>
                            </HStack>

                            <hr style={{ marginTop: 10, marginBottom: 10 }} />

                            <HStack p={2}>
                                <Text color={'gray.600'}>Amount: </Text>
                                <Spacer />
                                <Text fontWeight={500} color={order?.type === "outcome" ? "red.500" : "green.500"}>
                                    Rp. {formatFrice(order?.amount)}
                                </Text>
                            </HStack>

                            <Select
                                borderRadius="lg"
                                placeholder="select status"
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option value={"approved"}>
                                    <Text textTransform={"capitalize"}>Approved</Text>
                                </option>
                                <option value={"rejected"}>
                                    <Text textTransform={"capitalize"}>Rejected</Text>
                                </option>
                            </Select>


                          

                            <Text>File:</Text>
                            <HStack justifyContent="space-between">
                                <Image
                                    src={order?.image_url}
                                    width={'100%'}
                                    fallbackSrc="https://via.placeholder.com/150"
                                />
                            </HStack>






                        </Stack>
                    )}
                </ModalBody>
                <ModalFooter>
                    {order?.assignment_status === "pending" && (
                        <>
                            <Button colorScheme="red" mr={3} onClick={onClose}>
                                Close
                            </Button>

                            <Button
                                colorScheme="green"
                                mr={3}
                                isLoading={loading}
                                onClick={() => updatePaymentStatus(order?.id)}
                            >
                                Update Pay
                            </Button>
                        </>
                    )}

                </ModalFooter>

            </ModalContent>
        </Modal>
    );
};

export default TransactionDetailModal;
