import { Badge, HStack, IconButton, Image, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import useUserStore from '../../Hooks/Zustand/Store';
import WasteDetailModal from '../../Pages/Waste/WasteDetailModal';
import { getStoreName } from '../../Utils/nameUtil';
import { formatFrice } from '../../Utils/numberUtil';

export const ActivityTable = ({ data }) => {

    const globalState = useUserStore();




    return (
        <>
            <Table {...data} variant="striped" bgcolor='white' borderRadius={'md'} shadow='md' m={2}>
                <Thead>
                    <Tr>
                        <Th>Created Date</Th>
                        <Th>PIC</Th>
                        <Th>Items</Th>
                        <Th>Amount</Th>
                        <Th>Status</Th> {/* Added column for Report Status */}
                        <Th>Type</Th> {/* Added column for Report Status */}
                        <Th>Actions</Th> {/* Added column for Report Status */}
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((x, index) => {
                        const storeName = getStoreName(globalState, x.outlet);

                        return (
                            <Tr key={index}>
                                <Td fontSize="2xs">
                                    <Stack>
                                        <Image objectFit={'cover'} w='200px' h={'100px'} src={x.image} alt={x.name_items} />
                                        <Text fontWeight={500} color='gray.500'>{moment(x?.createdAt?.seconds * 1000).format('LLLL')}</Text>
                                    </Stack>
                                </Td>
                                <Td>
                                    <Text fontSize='sm' fontWeight={500}>{x?.name}</Text>
                                    {/* <Text textTransform={'capitalize'} fontSize="2xs">Outlets: {storeName}</Text>
                                    <Text textTransform={'capitalize'} fontSize="2xs">Notes: {x?.notes}</Text> */}
                                </Td>
                                <Td>
                                    <Stack>
                                        <Text fontSize='sm' textTransform={'capitalize'}>{x.name_items}</Text>
                                    </Stack>

                                </Td>
                                <Td>
                                    <Stack>
                                        <Text fontSize={'sm'} fontWeight={500}>{x.value}</Text>
                                    </Stack>
                                </Td>
                                <Td>
                                    <Stack>

                                        <Text textAlign={'center'}
                                            color={x.status === "inbound" ? "green" : "red"} p={1} borderRadius='md'>{(x.status)}</Text>
                                    </Stack>
                                </Td>
                                <Td>
                                    <Stack>

                                        <Text textAlign={'center'}
                                            fontWeight={500} p={1} borderRadius='md'>{(x.type)}</Text>
                                    </Stack>
                                </Td>
                                <Td>

                                    <HStack spacing="1">
                                        <IconButton
                                            icon={<FiEdit2 fontSize="1.25rem" />}
                                            variant="ghost"
                                            aria-label="Edit member"
                                            onClick={() => console.log(x)}
                                        />
                                    </HStack>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>

        </>
    );
};
