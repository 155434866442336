import { Icon } from "@chakra-ui/icons";
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Center,
	Divider,
	Flex,
	HStack,
	Image,
	Select,
	Spacer,
	Stack,
	Text,
	VStack,
	useBreakpointValue,
	useToast,
} from "@chakra-ui/react";
import { FiSettings, FiLogOut } from "react-icons/fi";
import store from "store";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { UserProfile } from "./UserProfile";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../Config/firebase";


import { data } from "./DataMenu";
import useUserStore from "../../Hooks/Zustand/Store";
import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { getCollectionFirebase } from "../../Apis/firebaseApi";
import themeConfig from "../../Config/themeConfig";
import { logoutUserWithIp } from "../../Hooks/Middleware/sessionMiddleWare";

// ** Theme Configuration

function SidebarComponentV2({ layout }) {
	const [desktopShow, setDesktopShow] = useState(true);
	const isDesktop = useBreakpointValue({ base: false, lg: desktopShow });

	const [listProject, setListProject] = useState([])
	const [listOutlet, setListOutlet] = useState([])

	const globalState = useUserStore();

	const handleCompanySelect = (e) => {
		const dataCompany = globalState.companies;

		const findCompany = dataCompany.find((x) => x.id === e);

		globalState.setCurrentCompany(findCompany.id || e);
		globalState.setUsers(findCompany.users);
		globalState.setCurrentXenditId(findCompany?.xenditId);

		if (findCompany.id || e) {
			getProjectList(findCompany.id || e)
		}

		if (findCompany.owner && findCompany.owner.includes(e)) {
			// Jika iya, tambahkan field "owner" ke dalam objek data[0]
			globalState.setRoleProject("owner");
		} else if (findCompany.managers && findCompany.managers.includes(e)) {
			globalState.setRoleProject("managers");
		} else {
			globalState.setRoleProject("user");
		}
	};

	const getProjectList = async (id) => {

		const conditions = [
			{
				field: "users",
				operator: "array-contains",
				value: globalState?.uid,
			},
			{
				field: "companyId",
				operator: "==",
				value: id,
			},
		];

		try {

			const projects = await getCollectionFirebase("projects", conditions);

			globalState.setProjects(projects);
			globalState.setCurrentProject(projects[0]?.id);

			// if (id) {
			// 	getOutletList(id)
			// }


			if (projects.length > 0 && projects[0].owner?.includes(globalState?.uid)) {
				globalState.setRoleProject("owner");
			} else if (projects.length > 0 && projects[0].managers?.includes(globalState?.uid)) {
				globalState.setRoleProject("managers");
			} else {
				globalState.setRoleProject("user");
			}

			setListProject(projects)



		} catch (error) {
			console.log(error)
		}
	}

	const getOutletList = async (id) => {
		if (id) {
			const conditions = [
				{
					field: "users",
					operator: "array-contains",
					value: globalState?.uid,
				},
				{
					field: "projectId",
					operator: "==",
					value: id,
				},
			];

			try {

				const outlets = await getCollectionFirebase("outlets", conditions);

				globalState.setOutlets(outlets);
				globalState.setCurrentOutlet(outlets[0]?.id);


				if (outlets?.length > 0 && outlets[0]?.owner?.includes(globalState?.uid)) {
					globalState.setRoleOutlet("owner");
				} else if (outlets?.length > 0 && outlets[0]?.managers?.includes(globalState?.uid)) {
					globalState.setRoleOutlet("managers");
				} else {
					globalState.setRoleOutlet("user");
				}

				setListOutlet(outlets)



			} catch (error) {
				console.log(error)
			}
		}

	}

	useEffect(() => {
		getProjectList(globalState?.currentCompany)

		return () => {
		}
	}, [globalState?.currentCompany])


	useEffect(() => {
		getOutletList(globalState?.currentProject)

		return () => {
		}
	}, [globalState?.currentProject])



	const handleProjectSelect = (e) => {
		if (e) {
			const dataProject = listProject && listProject

			if (dataProject) {

				const findProject = dataProject.find((x) => x.id === e);

				globalState.setCurrentProject(findProject.id || e);

				if (findProject?.owner && findProject?.owner.includes(e)) {
					// Jika iya, tambahkan field "owner" ke dalam objek data[0]
					globalState.setRoleProject("owner");
				} else if (findProject?.managers && findProject?.managers.includes(e)) {
					globalState.setRoleProject("managers");
				} else {
					globalState.setRoleProject("user");
				}
			}
		}


	};

	const handleOutletSelect = (e) => {
		const dataOutlet = listOutlet


		const findOutlet = dataOutlet.find((x) => x.id === e);

		globalState.setCurrentOutlet(findOutlet.id || e);

		if (findOutlet?.owner && findOutlet?.owner?.includes(e)) {
			// Jika iya, tambahkan field "owner" ke dalam objek data[0]
			globalState.setRoleOutlet("owner");
		} else if (findOutlet?.managers && findOutlet?.managers.includes(e)) {
			globalState.setRoleOutlet("managers");
		} else {
			globalState.setRoleOutlet("users");
		}
	};

	const navigate = useNavigate()

	const toast = useToast()



	const logout = async () => {

		const pathLink = 'rms'
		await logoutUserWithIp(window.location.hostname, globalState?.email, pathLink);

		signOut(auth)
			.then(() => {


				// Sign-out successful.
				toast({
					status: "success",
					description: "Logged out success",
					duration: 2000,
				});

				globalState.setIsLoggedIn(false);
				store.clearAll();
			})
			.catch((error) => {
				console.log(error, "ini erro");
			}).finally(() => {

				navigate("/login");
			})

	};

	const handleClick = () => {
		setDesktopShow(!desktopShow);
	};



	if (layout.type === "vertical" || layout.type === "vertical-horizontal")
		return (
			<>
				<Box
					height="full"
					width={desktopShow ? "auto" : "15px"}
					transition={"0.2s ease-in-out"}
					display={"initial"}
					overflowY="auto"
					shadow={"xl"}
					overflow="wrap"
					roundedBottomRight={"lg"}
					roundedTopRight={"lg"}
					backgroundColor={themeConfig.color.colorFirst}
				>
					
					<Box position="sticky" overflowY="auto"
					>
						<Stack
							position={"absolute"}
							right={-2}
							cursor={"pointer"}
							onClick={handleClick}
							alignItems="flex-end"
							justifyContent={"flex-end"}
							p={2}
							borderRadius='full'
							bgColor='blue.200'
							zIndex={500}
						>
							{desktopShow ? (
								<IoIosArrowBack size={15} />
							) : (
								<IoIosArrowForward size={15} />
							)}
						</Stack>
						
						<Flex as="section" minH="100vh" >
							<Flex
								flex="1"
								bg="bg-surface"
								boxShadow="sm"
								maxW={{
									base: "full",
									sm: "xs",
								}}
								py={{
									base: "4",
									sm: "6",
								}}
								px={[1, 1, 5]}
							>
								<Stack
									justify="space-between" spacing={3}
								>
									<Stack spacing={3} shouldWrapChildren>
										{/* <Logo /> */}
										{isDesktop ? (
											<Center>
												<Image src={themeConfig.logo} maxH={100} />
											</Center>
										) : (
											<Center>
												<Image
													src={themeConfig.logokotak}
													borderRadius="full"
													maxH={50}
												/>
											</Center>
										)}

										<Stack alignItems={"center"}>
											<Select
												w={["100%", "100%", "100%"]}
												size={"sm"}
												defaultValue={globalState.companies[0]}
												onChange={(e) => {
													handleCompanySelect(e.target.value);
												}}
											>
												{globalState.companies?.map((select, i) => (
													<option
														defaultValue={globalState.currentCompany}
														key={i}
														value={select?.id}
													>
														<Text textTransform={"capitalize"}>
															{select?.name}
														</Text>
													</option>
												))}
											</Select>
										</Stack>

										<Stack alignItems={"center"}>
											<Select
												w={["100%", "100%", "100%"]}
												size={"sm"}
												defaultValue={globalState?.projects[0]}
												onChange={(e) => {
													handleProjectSelect(e.target.value);
												}}
											>
												{listProject?.length > 0 && (
													listProject?.map((select, i) => (
														<option
															defaultValue={globalState?.currentProject}
															key={i}
															value={select?.id}
														>
															<Text textTransform={"capitalize"}>
																{select?.name}
															</Text>
														</option>
													))
												)}

											</Select>
										</Stack>

										<Stack alignItems={"center"}>
											<Select
												w={["100%", "100%", "100%"]}
												size={"sm"}
												defaultValue={globalState?.currentOutlet}
												onChange={(e) => {
													handleOutletSelect(e.target.value);
												}}
											>
												{listOutlet?.length > 0 && (
													listOutlet?.map((select, i) => (
														<option
															defaultValue={globalState?.currentOutlet}
															key={i}
															value={select?.id}
														>
															<Text textTransform={"capitalize"}>
																{select?.name}
															</Text>
														</option>
													))
												)}
											</Select>
										</Stack>


										<Stack
											alignItems={"center"}

										>

											<Accordion allowToggle>

												{data.map((x, i) => (
													<AccordionItem
														key={i} isDisabled={x.name === "Social Media" ? true : false}
													>
														<h2>
															<AccordionButton>
																{x.name === 'Scoreboard' || x.name === 'Transaction' || x.name === 'Reports' || x.name === 'Profiles' ?
																	<HStack spacing={2} onClick={() => navigate(x?.link)} align={'center'}>
																		<Icon as={x.icon}
																			boxSize={isDesktop ? 5 : 7}
																		/>
																		{isDesktop && (
																			<>
																				<Text
																					fontWeight={500}
																					fontSize="sm"
																					noOfLines={1}
																				>
																					{x.name}
																				</Text>
																			</>
																		)}
																	</HStack>
																	:
																	<HStack spacing={2} >
																		<Icon as={x.icon} boxSize={isDesktop ? 5 : 7} />
																		{isDesktop && (
																			<Text
																				fontWeight={500}
																				fontSize="sm"
																				noOfLines={1}
																			>
																				{x.name}
																			</Text>
																		)}
																		<Spacer />
																		<AccordionIcon />

																	</HStack>
																}
															</AccordionButton>
														</h2>
														{x.submenu ? (
															<>
																<AccordionPanel>
																	<Stack>
																		{x.submenu?.map((subitem, i) => (
																			<Link to={subitem.link} key={i}>
																				<HStack spacing="3">
																					<Icon
																						as={subitem.icon}
																						boxSize={5}
																					/>
																					{isDesktop && (
																						<>
																							<Text
																								pl={3}
																								fontWeight={500}
																								fontSize="sm"
																								noOfLines={1}
																							>
																								{subitem.name}
																							</Text>
																						</>
																					)}
																				</HStack>
																			</Link>
																		))}
																	</Stack>
																</AccordionPanel>
															</>
														) : (
															<>{null}</>
														)}
													</AccordionItem>
												))}
											</Accordion>
										</Stack>
									</Stack>

									<Spacer />
									{/* <Button onClick={() => console.log(globalState)}>Check</Button> */}

									<Stack alignItems={'center'}>
										<Button
											as={Link}
											to={"/profiles"}
											variant="ghost"
											justifyContent="start"
										>
											<HStack spacing="3">
												<Icon as={FiSettings} boxSize={isDesktop ? 5 : 7} color="subtle" />
												{isDesktop &&
													<Text>Setting</Text>
												}
											</HStack>
										</Button>

										<Divider />
									</Stack>



									<Stack alignItems={'center'} justifyContent='center'>
										<Stack
											spacing={{
												base: "5",
												sm: "6",
											}}
										>

											{layout.type === "vertical" && (
												<>
													{globalState.isLoggedIn ? (
														<>

															{isDesktop ?
																<>


																	<UserProfile
																		name={globalState.name}
																		image={
																			globalState.email === null
																				? "https://tinyurl.com/yhkm2ek8"
																				: globalState.email
																		}
																		email={globalState.email}
																	/>
																	<Button
																		w={"full"}
																		colorScheme="red"
																		variant={'outline'}
																		size={"sm"}
																		onClick={logout}
																	>
																		Logout
																	</Button>
																</>
																:
																<VStack justify={'left'} align={'left'}>
																	<UserProfile
																		image={
																			globalState.email === null
																				? "https://tinyurl.com/yhkm2ek8"
																				: globalState.email
																		}
																	/>
																	<Box pl='2'>
																		<Icon
																			as={FiLogOut}
																			aria-current="page"
																			boxSize={"40px"}
																			p={3}
																			borderRadius="md"
																			cursor={"pointer"}
																			shadow="inherit"
																			color={"red"}
																			border={'1px red solid'}
																			onClick={() => logout()}
																		/>
																	</Box>
																</VStack>
															}

														</>
													) : (
														<Box>
															<Button
																w={"full"}
																colorScheme="telegram"
																size={"sm"}
																as={Link}
																to={"/login"}
															>
																Login
															</Button>
														</Box>
													)}
												</>
											)}

											{!layout.type && (
												<Stack>
													{isDesktop ? (
														<Button
															icon={FiLogOut}
															onClick={() => logout()}
															size="sm"
															colorScheme={"red"}
														>
															Logout
														</Button>
													) : (
														<Icon
															as={FiLogOut}
															aria-current="page"
															boxSize={"40px"}
															bgColor="red.400"
															p={3}
															borderRadius="md"
															cursor={"pointer"}
															shadow="inherit"
															color={"white"}
															onClick={() => logout()}
														/>
													)}
												</Stack>
											)}
										</Stack>

									</Stack>






								</Stack>
							</Flex>
						</Flex>
					</Box>


				</Box>

			</>
		);

	return <></>;
}

export default SidebarComponentV2;
