import { Box, Button, Divider, Heading, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react'
import moment from 'moment'
import React, { useState } from 'react'
import { FcPlus } from 'react-icons/fc'
import { useLocation, useParams } from 'react-router-dom'
import BackButtons from '../../Components/Buttons/BackButtons'
import { ReportDetailRefundTable } from '../../Components/Tables/ReportDetailRefundTable'
import { ReportDetailTable } from '../../Components/Tables/ReportDetailTable'
import useUserStore from '../../Hooks/Zustand/Store'
import { getCompanyName, getStoreName } from '../../Utils/nameUtil'
import { formatFrice } from '../../Utils/numberUtil'

function ReportsDetailPage() {

    const param = useParams()
    const result = useLocation()
    const data = result.state

    const [modalDetail, setModalDetail] = useState(false)

	const globalState = useUserStore();


    return (
        <Stack>

            <Stack p={[1, 1, 5]} spacing={5}>
                <Stack flexDirection={["column" , "row", "row"]}>
                    <BackButtons />
                    <Spacer />
                    <HStack>
                        <Button onClick={() => setModalDetail(true)} bgColor={'white'} shadow='md' variant='outline' borderColor='#F05A28' color='#F05A28'>
                            <HStack>
                                <Text>Report Detail</Text>
                            </HStack>
                        </Button>
                    </HStack>
                </Stack>
                <Stack py={5}>
                    <Box overflowX={'auto'}>
                    {data?.detail_order.length > 0 && (
                        <ReportDetailTable data={data} id={data.id} />
                    )}
                    </Box>
                    <Box overflowX={'auto'}>
                    {data?.detail_order_refund.length > 0 && (
                        <ReportDetailRefundTable data={data} id={data.id} />
                    )}
                    </Box>

                </Stack>
            </Stack>

            <Modal isOpen={modalDetail} onClose={() => setModalDetail(false)}>
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>Reports Detail</ModalHeader>

                    <ModalCloseButton />

                    <ModalBody>
                        <Stack>
                            <HStack alignItems={'flex-end'} justifyContent='flex-end'>
                                <Text fontSize={'sm'} color='gray.700'>{moment(data?.reported_at?.seconds * 1000).format('MMMM Do YYYY')}</Text>
                                <Text fontSize={'sm'} color='gray.700'>{moment(data?.reported_at?.seconds * 1000).format('h:mm:ss a')}</Text>
                            </HStack>


                            <HStack>
                                <Text color='gray.600'>Company :</Text>
                                <Spacer />
                                <Text textTransform={'capitalize'} fontWeight={500}>{getCompanyName(globalState, data.companyId)}</Text>
                            </HStack>

                            <HStack>
                                <Text color='gray.600'>Outlet :</Text>
                                <Spacer />
                                <Text textTransform={'capitalize'} fontWeight={500}>{getStoreName(globalState, data.outlet)}</Text>
                            </HStack>

                            <HStack>
                                <Text color='gray.600'>Shift :</Text>
                                <Spacer />
                                <Text textTransform={'uppercase'} fontWeight={500}>{data.shift}</Text>
                            </HStack>

                            <HStack>
                                <Text color='gray.600'>PIC :</Text>
                                <Spacer />
                                <Text fontWeight={500}>{data.person_in_charge}</Text>
                            </HStack>



                            <HStack>
                                <Text color="gray.600">Type Reports :</Text>
                                <Spacer />
                                <Text fontWeight={500}>{data.type_reports}</Text>
                            </HStack>

                            <HStack>
                                <Text color="gray.600">Sold Item:</Text>
                                <Spacer />
                                <Text textTransform={"capitalize"} fontWeight={500}>
                                    {data.product_stock}
                                </Text>
                            </HStack>



                            <HStack>
                                <Text color="gray.600">Order Total :</Text>
                                <Spacer />
                                <Text textTransform={"capitalize"} fontWeight={500}>
                                    {data.detail_order.length}
                                </Text>
                            </HStack>

                            <HStack>
                                <Text color="gray.600">Refund Item:</Text>
                                <Spacer />
                                <Text textTransform={"capitalize"} fontWeight={500}>
                                    {data.product_refund}
                                </Text>
                            </HStack>

                            <HStack>
                                <Text color="gray.600">Refund Total :</Text>
                                <Spacer />
                                <Text textTransform={"capitalize"} fontWeight={500}>
                                    {data.detail_order_refund.length}
                                </Text>
                            </HStack>
                            <Divider />

                            <Text fontWeight={500}>INCOME</Text>

                            <HStack>
                                <Text color='gray.600'>Amount :</Text>
                                <Spacer />
                                <Text fontWeight={500}>Rp. {formatFrice(data.payment_total)}</Text>
                            </HStack>
                            <HStack>
                                <Text color='gray.600'>Discount :</Text>
                                <Spacer />
                                <Text color={'red'} fontWeight={500}>Rp. {formatFrice(data.payment_total_discount)}</Text>
                            </HStack>
                            <HStack>
                                <Text color='gray.600'>Tax:</Text>
                                <Spacer />
                                <Text fontWeight={500}>Rp. {formatFrice(data.payment_total_tax)}</Text>
                            </HStack>
                            <Divider />
                            <HStack>
                                <Text color='gray.600'>Amount Income :</Text>
                                <Spacer />
                                <Text fontWeight={'bold'} fontSize='lg'>Rp. {formatFrice(data.payment_total - data.payment_total_discount + data.payment_total_tax)}</Text>
                            </HStack>
                            <Divider />

                            <Stack>
                                <Text color='gray.600' fontSize={'sm'}>Method :</Text>
                                <SimpleGrid columns={[2]} gap={1}>
                                    {Object.entries(data.payment_total_methods).map(([method, amount]) => (
                                        <Stack key={method} shadow='md' p={2} borderWidth={0.5} alignItems='center' justifyContent={'center'} borderRadius='md' borderColor={'green.500'} spacing={0}>
                                            <Text fontSize={'xs'} textTransform='capitalize'>{method}</Text>
                                            <Text fontWeight={'bold'} variant="solid" colorScheme="blue">
                                                Rp. {formatFrice(amount)}
                                            </Text>
                                        </Stack>
                                    ))}
                                </SimpleGrid>
                            </Stack>


                            <Divider />

                            <Text fontWeight={500}>OUTCOME</Text>


                            <HStack>
                                <Text color="gray.600">Amount :</Text>
                                <Spacer />
                                <Text fontWeight={500}>
                                    Rp. {formatFrice(data.payment_total_refund)}
                                </Text>
                            </HStack>
                            <Divider />

                            <HStack>
                                <Text color="gray.600">Amount Outcome :</Text>
                                <Spacer />
                                <Text fontWeight={"bold"} fontSize="lg">
                                    Rp.{" "}
                                    {formatFrice(
                                        data.payment_total_refund
                                    )}
                                </Text>
                            </HStack>
                            <Divider />

                            <Stack>
                                <Text color="gray.600" fontSize={"sm"}>
                                    Method :
                                </Text>
                                <SimpleGrid columns={[2]} gap={1}>
                                    {Object.entries(data.payment_total_refund_methods).map(
                                        ([method, amount]) => (
                                            <Stack
                                                key={method}
                                                shadow="md"
                                                p={2}
                                                borderWidth={0.5}
                                                alignItems="center"
                                                justifyContent={"center"}
                                                borderRadius="md"
                                                borderColor={"green.500"}
                                                spacing={0}
                                            >
                                                <Text fontSize={"xs"} textTransform="capitalize">
                                                    {method}
                                                </Text>
                                                <Text
                                                    fontWeight={"bold"}
                                                    variant="solid"
                                                    colorScheme="blue"
                                                >
                                                    Rp. {formatFrice(amount)}
                                                </Text>
                                            </Stack>
                                        )
                                    )}
                                </SimpleGrid>
                            </Stack>

                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={() => setModalDetail(false)}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
    )
}

export default ReportsDetailPage