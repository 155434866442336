// Tambahkan import Select dari Chakra UI
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Tabs,
  Tab,
  TabList,
  useToast,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  addDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import { createPaymentVA } from "../../Apis/xenditApi";
import BackButtons from "../../Components/Buttons/BackButtons";
import { formatFrice } from "../../Utils/numberUtil";
import useUserStore from "../../Hooks/Zustand/Store";

function OrdersNewPage() {
  const [data, setData] = useState([]);
  const [totalOrder, setTotalOrder] = useState({
    name: "",
    totalPrice: 0,
    table: "",
  });
  const [order, setOrder] = useState([]);

  const [tax, setTax] = useState(0);

  const [categoryData, setCategoryData] = useState({}); // State untuk menyimpan data yang telah dikelompokkan berdasarkan kategori

  const [openModalPriceType, setOpenModalPriceType] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [selectedPrice, setSelectedPrice] = useState();
  const [inputSearch, setInputSearch] = useState("");
  const [dataSearched, setDataSearched] = useState([]);

  const globalState = useUserStore();
  const navigate = useNavigate();

  const toast = useToast()

  const getData = () => {
    getCollectionFirebase("menus", [
      {
        field: "companyId",
        operator: "==",
        value: globalState.currentCompany,
      },
    ])
      .then((menus) => {
        const filteredMenus = menus?.filter((menu) =>
          menu?.outlets?.includes(globalState.currentOutlet)
        );
        const sortData = filteredMenus.sort((a, b) => a.title.localeCompare(b.title))

        setData(sortData);

        // Mapping data berdasarkan kategori
        const mappedData = {};
        filteredMenus.forEach((menu) => {
          const category = menu.category;
          if (!mappedData[category]) {
            mappedData[category] = [];
          }
          mappedData[category].push(menu);
        });

        setCategoryData(mappedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTax = async () => {
    try {
      const result = await getSingleDocumentFirebase('tax', globalState.currentCompany)
      setTax(result.amount, 'ini resut')
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenBill = () => {
    if (!totalOrder.table) {
      return (
        toast({
          title: "Deoapp",
          description: "No table Insert !.",
          status: "warning",
        }))
    }

    if (!totalOrder.name) {
      return (
        toast({
          title: "Deoapp",
          description: "No Name Insert !.",
          status: "warning",
        }))
    }


    const updatedOrders = order.map((order, index) => {
      const stationIndex = (index % 2) + 1; // Menghitung indeks stasiun (1 atau 2) berdasarkan indeks order
      const suffix = `_${stationIndex}`;

      const updatedStations = order.stations.map((station) => {
        return station.replace("checker", `checker${suffix}`);
      });

      return {
        ...order,
        stations: updatedStations,
      };
    });

    const newOrder = { ...totalOrder, orders: updatedOrders };
    newOrder.outlet = globalState.currentOutlet;
    newOrder.outletId = globalState.currentOutlet;
    newOrder.projectId = globalState.currentProject;
    newOrder.paymentStatus = "open";
    newOrder.orderStatus = "onProcess";
    newOrder.module = "rms";
    newOrder.tax = tax;
    newOrder.discount = newOrder.discount || 0;
    newOrder.createdAt = newOrder.createdAt ? new Date(newOrder.createdAt) : new Date()

    addDocumentFirebase("orders", newOrder, globalState.currentCompany).then(
      (x) =>
        //   createPaymentVA(x).then((x) => console.log(x))
        navigate(-1)
    );
  };

  const handleOpenModalPriceType = (menu, i) => {
    setOpenModalPriceType(true);
    setSelectedMenu({ menu, i });
  };

  const handleAddOrder = (menu, index) => {
    let newOrder = [...order];

    const indexSameOrder = order.findIndex((x) => x.id === menu.id); // check kalau order menu yang sama, get index

    // Decrease stok when user hit button pesan
    updateDocumentFirebase("menus", menu.id, {
      stock: menu.stock - 1,
    });

    if (indexSameOrder >= 0) {
      handleIncreaseQuantity(indexSameOrder);
    } else {
      if (menu.price_type === "multiple") {
        if (selectedPrice) {
          setOpenModalPriceType(false);

          const priceIndex = parseInt(selectedPrice);
          if (priceIndex >= 0 && priceIndex < menu.prices.length) {
            let price_type = "";
            if (priceIndex === 0) {
              price_type = "komplit";
            } else if (priceIndex === 1) {
              price_type = "ala carte";
            }

            newOrder.push({
              id: menu.id,
              title: menu.title,
              base_price: menu.prices[priceIndex],
              price: menu.prices[priceIndex],
              stations: menu.stations,
              quantity: 1,
              price_type: price_type,
              category: menu.category,
              variant_type: menu.variant_type ? menu.variant_type : "multiple",
            });
            setSelectedPrice();
          } else {
            alert("Invalid price selection");
          }
        }
      } else {
        newOrder.push({
          id: menu.id,
          title: menu.title,
          base_price: menu.price,
          price: menu.price,
          stations: menu.stations,
          quantity: 1,
          category: menu.category,
          variant_type: menu.variant_type ? menu.variant_type : "single",
        });
      }
      setOrder(newOrder);
    }
  };

  const calculateTotalPrice = useCallback(() => {
    const totalPrice = order.reduce(
      (acc, item) => acc + parseInt(item.price),
      0
    );
    setTotalOrder((prev) => ({
      ...prev,
      totalPrice,
    }));
  }, [order]);

  const handleIncreaseQuantity = (menu, index) => {
    const updatedOrder = [...order];
    const quantityPerPrice =
      updatedOrder[index].price / updatedOrder[index].quantity; // Hitung harga item per quantity

    updatedOrder[index].quantity += 1;
    updatedOrder[index].price = quantityPerPrice * updatedOrder[index].quantity;
    setOrder(updatedOrder);

    // Update Stock to Firebase
    const filteredMenu = data.filter((list) => list.id === menu.id);
    filteredMenu.map((list) => {
      updateDocumentFirebase("menus", menu.id, {
        stock: list.stock - 1,
      });
    });
  };

  const handleDecreaseQuantity = (menu, index) => {
    const updatedOrder = [...order];
    const quantity = updatedOrder[index].quantity;

    if (quantity > 1) {
      const quantityPerPrice =
        updatedOrder[index].price / updatedOrder[index].quantity;
      updatedOrder[index].quantity -= 1;
      updatedOrder[index].price =
        quantityPerPrice * updatedOrder[index].quantity;
      setOrder(updatedOrder);

      // Update Stock to Firebase
      const filteredMenu = data.filter((list) => list.id === menu.id);
      filteredMenu.map((list) => {
        updateDocumentFirebase("menus", menu.id, {
          stock: list.stock + 1,
        });
      });
    } else if (quantity === 1) {
      updatedOrder.splice(index, 1); // Menghapus orderan dari daftar
      setOrder(updatedOrder);

      // Update Stock to Firebase
      const filteredMenu = data.filter((list) => list.id === menu.id);
      filteredMenu.map((list) => {
        updateDocumentFirebase("menus", menu.id, {
          stock: list.stock + 1,
        });
      });
    }
  };


  const handleTabSelection = (tabs) => {

    const element = document.getElementById(tabs);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }


  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = data.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataSearched(newData);
      setInputSearch(text);
    } else {
      setDataSearched([]);
      setInputSearch(text);
    }
  };

  useEffect(() => {
    getData();
    getTax()

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.currentOutlet, globalState.currentCompany]);

  // Buat fungsi total harga ganti secara dynamic
  useEffect(() => {
    calculateTotalPrice();
  }, [calculateTotalPrice]);

  return (
    <Box w={'100%'}>
      <Flex direction="row" align="center" id="all">
        <BackButtons />
        <Spacer />
      </Flex>

      <Box position="sticky" w='full' top={0} backgroundColor="#F7FAFC" zIndex={1}>
        <InputGroup mt={15}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Cari Menu"
            onChange={(e) => {
              searchFilterFunction(e.target.value);
            }}
          />
        </InputGroup>

        <Tabs
          variant="soft-rounded"
          colorScheme="orange"
          mb={19}
          p={3}
          borderRadius="lg"
          align="center"
          isLazy
        >
          <TabList flexWrap="wrap" justifyContent="center">
            <Tab
              onClick={() => {
                handleTabSelection("all");
              }}
            >
              ALL
            </Tab>

            {Object.entries(categoryData).map(([category]) => (
              <Tab
                key={category}
                onClick={() => {
                  handleTabSelection(category);
                }}
              >
                {category?.toUpperCase()}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Box>

      <Flex direction={["column", "row", "row"]}  gap={5} justifyContent="space-between">
        {dataSearched.length !== 0 ? (
          <Stack
            width={order.length === 0 && "60vw"}
            overflowY="auto"
          >
            <SimpleGrid columns={[1, null, 3]} gap={5} >
              {dataSearched.map((x, i) => (
                <Stack
                  key={i}
                  p="2"
                  spacing={2}
                  borderRadius="lg"
                  borderWidth={0.5}
                  shadow="lg"
                  // borderColor="blackAlpha.200"
                  borderColor="#F05A28"
                >
                  <Image src={x.image_url} alt={x.title} borderRadius="lg" objectFit={'cover'}
                    h='150px' />

                  <Stack spacing={1}>
                    <Text
                      fontSize="md"
                      textTransform="capitalize"
                      fontWeight={500}
                    >
                      {x.title}
                    </Text>

                    <Text fontSize="xs" textTransform="capitalize">
                      {x.description}
                    </Text>

                    {x.price_type === "multiple" ? (
                      <HStack>
                        {x.prices.map((y, index) => {
                          const priceTitle =
                            index === 0 ? "Komplit" : "Ala Carte";
                          return (
                            <Stack key={index}>
                              <Text fontSize={"sm"}>{priceTitle}</Text>
                              <Text fontSize={"sm"}>
                                Rp.{formatFrice(parseFloat(y))}
                              </Text>
                            </Stack>
                          );
                        })}
                      </HStack>
                    ) : (
                      <Text fontSize={"sm"}>
                        Rp. {formatFrice(parseFloat(x.price))}
                      </Text>
                    )}
                  </Stack>

                  <Spacer />

                  <Stack>
                    <Button
                      colorScheme="green"
                      size="sm"
                      borderRadius="lg"
                      width="full"
                      isDisabled={
                        x.stock === 0
                          ? true
                          : x.stock === undefined
                            ? true
                            : false
                      }
                      onClick={() => {
                        x.price_type === "multiple"
                          ? handleOpenModalPriceType(x, i)
                          : handleAddOrder(x, i);
                        // setRefreshData(true);
                      }}
                    >
                      {x.stock === 0
                        ? "Habis"
                        : x.stock === undefined
                          ? "Habis"
                          : "Pesan"}
                    </Button>
                  </Stack>
                </Stack>
              ))}
            </SimpleGrid>
          </Stack>
        ) : (
          <Stack
            width={["100%", "100%", "80%"]}
            overflowY="auto"

          >
            <Stack bgColor={'white'}>
              {Object.entries(categoryData).map(([category, categoryMenus]) => (
                <Stack
                  spacing={2}
                  key={category}
                  // py={2}
                  w={'auto'}
                  p={5}
                  shadow="xl"
                  borderWidth="1px"
                  borderRadius="xl"
                  borderColor="gray.300"
                  id={category}
                >
                  <Heading size="lg" textAlign="center" mb={5}>
                    {category?.toUpperCase()}
                  </Heading>

                  <hr style={{ marginBottom: 10 }} />

                  <SimpleGrid columns={[1, null, 3]} gap={5}>
                    {categoryMenus.map((x, i) => (
                      <Stack
                        key={i}
                        p="2"
                        spacing={2}
                        borderRadius="lg"
                        borderWidth={0.5}
                        shadow="lg"
                        // borderColor="blackAlpha.200"
                        borderColor="#F05A28"
                      >
                        <Image
                          src={x.image_url}
                          alt={x.title}
                          borderRadius="lg"
                          objectFit={'cover'}
                          h='150px'
                        />

                        <Stack spacing={1}>
                          <Text
                            fontSize="md"
                            textTransform="capitalize"
                            fontWeight={500}
                          >
                            {x.title}
                          </Text>

                          <Text fontSize="xs" textTransform="capitalize">
                            {x.description}
                          </Text>

                          {x.price_type === "multiple" ? (
                            <HStack>
                              {x.prices.map((y, index) => {
                                const priceTitle =
                                  index === 0 ? "Komplit" : "Ala Carte";
                                return (
                                  <Stack key={index}>
                                    <Text fontSize={"sm"}>{priceTitle}</Text>
                                    <Text fontSize={"sm"}>
                                      Rp.{formatFrice(parseFloat(y))}
                                    </Text>
                                  </Stack>
                                );
                              })}
                            </HStack>
                          ) : (
                            <Text fontSize={"sm"}>
                              Rp. {formatFrice(parseFloat(x.price))}
                            </Text>
                          )}
                        </Stack>

                        <Spacer />

                        <Stack>
                          <Button
                            colorScheme="green"
                            size="sm"
                            borderRadius="lg"
                            width="full"
                            isDisabled={
                              x.stock === 0
                                ? true
                                : x.stock === undefined
                                  ? true
                                  : false
                            }
                            onClick={() => {
                              x?.price_type === "multiple"
                                ? handleOpenModalPriceType(x, i)
                                : handleAddOrder(x, i);
                              // setRefreshData(true);
                            }}
                          >
                            {x.stock === 0
                              ? "Habis"
                              : x.stock === undefined
                                ? "Habis"
                                : "Pesan"}
                          </Button>
                        </Stack>
                      </Stack>
                    ))}
                  </SimpleGrid>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}

        <Stack
          w='auto'
          height="auto"
          maxH={600}
          overflowY="auto"
          p="5"
          mt={1}
          backgroundColor="white"
          shadow="lg"
          borderWidth="1px"
          borderRadius="xl"
          borderColor="#F05A28"
          position="sticky"
          top={100}
        >
          <Heading size={"md"} textAlign="center">
            ORDER
          </Heading>

          <hr />

          {order?.map((x, i) => {
            return (
              <Stack key={i}>
                <HStack>
                  <Box>
                    <Text fontSize={"sm"} fontWeight={500}>
                      {x.title}
                    </Text>

                    {x.variant && (
                      <Text fontSize={"sm"}>
                        Type : {x.price_type && x.price_type}
                      </Text>
                    )}

                    <Text fontSize={"sm"}>
                      Rp.{formatFrice(parseFloat(x.price))}
                    </Text>
                  </Box>

                  <Spacer />

                  <Stack>
                    <HStack>
                      <Button
                        size={"sm"}
                        onClick={() => {
                          handleDecreaseQuantity(x, i);
                        }}
                      >
                        -
                      </Button>
                      <Text>{x.quantity}</Text>
                      <Button
                        size={"sm"}
                        onClick={() => {
                          handleIncreaseQuantity(x, i);
                        }}
                      >
                        +
                      </Button>
                    </HStack>
                  </Stack>
                </HStack>

                <Stack>

                  {x.variant_type === "multiple" && x.price_type === "komplit" && (
                    <RadioGroup
                      onChange={(e) => {
                        const updatedOrder = [...order];
                        updatedOrder[i].variant = e;
                        if (e === "barra") {
                          updatedOrder[i].price =
                            (parseInt(updatedOrder[i].base_price) + 1000) *
                            updatedOrder[i].quantity;
                        } else {
                          updatedOrder[i].price =
                            parseInt(updatedOrder[i].base_price) *
                            updatedOrder[i].quantity;
                        }
                        setOrder(updatedOrder);
                      }}
                    >
                      <HStack align="start" fontSize={"sm"}>
                        {["bakka", "barra", "gajja"].map((value, index) => (
                          <Radio key={index} value={value}>
                            <Text>
                              {value.charAt(0).toUpperCase() + value.slice(1)}
                            </Text>
                          </Radio>
                        ))}
                      </HStack>
                    </RadioGroup>
                  )}
                </Stack>

                <Input
                  size="sm"
                  borderRadius="md"
                  placeholder="Tambah catatan"
                  onChange={(e) => {
                    const updatedOrder = [...order];
                    updatedOrder[i].notes = e.target.value;
                    setOrder(updatedOrder);
                  }}
                />

                <hr style={{ marginTop: 20 }} />
              </Stack>
            );
          })}

          <Stack direction="row" align="center">
            <Text fontWeight={500}>No Meja:</Text>

            <Spacer />

            <Select
              width={200}
              value={totalOrder.table}
              onChange={(e) =>
                setTotalOrder({ ...totalOrder, table: e.target.value })
              }
            >
              <option value="">Pilih Nomor Meja</option>
              {Array.from({ length: 20 }, (_, index) => (
                <option key={index} value={index + 1}>
                  Meja {index + 1}
                </option>
              ))}
            </Select>
          </Stack>

          <Stack direction="row" align="center">
            <Text fontWeight={500}>Nama Pemesan:</Text>

            <Spacer />

            <Input
              width={200}
              type="name"
              onChange={(e) =>
                setTotalOrder({ ...totalOrder, name: e.target.value })
              }
              placeholder="Masukkan Nama"
            />
          </Stack>

          <Stack direction="row" align="center">
            <Text fontWeight={500}>Tanggal Pemesanan:</Text>

            <Spacer />

            <Input
              width={200}
              type="datetime-local"
              onChange={(e) =>
                setTotalOrder({ ...totalOrder, createdAt: e.target.value })
              }
              placeholder="Masukkan Nama"
            />
          </Stack>

          <Stack direction="row" align="center">
            <Text fontWeight={500}>Total:</Text>
            <Spacer />
            <Text fontWeight={700} fontSize={20}>
              Rp.{formatFrice(totalOrder.totalPrice)}
            </Text>
          </Stack>

          <Button onClick={handleOpenBill} colorScheme="green" padding={5}>
            Open Bill
          </Button>

          {/* <Button onClick={handleCloseBill}>Close Bill</Button> */}
        </Stack>
        {/* )} */}
      </Flex>

      {selectedMenu && (
        <Modal
          isOpen={openModalPriceType}
          onClose={() => setOpenModalPriceType(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedMenu?.menu?.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Pilih Tipe Menu</FormLabel>
                <RadioGroup
                  onChange={(value) => {
                    setSelectedPrice(String(value));
                  }}
                  value={selectedPrice}
                >
                  <Stack>
                    {selectedMenu.menu?.prices?.map((y, index) => {
                      const priceTitle = index === 0 ? "Komplit" : "Ala Carte";
                      return (
                        <Radio value={String(index)} key={index}>
                          {priceTitle}: Rp.{formatFrice(parseFloat(y))}
                        </Radio>
                      );
                    })}
                  </Stack>
                </RadioGroup>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() =>
                  handleAddOrder(selectedMenu.menu, selectedMenu?.i)
                }
              >
                Pilih
              </Button>
              <Button onClick={() => setOpenModalPriceType(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default OrdersNewPage;
