import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Box,
    FormLabel,
    Input,
    Select,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    TableContainer,
    Text,
} from '@chakra-ui/react';
import Pagination from "@choc-ui/paginator";
import DatePicker from '../../Pages/Stock/Times';
import moment from 'moment';

const ModalStock = ({
    isOpen,
    onClose,
    history,
    setHistory,
    handleDateRangeChange,
    paginate,
    ren,
    itemsPerPage,
    currentPage,
    page,
    setCurrentPage,
    handleAddHistory,
    closeModal
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Stock</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <FormLabel htmlFor="status">Status</FormLabel>
                        <Select
                            placeholder="Select option"
                            onChange={(e) =>
                                setHistory({
                                    ...history,
                                    status: e.target.value,
                                })
                            }
                        >
                            <option value="inbound">inbound</option>
                            <option value="outbound">outbound</option>
                        </Select>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="value">Value</FormLabel>
                        <Input
                            placeholder="Quantity"
                            type="number"
                            onChange={(e) =>
                                setHistory({
                                    ...history,
                                    value: parseInt(e.target.value),
                                })
                            }
                        />
                    </Box>
                    <Box marginTop="20px">
                        <FormLabel htmlFor="dateFilter">Filter by</FormLabel>
                        <Flex>
                            <DatePicker onDateChange={handleDateRangeChange} />
                        </Flex>
                    </Box>
                    {/* Tambahkan tabel dan komponen lainnya seperti yang Anda butuhkan */}


                </ModalBody>
                <TableContainer>
                    <Table size="lg">
                        <Thead>
                            <Tr>
                                <Th>PIC</Th>
                                <Th>Date</Th>
                                <Th>Status</Th>
                                <Th>Value</Th>
                            </Tr>
                        </Thead>
                        {paginate(ren, itemsPerPage, currentPage)?.map((index) => (
                            <Tbody key={index.id}>
                                <Tr>
                                    <Td>{index.name}</Td>
                                    <Td>
                                        {moment
                                            .unix(index.createdAt.seconds)
                                            .format("YYYY-MM-DD hh:mm:ss")}
                                    </Td>
                                    <Td>
                                        <Text color={index.status === "inbound" ? "green.500" : "red.500"} fontWeight={500}>{index.status}</Text>
                                    </Td>
                                    <Td>{index.value}</Td>
                                </Tr>
                            </Tbody>
                        ))}
                    </Table>
                </TableContainer>

                <Flex
                    w="full"
                    bg={"gray.400"}
                    _dark={{
                        bg: "gray.600",
                    }}
                    p={30}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Pagination
                        defaultCurrent={1}
                        total={page}
                        paginationProps={{
                            display: "flex",
                        }}
                        onChange={(page) => setCurrentPage(page)}
                    />
                </Flex>

                <ModalFooter>
                    <Button colorScheme="red" mr={3} onClick={closeModal}>
                        Close
                    </Button>
                    <Button
                        variant="ghost"
                        colorScheme="blue"
                        onClick={() => handleAddHistory()}
                    >
                        Submit
                    </Button>
                </ModalFooter>
                {/* Lanjutkan dengan konten lainnya seperti tabel dan footer */}
            </ModalContent>
        </Modal>
    );
};

export default ModalStock;
