import React from "react";
import WasteFoodPage from "../Pages/Waste/WasteFoodPage";

const WasteRouter = [
  {
    path: "/wastefood",
    element: <WasteFoodPage />,
  },
];

export default WasteRouter;
