import { Icon } from '@chakra-ui/icons'
import {
	Avatar,
	Box,
	Button,
	Divider,
	Flex,
	HStack,
	Input,
	InputGroup,
	InputLeftElement,
	Progress,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react'
import {
	FiBarChart2,
	FiBookmark,
	FiCheckSquare,
	FiHelpCircle,
	FiHome,
	FiSearch,
	FiSettings,
	FiUsers,
	FiMoon,
	FiSun,
} from 'react-icons/fi'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { logOutUser } from '../../Apis/firebaseApi'
import { Logo } from '../Logo'

export const SidebarComponent = () => {
	const { colorMode, toggleColorMode } = useColorMode()

	const navigate = useNavigate()
	const NavButton = (props) => {
		const { icon, label, ...buttonProps } = props
		return (
			<Button variant="ghost" justifyContent="start" {...buttonProps}>
				<HStack spacing="3">
					<Icon as={icon} boxSize="6" color="subtle" />
					<Text>{label}</Text>
				</HStack>
			</Button>
		)
	}

	const UserProfile = (props) => {
		const { name, image, email } = props
		return (
			<HStack spacing="3" ps="2">
				<Avatar name={name} src={image} boxSize="10" />
				<Box>
					<Text fontWeight="medium" fontSize="sm">
						{name}
					</Text>
					<Text color="muted" fontSize="sm">
						{email}
					</Text>
				</Box>
			</HStack>
		)
	}

	return (
		<Flex as="section" minH="100vh" bg="bg-canvas" overflowY='auto' w='min' m='2' borderRight='brand.50'>
			<Flex
				flex="1"
				bg="bg-surface"
				boxShadow="sm"
				maxW={{
					base: 'full',
					sm: 'xs',
				}}
				py={{
					base: '6',
					sm: '8',
				}}
				px={{
					base: '4',
					sm: '6',
				}}
			>
				<Stack justify="space-between" spacing="1">
					<Stack
						spacing={{
							base: '5',
							sm: '6',
						}}
						shouldWrapChildren
					>
						<Logo />
						<InputGroup>
							<InputLeftElement pointerEvents="none">
								<Icon as={FiSearch} color="muted" boxSize="5" />
							</InputLeftElement>
							<Input placeholder="Search" />
						</InputGroup>
						<Stack spacing="1">
							<NavButton label="Home" icon={FiHome} />
							<Link to='dashboard'>
								<NavButton label="Dashboard" icon={FiBarChart2} aria-current="page" />
							</Link>
							<NavButton label="Tasks" icon={FiCheckSquare} />
							<NavButton label="Bookmarks" icon={FiBookmark} />
							<NavButton label="Users" icon={FiUsers} />
						</Stack>
					</Stack>
					<Stack
						spacing={{
							base: '5',
							sm: '6',
						}}
					>
						<Stack spacing="1">
							<NavButton label="Help" icon={FiHelpCircle} />
							<Link to='setting'>
								<NavButton label="Settings" icon={FiSettings} />
							</Link>
							<NavButton onClick={toggleColorMode} label={colorMode === 'light' ? 'Dark' : 'Light'} icon={colorMode === 'light' ? FiMoon : FiSun} />


						</Stack>
						<Box bg="bg-subtle" px="4" py="5" borderRadius="lg">
							<Stack spacing="4">
								<Stack spacing="1">
									<Text fontSize="sm" fontWeight="medium">
										Almost there
									</Text>
									<Text fontSize="sm" color="muted">
										Fill in some more information about you and your person.
									</Text>
								</Stack>
								<Progress value={80} size="sm" aria-label="Profile Update Progress" />
								<HStack spacing="3">
									<Button variant="link" size="sm">
										Dismiss
									</Button>
									<Button variant="link" size="sm" colorScheme="blue">
										Update profile
									</Button>
								</HStack>
							</Stack>
						</Box>
						<Divider />
						<UserProfile
							name="Christoph Winston"
							image="https://tinyurl.com/yhkm2ek8"
							email="chris@chakra-ui.com"
						/>

						<Button onClick={() => { logOutUser(); navigate('/') }}>Logout</Button>
					</Stack>
				</Stack>
			</Flex>
		</Flex>
	)
}