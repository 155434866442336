import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Container,
  VStack,
  HStack,
  Stack,
  Text,
  Spacer,
  Heading,
  Image,
  Box,
  Divider,
  SimpleGrid,
  RadioGroup,
  Radio,
  Button,
  useToast,
} from "@chakra-ui/react";
import { formatFrice } from "../../Utils/numberUtil";
import CopyToClipboardUtil from "../../Utils/CopyToClipboardUtil";

const OrderCustomerPayDrawer = ({
  store,
  isOpen,
  onClose,
  param,
  order,
  totalOrder,
  paymentVA,
  selectedPaymentMethod,
  selectedPaymentMethodImage,
  handleCancelPayment,
  handleInputPayment,
  handleOrderPayConfirm,
  handlePaymentMethodSelect,
  banks,
  loading
}) => {
  const toast = useToast({
    position: "top",
    align: "center",
  });

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      title: "Sambal Bakar",
      description: "Copy to clipboard.",
      status: "success",
    });
  };

  return (
    <Drawer maxW="xl" placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton onClick={onClose} />

        <DrawerHeader borderBottomWidth="1px" textAlign="center">
          <Text textAlign="center" fontSize="xl" fontWeight="bold" mb="4">
            Invoice Table: {param?.table}
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <Container maxW="xl" p="2">
            {order?.length > 0 && (
              <VStack align="start" mb="4">
                {order.map((item, index) => (
                  <HStack
                    key={index}
                    w="full"
                    p="2"
                    borderBottom="1px"
                    borderColor="gray.200"
                  >
                    <VStack align="start" spacing={0}>
                      <Text textTransform="capitalize">{item.title}</Text>
                      <Text textTransform="capitalize" fontSize="sm">
                        Quantity : {item.quantity}
                      </Text>
                      <Text textTransform="capitalize" fontSize="sm">
                        notes : {item.notes}
                      </Text>
                    </VStack>
                    <Spacer />
                    <Box>
                      <Text>Rp. {formatFrice(item.price)}</Text>
                    </Box>
                  </HStack>
                ))}
              </VStack>
            )}

            <HStack pb={2} mx={2}>
              <Text size="md">Order Harga</Text>
              <Spacer />
              <Text size="md">Rp. {formatFrice(totalOrder?.totalPrice)}</Text>
            </HStack>
            <HStack pb={2} mx={2}>
              <Text size="md">Diskon</Text>
              <Spacer />
              <Text size="md" color={'red'}>Rp. {formatFrice(totalOrder?.discount)}</Text>
            </HStack>
            <HStack pb={2} mx={2}>
              <Text size="md">Tax</Text>
              <Spacer />
              <Text size="md">Rp. {formatFrice(totalOrder?.tax)}</Text>
            </HStack>
            <HStack pb={2} mx={2}>
              <Heading size="sm">Total </Heading>
              <Spacer />
              <Heading size="sm">
                Rp.
                {formatFrice(
                  totalOrder?.totalPrice +
                  totalOrder?.tax -
                  totalOrder?.discount
                )}
              </Heading>
            </HStack>
            {paymentVA !== "" ? (
              <Stack>
                <HStack w="full" px={5}>
                  <Image
                    src={selectedPaymentMethodImage}
                    alt={paymentVA?.bank_code}
                    w="80px"
                    borderRadius="xl"
                  />

                  <Spacer />

                  <Text fontSize="sm" textTransform="uppercase">
                    {paymentVA?.status}
                  </Text>
                </HStack>

                <Box bg="white" px={5}>
                  <Text>No. Virtual Account : </Text>
                  <Divider my={2} />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Text fontSize={20} color="gray.500">
                      {paymentVA ? paymentVA?.account_number : "none"}
                    </Text>
                    <Text
                      color="blue.600"
                      cursor="pointer"
                      onClick={() => handleCopy(paymentVA?.account_number)}
                    >
                      SALIN
                    </Text>
                  </Box>
                  <Divider my={2} />
                  <HStack py={1}>
                    <Text fontSize="sm" textTransform={'uppercase'}>{store}</Text>
                    <Spacer />
                    <Text fontSize="sm">
                      Rp. {formatFrice(paymentVA?.expected_amount)}
                    </Text>
                    <CopyToClipboardUtil text={paymentVA?.expected_amount} />
                  </HStack>
                  <Divider my={2} />

                  <Text fontSize={10} color="gray.600">
                    Proses verifikasi otomatis kurang dari 10 menit setelah
                    pembayaran berhasil
                  </Text>
                  <Spacer />
                  <Text fontSize={10} color="gray.600">
                    Bayar ke Virtual Account di atas sebelum membuat donasi baru
                    dengan Virtual account agar nomor tetap sama.
                  </Text>
                </Box>
                <Box bg="white" p={5}>
                  <Text fontSize={10} color="gray.600">
                    Petunjuk Transfer mBanking :
                  </Text>
                  <Divider />
                  <Text fontSize={10} color="gray.600">
                    1. Login ke mBanking-mu, pilih Transaksi, kemudian cari {paymentVA.bank_code} Virtual Account
                  </Text>
                  <Text fontSize={10} color="gray.600">
                    2. Masukkan nomor Virtual Account
                  </Text>
                  <Text fontSize={10} color="gray.600">
                    3. Pastikan nama dan nominal bayar benar
                  </Text>
                </Box>

                <HStack alignItems="center" justifyContent="center">
                  <Box>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => handleCancelPayment()}
                    >
                      Cancel payment
                    </Button>
                  </Box>

                  <Box>
                    <Button
                      colorScheme="green"
                      size="sm"
                      onClick={() => handleInputPayment()}
                    >
                      Done
                    </Button>
                  </Box>
                </HStack>
              </Stack>
            ) : (
              <Stack>
                <Text mt="4">Pilih metode pembayaran :</Text>
                <RadioGroup
                  value={selectedPaymentMethod}
                  onChange={handlePaymentMethodSelect}
                  mt="2"
                >
                  <SimpleGrid py={2} columns={[2, null, 4]} align="start">
                    {banks?.map((x, index) => (
                      <Radio key={index} value={x.name}>
                        <Image src={x.uri} w="70px" />
                      </Radio>
                    ))}
                  </SimpleGrid>
                </RadioGroup>

                {!loading ?
                  <Button  colorScheme="green"
                  onClick={() => handleOrderPayConfirm()}>Bayar</Button>
                  :
                  <Button isLoading colorScheme="green"
                  onClick={() => handleOrderPayConfirm()}>Bayar</Button>

                }
              </Stack>
            )}
          </Container>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default OrderCustomerPayDrawer;
