import React from 'react'
import EditMenuPage from '../Pages/Menus/EditMenuPage'
import MenusPage from '../Pages/Menus/MenusPage'

const MenusRouter = [
	{
		path: "/menus",
		element: <MenusPage />
	},
	{
		path: "/menus/:id/edit",
		element: <EditMenuPage />
	},

]

export default MenusRouter