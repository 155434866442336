import { Divider, Grid, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { FiMail, FiSend, FiUsers } from 'react-icons/fi'
import ChartsLineOrder from '../../Components/Charts/ChartsLineOrder'
import ChartsPieOrder from '../../Components/Charts/ChartsPieOrder'
import { StatsWithIconComponent } from '../../Components/Stats/StatsWithIconComponent'

function DashboardPage() {


	return (
		<Stack p={[1, 1, 5]} spacing={10}  minH='100vh' >
			<Stack>
				<Text fontWeight={'bold'} fontSize='3xl'>🚀 Welcome to Deoapp RMS</Text>
				<Text >Make your business restaurant more efficient with the help from Restaurant Management System (RMS).</Text>
				<Divider />
			</Stack>

			<Stack w='full'>
				<Grid templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={3}>

					<Stack
					overflow={'auto'}
						spacing={2}
						borderTopWidth={3}
						borderBottomWidth={3}
						borderColor="green.500"
						py={4}
						px={2}
						borderRadius="md"
						shadow="md"
					>
						<ChartsPieOrder />
					</Stack>

					<Stack
					overflow={'auto'}
						spacing={2}
						borderTopWidth={3}
						borderBottomWidth={3}
						borderColor="green.500"
						py={4}
						px={2}
						borderRadius="md"
						shadow="md"
					>
						<ChartsLineOrder  />
					</Stack>

				</Grid>
			</Stack>





		</Stack>
	)
}

export default DashboardPage