import React from 'react'
import ReportsDetailPage from '../Pages/Report/ReportsDetailPage'
import ReportsPage from '../Pages/Report/ReportsPage'

const ReportsRouter = [
	{
		path: "/reports",
		element: <ReportsPage />
	},

	{
		path: "/reports/:id",
		element: <ReportsDetailPage />
	},

	// {
	// 	path: "/menus/:id/edit",
	// 	element: <EditMenuPage />
	// },

]

export default ReportsRouter