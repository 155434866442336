import { Box, Button, Flex, Heading, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FcPlus } from 'react-icons/fc';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { addEdge, useEdgesState } from 'reactflow';


import 'reactflow/dist/style.css';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/Zustand/Store';


const initialNodes = [
	{ id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
	{ id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
];
const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];


function TablesPage() {
	const [setEdges] = useEdgesState(initialEdges);
	const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

	const [data, setData] = useState([])
	const [addModal, setAddModal] = useState(false)
	const [tableInput, setTableInput] = useState(0)
	const [tableAmount, setTableAmount] = useState(20)

	const globalState = useUserStore();

	const param = useParams()

	const getBarcode = () => {
		const data = [];
		const outletId = param.id
		const numberOfTables = tableAmount; // Jumlah tabel yang diinginkan

		for (let table = 1; table <= numberOfTables; table++) {
			data.push({ table, outletId });
		}

		setData(data);
	};

	const handleTableBarcode = () => {
		const data = [];
		const outletId = param.id
		const numberOfTables = tableInput; // Jumlah tabel yang diinginkan

		for (let table = 1; table <= numberOfTables; table++) {
			data.push({ table, outletId });
		}
		setData(data);
		setAddModal(false)
	}

	useEffect(() => {
		getBarcode();

		return () => {
			// Cleanup function
		};
	}, []);



	return (
		<Stack p={[1, 1, 5]} spacing={10}>


			<HStack>
				<BackButtons />
				<Spacer />

				<Heading size={'md'}>
					Tables Barcode
				</Heading>
				<Stack>
					<Button onClick={() => setAddModal(true)} bgColor={'white'} shadow='md' variant='outline' borderColor='#F05A28' color='#F05A28'>
						<HStack>
							<FcPlus />
							<Text>Table</Text>
						</HStack>
					</Button>
				</Stack>
			</HStack>
			<SimpleGrid columns={[2, 2, 4]} gap={5}>
				{data.map((x, index) =>
					<Stack key={index} spacing={2} alignItems='center' justifyContent={'center'}>
						<Heading size={'md'}>
							Table : {x.table}
						</Heading>
						<QRCode
							size={256}
							style={{ height: "auto", maxWidth: "100px" }}
							value={`https://rms.deoapp.com/orders/${x.outletId}/${x.table}`}
							viewBox={`0 0 256 256`}
						/>

					</Stack>

				)}
			</SimpleGrid>

			<Modal isOpen={addModal} onClose={() => setAddModal(false)}>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>Table</ModalHeader>

					<ModalCloseButton />

					<ModalBody>
						<Input
							type="text"
							placeholder="Table Amount"
							size={'sm'}
							onChange={(e) => setTableInput(e.target.value)}
						/>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={() => handleTableBarcode()}>
							Add
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Stack>
	)
}

export default TablesPage