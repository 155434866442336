import { Button, Checkbox, FormControl, FormLabel, Input, Select, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import React, { useState } from 'react'
import { auth } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';

function NewUserPage() {

	const globalState = useUserStore();

    const [data, setData] = useState({});


    const handleRegister = () => {
        const passwordDefault = data.email
        try {
        createUserWithEmailAndPassword(auth, data.email, passwordDefault).then(async (userCredential) => {
						await updateProfile(auth.currentUser, {
							displayName: data.name,
						});
						sendEmailVerification(auth.currentUser);

                        const user = userCredential.user;
                        console.log(user, 'ini user')
                    
                    
                    })
            
        } catch (error) {
            console.log(error, 'ini error')
        }

    }


    return (
        <Stack>
            <Text fontWeight={500} fontSize='md'>New User</Text>
            <Stack>
                <FormControl mb={3} isRequired>
                    <FormLabel>Name</FormLabel>

                    <Input
                        placeholder="Name"
                        isInvalid={data?.name?.length === 0}
                        errorBorderColor="crimson"
                        value={data?.name}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                    />
                </FormControl>

                <FormControl mb={3} isRequired>
                    <FormLabel>Email</FormLabel>

                    <Input
                        placeholder="Email"
                        isInvalid={data?.email?.length === 0}
                        errorBorderColor="crimson"
                        value={data?.email}
                        onChange={(e) => setData({ ...data, email: e.target.value })}
                    />
                </FormControl>

                <FormControl mb={3} isRequired>
                    <FormLabel>Phone Number</FormLabel>

                    <Input
                        placeholder="Phone Number"
                        isInvalid={data?.name?.length === 0}
                        errorBorderColor="crimson"
                        value={data?.phoneNumber}
                        onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
                    />
                </FormControl>

                <SimpleGrid columns={3}>
                    {globalState?.projects?.length > 0 ? (
                        globalState?.projects?.map((x, i) => {
                            return (
                                <Checkbox
                                    key={i}
                                    value={x.id}
                                    isChecked={
                                        data?.projects?.find((e) => e === x.id) ? true : false
                                    }
                                    onChange={(e) => {
                                        data?.projects === undefined
                                            ? setData({
                                                ...data,
                                                projects: [e.target.value],
                                            })
                                            : data?.projects?.find((e) => e === x.id)
                                                ? setData({
                                                    ...data,
                                                    projects: [...data.projects, e.target.value].filter(
                                                        (item) => item !== x.id
                                                    ),
                                                })
                                                : setData({
                                                    ...data,
                                                    projects: [...data.projects, e.target.value],
                                                });
                                    }}
                                >
                                    {x.name}
                                </Checkbox>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </SimpleGrid>

                <FormControl mb={3}>
                    <FormLabel>Type Price</FormLabel>
                    <Select
                        m="0"
                        isInvalid={data?.price_type?.length === 0}
                        placeholder="Role"
                        value={data?.price_type}
                        onChange={(e) =>
                            setData({ ...data, role: e.target.value })
                        }
                    >
                        <option value={"manager"}>
                            <Text textTransform={"capitalize"}>Manager</Text>
                        </option>
                        <option value={"user"}>
                            <Text textTransform={"capitalize"}>User</Text>
                        </option>
                    </Select>
                </FormControl>

                <Button onClick={() => handleRegister()}>Register</Button>

            </Stack>
        </Stack>
    )
}

export default NewUserPage