import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  Text,
  SimpleGrid,
  Button,
  Box,
  FormLabel,
  Input,
  Select,
  Textarea,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  Image,
} from '@chakra-ui/react';
import { MdOutlinePermMedia } from 'react-icons/md';

const DrawerStock = ({
  isOpen,
  onClose,
  data,
  handleCategoryClick,
  setData,
  loading,
  handleAddMenu,
  files,
  handleFileInputChange,
  category,
  cat
}) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">New Item</DrawerHeader>
        <DrawerBody>
          <Stack spacing={5}>
          <Text>Type</Text>
                <SimpleGrid wrap={"wrap"} columns={[1, 1, 2]} gap={2}>

                  <Button
                    colorScheme={'blue'}
                    textTransform='capitalize'
                    value={"makanan"}
                    w={"auto"}
                    size='sm'
                    onChange={(e) =>
                      setData({ ...data, category: e.target.value })
                    }
                    variant={category === "makanan" ? "solid" : "outline"}
                    onClick={() => handleCategoryClick("makanan")}
                  >
                    makanan
                  </Button>


                  <Button
                    colorScheme={'blue'}
                    textTransform='capitalize'
                    value={"minuman"}
                    w={"auto"}
                    size='sm'

                    onChange={(e) =>
                      setData({ ...data, category: e.target.value })
                    }
                    variant={category === "minuman" ? "solid" : "outline"}
                    onClick={() => handleCategoryClick("minuman")}
                  >
                    minuman
                  </Button>

                  <Button
                    size='sm'
                    colorScheme={'blue'}
                    textTransform='capitalize'
                    value={"bumbu"}
                    w={"auto"}
                    onChange={(e) =>
                      setData({ ...data, category: e.target.value })
                    }
                    variant={category === "bumbu" ? "solid" : "outline"}
                    onClick={() => handleCategoryClick("bumbu")}
                  >
                    bumbu
                  </Button>

                  <Button
                    size='sm'
                    colorScheme={'blue'}
                    textTransform='capitalize'
                    value={"perlengakapan makan"}
                    w={"auto"}
                    onChange={(e) =>
                      setData({ ...data, category: e.target.value })
                    }
                    variant={
                      category === "perlengkapan makan" ? "solid" : "outline"
                    }
                    onClick={() => handleCategoryClick("perlengakapan makan")}
                  >
                    per. makan
                  </Button>
                  <Button
                    size='sm'

                    colorScheme={'blue'}
                    textTransform='capitalize'
                    value={"perlengkapan minum"}
                    w={"auto"}
                    onChange={(e) =>
                      setData({ ...data, category: e.target.value })
                    }
                    variant={
                      category === "perlengkapan minum" ? "solid" : "outline"
                    }
                    onClick={() => handleCategoryClick("perlengkapan minum")}
                  >
                    per. minum
                  </Button>

                  <Button
                    size='sm'
                    colorScheme={'blue'}
                    textTransform='capitalize'
                    value={"alat makan"}
                    w={"auto"}
                    onChange={(e) =>
                      setData({ ...data, category: e.target.value })
                    }
                    variant={category === "alat makan" ? "solid" : "outline"}
                    onClick={() => handleCategoryClick("alat makan")}
                  >
                    alat makan
                  </Button>

                </SimpleGrid>

                <Box>
                  <FormLabel htmlFor="username">Name</FormLabel>
                  <Input
                    type="text"
                    size={'sm'}
                    borderRadius='md'
                    placeholder="Item name"
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Box>

                <Box>
                  <FormLabel htmlFor="username">Supplier</FormLabel>
                  <Select
                    size={'sm'}
                    borderRadius='md'
                    placeholder="Select option"
                    onChange={(e) =>
                      setData({ ...data, supplier: e.target.value })
                    }
                  >
                    {cat.map((k, index) => (
                      <option key={index} value={k.name}>
                        {k.name}
                      </option>
                    ))}
                  </Select>
                </Box>

                <Box>
                  <FormLabel htmlFor="desc">Description</FormLabel>
                  <Textarea
                    id="desc"
                    size={'sm'}
                    borderRadius='md'
                    placeholder="Item name"
                    type="text"
                    onChange={(e) =>
                      setData({ ...data, description: e.target.value })
                    }
                  />
                </Box>

                <FormControl>
                  <FormLabel>Price</FormLabel>
                  <NumberInput
                    min={10}
                    size={'sm'}
                    borderRadius='md'
                    placeholder="Item name"
                    onChange={(valueString) =>
                      setData({ ...data, price: valueString })
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>

                <FormControl>
                  <FormLabel>Stock</FormLabel>
                  <NumberInput
                    size={'sm'}
                    borderRadius='md'
                    placeholder="Item name"
                    min={10}
                    onChange={(valueString) =>
                      setData({ ...data, stock: parseInt(valueString) })
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>

                <Box>
                  <FormLabel htmlFor="username">Type amount</FormLabel>
                  <Select
                    size={'sm'}
                    borderRadius='md'
                    placeholder="Type amount"
                    onChange={(e) => setData({ ...data, quantity_type: e.target.value })}
                  >
                    <option value={"qty"}>Quantity</option>
                    <option value={"gram"}>Gram</option>
                    <option value={"liter"}>Liter</option>
                  </Select>
                </Box>

                <Box>
                  <HStack>
                    {files.length > 0 ? (
                      <Stack>
                        <Image
                          src={files[0].file}
                          boxSize="100%"
                          maxWidth={300}
                          borderRadius="xl"
                          alt={files[0].name}
                          shadow="sm"
                        />
                      </Stack>
                    ) : (
                      <Image
                        boxSize="100%"
                        src={"https://bit.ly/dan-abramov"}
                        alt="Dan Abramov"
                      />
                    )}
                  </HStack>

                  <Stack>
                    <Input
                      type="file"
                      onChange={handleFileInputChange}
                      display="none"
                      id="fileInput"
                    />

                    <label htmlFor="fileInput">
                      <HStack cursor={"pointer"}>
                        <Stack>
                          <MdOutlinePermMedia />
                        </Stack>
                        <Text
                          fontSize={"sm"}
                          color="blue.600"
                          fontStyle={"italic"}
                        >
                          Add Image
                        </Text>
                      </HStack>
                    </label>
                  </Stack>
                </Box>
          </Stack>
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            isLoading={loading}
            onClick={handleAddMenu}
          >
            Submit
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerStock;
