import {
    Badge,
    HStack,
    IconButton,
    Image,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import useUserStore from "../../Hooks/Zustand/Store";
import TransactionDetailModal from "../../Pages/Transaction/TransactionDetailModal";
import { getStoreName } from "../../Utils/nameUtil";
import { formatFrice } from "../../Utils/numberUtil";

export const TransactionTable = (props) => {
    const data = props.data;

	const globalState = useUserStore();


    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isDetailOpen, setDetailOpen] = useState(false);

    const openDetailModal = (order) => {
        setSelectedOrder(order);
        setDetailOpen(true);
    };

    const closeDetailModal = () => {
        setSelectedOrder(null);
        setDetailOpen(false);
    };

    return (
        <>
            <Table {...props} variant="striped" bgcolor="white" shadow={"md"}>
                <Thead>
                    <Tr>
                        <Th>Created Date</Th>
                        <Th>Transaction</Th>
                        <Th>Image</Th>
                        <Th>Transaction Value</Th>
                        <Th>Payment Status</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((x, index) => {
                        const storeName = getStoreName(globalState, x.outlet);

                        return (

                            <Tr key={index}>
                                <Td fontSize="2xs">
                                    <Text fontWeight={500}>
                                        {moment(x?.createdAt?.seconds * 1000).format(
                                            "MMMM Do YYYY"
                                        )}
                                    </Text>
                                    <Text fontWeight={500}>
                                        {moment(x?.createdAt?.seconds * 1000).format("h:mm:ss a")}
                                    </Text>
                                </Td>

                                <Td>
                                    <Text fontSize='sm' fontWeight={500}>{x?.on_report_person}</Text>
                                    <Text textTransform={'capitalize'} fontSize="2xs">Outlets: {storeName}</Text>
                                    <Text textTransform={'capitalize'} fontSize="2xs">Notes: {x?.notes}</Text>
                                </Td>

                                <Td>
                                    <Stack>
                                        <Image
                                            src={x?.image_url}
                                            w={'300px'}
                                            fallbackSrc="https://via.placeholder.com/150"
                                        />
                                    </Stack>
                                </Td>

                                <Td>
                                    <HStack>
                                        <Text color="muted" fontSize={"sm"} textTransform='uppercase' fontWeight={500}>
                                            {x.paymentMethod}
                                        </Text>

                                        <Text color="muted" fontSize={"sm"} textTransform='uppercase' fontWeight={500}>
                                            {x.type}
                                        </Text>
                                    </HStack>
                                    <Text color={x.type === "outcome" ? "red.500" : "green.500"} fontSize={"sm"} fontWeight={500}>
                                        Rp.
                                        {formatFrice(x.amount)}
                                    </Text>
                                </Td>

                                <Td>
                                    <Stack>
                                        {x?.shift && (
                                            <Badge
                                                textAlign={'center'}
                                                variant="solid"
                                                borderRadius="md"
                                                p={1}
                                                colorScheme={
                                                    "green"
                                                }
                                            >
                                                {x?.shift}
                                            </Badge>
                                        )}

                                        <Badge textAlign={'center'}
                                            variant="solid" colorScheme={x.assignment_status === "pending" ? "yellow" : x.assignment_status === "rejected" ? "red" : "green"} p={1} borderRadius='md'>{(x.assignment_status)}</Badge>
                                    </Stack>
                                </Td>



                                <Td>
                                    <HStack spacing="1">
                                        <IconButton
                                            icon={<FiEdit2 fontSize="1.25rem" />}
                                            variant="ghost"
                                            aria-label="Edit member"
                                            onClick={() => openDetailModal(x)}
                                        />
                                    </HStack>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>

            <TransactionDetailModal
                order={selectedOrder}
                isOpen={isDetailOpen}
                onClose={closeDetailModal}
            />
        </>
    );
};
