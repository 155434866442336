import {
  Box,
  Button,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Spacer,
} from "@chakra-ui/react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { setDocumentFirebase } from "../../Apis/firebaseApi";
import BackButtons from "../../Components/Buttons/BackButtons";
import { db } from "../../Config/firebase";
import useUserStore from "../../Hooks/Zustand/Store";

function StationViewServerPage() {
  const param = useParams();
	const globalState = useUserStore();
  const [data, setData] = useState();
  const [filterData, setFilterData] = useState();

  const updateOrderStatus = (newArr) => {
    const servingOrders = newArr.orders.filter((order) => order.status === "serving");
    const finishedOrders = newArr.orders.filter((order) => order.status === "finished");
  
    let updatedOrderStatus = newArr.orderStatus;
  
    if (servingOrders.length === newArr.orders.length) {
      updatedOrderStatus = "served";
    } else if (finishedOrders.length === newArr.orders.length) {
      updatedOrderStatus = "done";
    }
  
    return {
      ...newArr,
      orderStatus: updatedOrderStatus,
    };
  };

  const handleUpdate = (parentIndex, childIndex, status) => {
    if (status) {
      const newArr = data[parentIndex];
      newArr.orders[childIndex].status = status;
  
      const updatedArr = updateOrderStatus(newArr);
  
      // Tambahkan logika untuk memperbarui data ke Firebase
      setDocumentFirebase("orders", updatedArr.id, updatedArr)
        .then((x) => console.log(x))
        .catch((e) => console.log(e.message));
    }
  };

  const filteringData = (filterKey) => {
    let result = [];
    let status = "";

    // if (filterKey === "newOrder") {
    //   result = filterData.filter((y) => y.status === undefined);
    //   status = "onProgress";
    // } else if (filterKey === "onProgress") {
    //   result = filterData.filter((y) => y.status === "onProgress");
    //   status = "ready";
    // } else 
    if (filterKey === "ready") {
      result = filterData.filter((y) => y.status === "ready" ||  y.status === undefined && y.stations.includes("server") );
      status = "serving";
    } else if (filterKey === "serving") {
      result = filterData.filter((y) => y.status === "serving");
      status = "finished";
    } else {
      result = filterData.filter((y) => y.status === "serving");
    }

    // console.log(result, filterKey);
    result = result.sort(
      (a, b) => a?.updated_bill?.seconds - b?.updated_bill?.seconds
    );
    return result.map((x, i) => {
      const currentTime = moment();
      const createdAtTime = moment.unix(x?.updated_bill?.seconds);
      const timeDifference = currentTime.diff(createdAtTime, "minutes");

      let bgColor = "";
      if (timeDifference >= 20 && timeDifference < 30) {
        bgColor = "yellow.200";
      } else if (timeDifference >= 30) {
        bgColor = "red.200";
      } else if (
        x.paymentStatus === "cancel" ||
        x.paymentStatus === "refund" ||
        x.paymentStatus === "open"
      ) {
        bgColor = "gray.200";
      } else {
        bgColor = "green.100";
      }

      return (
        <Box
          w="100%"
          mt={5}
          p="5"
          borderRadius="xl"
          shadow="md"
          bgColor={bgColor}
        >
          <Popover>
            <Stack>
              <Text fontWeight={"bold"} textAlign="center">
                Table # {x.table}
              </Text>

              <Text fontWeight={"bold"} textAlign="center">
                {x.title}
              </Text>

              <Text>Quantity : {x.quantity} </Text>

              {x.price_type && (
                <HStack>
                  <Text>Type : </Text>
                  <Text fontWeight={"bold"}>{x.price_type}</Text>
                </HStack>
              )}

              {x.variant && (
                <HStack>
                  <Text>Variant : </Text>
                  <Text fontWeight={"bold"}>{x.variant}</Text>
                </HStack>
              )}

              {x.notes && (
                <HStack>
                  <Text>Notes : </Text>
                  <Text fontWeight={"bold"}>{x.notes}</Text>
                </HStack>
              )}

              {x.paymentStatus && (
                <HStack>
                  <Text>Status : </Text>
                  <Text fontWeight={"bold"}>{x.paymentStatus}</Text>
                </HStack>
              )}

              <Text>
                Order Date:{" "}
                {moment.unix(x?.createdAt?.seconds).format("hh:mm:ss")}
              </Text>
              <Text>
                Bill Date:{" "}
                {moment.unix(x?.updated_bill?.seconds).format("hh:mm:ss")}
              </Text>
            </Stack>

            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Semua Pesanan Table # {x.table} </PopoverHeader>
              <PopoverBody>
                {filterData.map(
                  (datas) =>

                    x.table === datas.table &&
                    datas.status !== "finished" && (
                      <HStack key={datas.id}>
                        <Text >{datas.title}</Text>
                        <Spacer />
                        <Text>{datas.quantity}</Text>
                      </HStack>
                    )
                )}
              </PopoverBody>
            </PopoverContent>

            <Button
              w="full"
              mt={5}
              colorScheme="orange"
              backgroundColor="#F05A28"
              color="white"
              borderRadius="lg"
              onClick={() => handleUpdate(x.parentIndex, x.indexChild, status)}
            >
              Next Step
            </Button>

            <PopoverTrigger>
              <Button
                w="full"
                mt={3}
                colorScheme="orange"
                backgroundColor="#F05A28"
                color="white"
                borderRadius="lg"
              >
                Check Menu
              </Button>
            </PopoverTrigger>
          </Popover>
        </Box>
      );
    });
  };

  useEffect(() => {
    let collectionRef = collection(db, "orders");
    collectionRef = query(
      collectionRef,
      where("orderStatus", "!=", "finished")
    );
    collectionRef = query(
      collectionRef,
      where("outlet", "==", globalState?.currentOutlet)
    );

    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const newData = [];
      const filteredData = [];
      let parentIndex = 0;
      querySnapshot.forEach((doc, i) => {
        const dataQ = doc.data();
        const parentId = doc.id;
        newData.push({ id: parentId, ...dataQ });

        // if (dataQ?.paymentStatus !== "open") {
          dataQ?.orders.map((y, j) => {
            filteredData.push({
              ...y,
              createdAt: dataQ.createdAt,
              updated_bill: dataQ.updated_bill,
              parentId: parentId,
              indexChild: j,
              parentIndex: parentIndex,
              table: dataQ.table,
              paymentStatus: dataQ?.paymentStatus,
            });
            return {};
          });
        // }

        parentIndex++;
      });
      setData(newData);
      setFilterData(filteredData);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.currentCompany]);

  return (
    <Box sx={{ width: "100%" }}>
      <Flex direction={["column", "column","row"]} gap={5} justifyContent="space-between" mb={5} mt={5}>
        <BackButtons />
    <Spacer />
        <Heading as="h3" size="lg">
          Station - SERVER
        </Heading>
      </Flex>

      <hr />

      <SimpleGrid columns={["1" , "2", "2"]} gap="2" p={1} mt={5}>
        {/* <Box
          border="1px"
          borderWidth="1px"
          borderRadius="xl"
          borderColor="#F05A28"
          p={5}
          shadow="md"
        >
          <Heading as="h3" size="lg" textAlign="center" mb={3}>
            New Order
          </Heading>

          <hr />

          {data ? filteringData("newOrder") : <></>}
        </Box> */}

        {/* <Box
          border="1px"
          borderWidth="1px"
          borderRadius="xl"
          borderColor="#F05A28"
          p={5}
          shadow="md"
        >
          <Heading as="h3" size="lg" textAlign="center" mb={3}>
            On Progress
          </Heading>

          <hr />

          {data ? filteringData("onProgress") : <></>}
        </Box> */}

        <Box
          border="1px"
          borderWidth="1px"
          borderRadius="xl"
          borderColor="#F05A28"
          p={5}
          shadow="md"
          maxH={'650px'}
          overflowY='scroll'
        >
          <Heading as="h3" size="lg" textAlign="center" mb={3}>
            Ready to serve
          </Heading>

          <hr />

          {data ? filteringData("ready" || "server") : <></>}
        </Box>

        <Box
          border="1px"
          borderWidth="1px"
          borderRadius="xl"
          borderColor="#F05A28"
          p={5}
          shadow="md"
          maxH={'650px'}
          overflowY='scroll'
        >
          <Heading as="h3" size="lg" textAlign="center" mb={3}>
            Served
          </Heading>

          <hr />
          {data ? filteringData("serving") : <></>}
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default StationViewServerPage;
