import React from 'react';
import { Stack, Text, SimpleGrid, AvatarGroup, Avatar, Button, HStack } from '@chakra-ui/react';
import { FcPlus } from 'react-icons/fc';

const OutletCard = ({ outletData, handleOpenModal, handleOpenModalOutlet }) => {
  return (
    <Stack p={[1, 1, 5]}>
      <Text fontWeight={'bold'} fontSize='xl'>
        Outlets
      </Text>
      {outletData.length > 0 && (
        <SimpleGrid columns={[1, 2, 4]} gap={3}>
          {outletData.map((x, index) => (
            <Stack key={index} bgColor='white' p={4} borderRadius={'lg'} shadow='md'>
              <Text textTransform={'capitalize'} fontWeight={500}>
                {x?.name}
              </Text>
              <Text>Managers: {x?.managers?.length}</Text>
              <AvatarGroup size='sm' gap='1' max={4}>
                {x?.managers?.length > 0 &&
                  x?.managers.map((y, i) => {
                    const user = x.usersOutletData.find((userData) => userData.id === y);
                    return <Avatar key={i} name={user?.name} />;
                  })}
              </AvatarGroup>
              <Text>Users: {x?.usersOutletData?.length}</Text>
              <AvatarGroup size='sm' gap='1' max={4}>
                {x?.usersOutletData?.length > 0 &&
                  x?.usersOutletData.map((y, i) => <Avatar key={i} name={y?.name} />)}
              </AvatarGroup>
              <Stack>
                <Button
                  bgColor={'white'}
                  onClick={() => handleOpenModalOutlet(x)}
                  shadow='md'
                  variant='outline'
                  borderColor='#F05A28'
                  color='#F05A28'
                  size={'sm'}
                >
                  <HStack>
                    <Text>Lihat Team</Text>
                  </HStack>
                </Button>

                <Button
                  bgColor={'white'}
                  onClick={() => handleOpenModal(x)}
                  shadow='md'
                  variant='outline'
                  borderColor='#F05A28'
                  color='#F05A28'
                  size={'sm'}
                >
                  <HStack>
                    <FcPlus />
                    <Text>Team</Text>
                  </HStack>
                </Button>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      )}
    </Stack>
  );
};

export default OutletCard;
