import React from 'react'
import TablesPage from '../Pages/Tables/TablesPage'

const TablesRouter = [
	{
		path: "/tables/:id",
		element: <TablesPage />
	},

	// {
	// 	path: "/dashboard",
	// 	element: <ExamplePage />
	// },
	// {
	// 	path: "/dashboard/:id",
	// 	element: <DashboardPage />
	// }
]

export default TablesRouter