import {
    Badge,
    Box,
    Button,
    HStack,
    IconButton,
    Stack,
    Table,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useState, useEffect, useRef } from "react";
import { FiEdit2 } from "react-icons/fi";
import OrderRefundDetailModal from "../../Pages/Orders/OrderRefundDetailModal";
import OrderRefundDetailPaymentModal from "../../Pages/Orders/OrderRefundDetailPaymentModal";
import { formatFrice } from "../../Utils/numberUtil";

export const RefundTable = (props) => {
    const data = props.data;


    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isInvoiceOpen, setInvoiceOpen] = useState(false);
    const [isInvoiceRefundOpen, setInvoiceRefundOpen] = useState(false);

    const openDetailModal = (order) => {
        setSelectedOrder(order);
        setInvoiceRefundOpen(true);
    };

    const closeDetailModal = () => {
        setSelectedOrder(null);
        setInvoiceRefundOpen(false);
    };

    const openInvoiceModal = (order) => {
        setSelectedOrder(order);
        setInvoiceOpen(true);
    };

    const closeInvoiceModal = () => {
        setSelectedOrder(null);
        setInvoiceOpen(false);
    };

    return (
        <Box overflowX={'auto'}>
            <Table mb={10} variant="striped" bgcolor="white" shadow={"md"}>
                <Thead>
                    <Tr>
                        <Th>Updated Date</Th>
                        <Th>Order</Th>
                        <Th>Table</Th>
                        <Th>Status</Th>
                        <Th>Order Value Refund</Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {data.map((data) => (
                        <Tr key={data.id}>
                            <Td fontSize="2xs">
                                <Text fontWeight={500}>
                                    {moment(data?.lastUpdated?.seconds * 1000).format(
                                        "MMMM Do YYYY"
                                    )}
                                </Text>
                                <Text fontWeight={500}>
                                    {moment(data?.lastUpdated?.seconds * 1000).format(
                                        "h:mm:ss a"
                                    )}
                                </Text>
                            </Td>

                            <Td>
                                <Text fontWeight={500} fontSize="sm">
                                    {data.name}
                                </Text>
                                <Text fontSize="2xs">Order ID: {data.id}</Text>
                            </Td>

                            <Td>
                                <Text fontWeight={500}>#{data.table ? data.table : 0}</Text>
                            </Td>

                            <Td>
                                <Stack>
                                    {data.on_report_at !== undefined && (
                                        <Badge
                                            colorScheme={"green"}
                                            p={1} textAlign='center' variant={'solid'} borderRadius='md'
                                        >
                                            {data.on_report_shift}
                                        </Badge>
                                    )}
                                    <Badge
                                        p={1} textAlign='center' variant={'solid'} borderRadius='md'
                                        colorScheme={
                                            data.paymentRefundStatus === "complete" ? "green"
                                                : "red"
                                        }
                                    >
                                        {data?.paymentRefundStatus ? data?.paymentRefundStatus : "open"}
                                    </Badge>
                                </Stack>
                            </Td>

                            <Td>
                                <Text color="muted" fontSize={"sm"}>
                                    {data?.order_refund?.length} Menu/{data.paymentStatus}
                                </Text>
                                <Text color="muted" fontSize={"sm"} fontWeight={500}>
                                    Rp.
                                    {formatFrice(data.totalPrice)}
                                </Text>
                            </Td>

                            <Td>
                                <HStack spacing="1">
                                    <IconButton
                                        icon={<FiEdit2 fontSize="1.25rem" />}
                                        variant="ghost"
                                        aria-label="Edit member"
                                        onClick={() => openDetailModal(data)}
                                    />
                                    {!data.paymentRefundStatus && (
                                        <Button
                                            size={"sm"}
                                            colorScheme={"green"}
                                            onClick={() => openInvoiceModal(data)}
                                        >
                                            Pay
                                        </Button>
                                    )}

                                </HStack>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            <OrderRefundDetailModal
                order={selectedOrder}
                isOpen={isInvoiceRefundOpen}
                onClose={closeDetailModal}
            />


            <OrderRefundDetailPaymentModal
                order={selectedOrder}
                isOpen={isInvoiceOpen}
                onClose={closeInvoiceModal}
            />
        </Box>
    );
};
