import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  FormErrorMessage,
  useToast,
  InputGroup,
  Spacer,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdOutlinePermMedia } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCollectionFirebase,
  getCollectionFirebaseV2,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  uploadFile,
} from "../../Apis/firebaseApi";
import BackButtons from "../../Components/Buttons/BackButtons";
import { CheckIcon } from "@chakra-ui/icons";
import useUserStore from "../../Hooks/Zustand/Store";
import { FcPlus } from "react-icons/fc";
import { AiOutlineCheck, AiOutlineCloseCircle } from "react-icons/ai";

function EditMenuPage() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [stationsData, setStationsData] = useState();
  const [outLetsData, setOutletsData] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const [prices, setPrices] = useState([]);
  const globalState = useUserStore();
  const param = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [selectedItems, setSelectedItems] = useState([]); // State untuk menyimpan item yang dipilih
  const [selectedItemId, setSelectedItemId] = useState(null); // State untuk menyimpan ID item yang dipilih
  const [amountInput, setAmountInput] = useState('');

  const itemsPerPage = 2;

  const [startIndex, setStartIndex] = useState(0);

  const [items, setItems] = useState([]); // State untuk menyimpan data items


  const categoryData = [
    "makanan",
    "minuman",
    "sayur",
    "paket",
    "tambahan",
    "lainnya",
  ];

  const validateForm = () => {
    if (data?.title.length === 0) {
      toast({
        title: "Error",
        description: "Masukan Nama Menu",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.category === undefined) {
      toast({
        title: "Error",
        description: "Pilih Category",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.variant_type === undefined) {
      toast({
        title: "Error",
        description: "Pilih Type Variant",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.price_type === undefined) {
      toast({
        title: "Error",
        description: "Pilih Price Type",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.category === undefined) {
      toast({
        title: "Error",
        description: "Masukan Kategori",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.stations === undefined) {
      toast({
        title: "Error",
        description: "Pilih Station",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.outlets === undefined) {
      toast({
        title: "Error",
        description: "Pilih Outlete",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.tax === undefined) {
      toast({
        title: "Error",
        description: "Masukan Pajak",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (data?.stock === undefined) {
      toast({
        title: "Error",
        description: "Masukan Stock",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      handleSave();
    }
  };

  const handleSave = async () => {

    if (data.price_type === "multiple") {
      data.prices = prices.length > 0 ? prices : data.prices;
    }

    if (filesImage[0]) {
      const resImage = await uploadFile(data.title, "menus", filesImage[0]);
      data.image_url = resImage;
    }

    if(selectedItems.length > 0){
      data.items = selectedItems
    }
    data.companyId = globalState.currentCompany;
    data.projectId = globalState.currentProject;
    setDocumentFirebase("menus", param.id, data);
    navigate(-1);
    toast({
      title: "Success",
      description: "Menu sudah di buat dan di tambahkan",
      position: "top",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;
    if (newFiles.length) {
      const newFileArray = [...files];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);
        };
      }
      setFilesImage(newFiles); // Mengubah state filesImage menjadi array baru dari selectedFiles
    }
  };

  const getdata = () => {
    getSingleDocumentFirebase("rms", globalState.currentProject).then((x) => {
      setStationsData(x.stations);
    }).catch((err) => console.log(err))

    getSingleDocumentFirebase("menus", param.id).then((x) => {
      setData(x);
      if(x.items){
        setSelectedItems(x.items)
      }
    }).catch((err) => console.log(err));

    getCollectionFirebase("outlets", [
      {
        field: "companyId",
        operator: "==",
        value: globalState.currentCompany,
      },
      {
        field: "projectId",
        operator: "==",
        value: globalState.currentProject,
      },
    ]).then((x) => setOutletsData(x)).catch((err) => console.log(err));


  };

  const getDataWarehouse = async () => {
    const conditions = [
      { field: "companyId", operator: "==", value: globalState.currentCompany },
      { field: "projectId", operator: "==", value: globalState.currentProject },
      { field: "outletId", operator: "==", value: globalState.currentOutlet },
    ];
    const sortBy = { field: "createdAt", direction: "desc" };

    const limitValue = startIndex + itemsPerPage

    try {
      const res = await getCollectionFirebaseV2(
        "warehouse",
        conditions,
        sortBy,
        limitValue,
      );
      setItemList(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  }

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };


  useEffect(() => {
    getDataWarehouse()

    return () => {
    }
  }, [globalState.currentOutlet, startIndex])


  useEffect(() => {
    if (globalState.currentProject) {
      getdata()
      getDataWarehouse()
    }

    return () => {
      setData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.currentProject]);

  const handlePriceChange = (index, value) => {
    const updatedPrices = [...prices];
    updatedPrices[index] = value;
    setPrices(updatedPrices);
  };

  // Fungsi untuk menambah/menghapus item yang dipilih
  const toggleSelectedItem = (itemId) => {
    if (selectedItemId === itemId) {
      // Jika item yang sama dipilih kembali, hapus pemilihan
      setSelectedItemId(null);
    } else {
      // Jika item berbeda dipilih, simpan ID item yang dipilih
      setSelectedItemId(itemId);
    }
  };

  // Fungsi untuk menambahkan item ke dalam daftar yang dipilih dengan jumlah yang dimasukkan
  const addToSelectedItems = () => {
    if (selectedItemId !== null && amountInput !== '') {
      // Cek apakah item dengan ID yang sama sudah ada dalam daftar yang dipilih
      const isItemAlreadySelected = selectedItems.some((item) => item.id === selectedItemId);
      if (!isItemAlreadySelected) {
        const selectedItem = itemList.find((item) => item.id === selectedItemId);
        if (selectedItem) {
          setSelectedItems([...selectedItems, { id: selectedItemId, name: selectedItem.name, amount: parseFloat(amountInput) }]);
          setAmountInput(''); // Reset input jumlah setelah menambahkan item
          setSelectedItemId(null); // Reset pemilihan item
        }
      } else {
        // Tampilkan pesan kesalahan jika item dengan ID yang sama sudah ada dalam daftar yang dipilih
        toast({
          title: "Error",
          description: "this item is already on the list !",
          position: "top",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };


  const removeFromSelectedItems = (itemId) => {
    const updatedSelectedItems = selectedItems.filter((item) => item.id !== itemId);
    setSelectedItems(updatedSelectedItems);
  };

  return (
    <Box p="50" width="100%">
      <BackButtons />

      <Flex direction="row" justifyContent="space-evenly" gap={10}>
        <Stack spacing={4}>
          <HStack>
            <Heading mb={30} mt={30}>
              {data ? data.title : <></>}
            </Heading>
          </HStack>

          <Text fontSize="3xs">ID: {param.id}</Text>

          <HStack>
            {files.length > 0 ? (
              <Stack>
                <Image
                  src={files[0].file}
                  boxSize="100%"
                  maxWidth={300}
                  borderRadius="xl"
                  alt={files[0].name}
                  shadow="sm"
                />
              </Stack>
            ) : (
              <Image
                boxSize="100%"
                src={
                  data?.image_url
                    ? data.image_url
                    : "https://bit.ly/dan-abramov"
                }
                alt="Dan Abramov"
              />
            )}
          </HStack>

          <Stack>
            <Input
              type="file"
              onChange={handleFileInputChange}
              display="none"
              id="fileInput"
            />

            <label htmlFor="fileInput">
              <HStack cursor={"pointer"}>
                <Stack>
                  <MdOutlinePermMedia />
                </Stack>
                <Text fontSize={"sm"} color="blue.600" fontStyle={"italic"}>
                  Add Image
                </Text>
              </HStack>
            </label>
          </Stack>

          <Stack>
            {itemList?.length > 0 && (
              <SimpleGrid columns={[1, 1, 2]} gap={2}>
                {itemList.map((x, index) => {
                  const isSelected = selectedItemId === x.id;
                  return (
                    <Stack
                      p={2}
                      bgColor={isSelected ? 'blue.100' : 'white'} // Atur warna latar belakang jika dipilih
                      shadow={'md'}
                      key={index}
                      borderRadius='md'
                      cursor={'pointer'}
                      borderWidth={0.5}
                      borderColor='black'
                      onClick={() => toggleSelectedItem(x.id)}
                    >
                      <Text fontSize={'xx-small'}>ID : {x.id}</Text>
                      <Text fontWeight={500} fontSize='sm' textTransform={'capitalize'}>{x.name}</Text>
                      <Text fontWeight={500} fontSize='sm' textTransform={'capitalize'}>{x.stock} {x.quantity_type}</Text>
                    </Stack>
                  );
                })}
              </SimpleGrid>
            )}
            {/* Input untuk jumlah */}
            <HStack spacing={2}>
              <Input
                type="number"
                borderRadius={'md'}
                bgColor='white'
                placeholder="input value"
                value={amountInput}
                size="sm"
                onChange={(e) => setAmountInput(e.target.value)}
              />
              <Button size={'sm'} onClick={addToSelectedItems}>
                <FcPlus />
              </Button>
            </HStack>
            {/* Tampilkan item yang dipilih */}
            {selectedItems.length > 0 && (
              <Stack>
                <Text fontWeight={500}>Items yang Dipilih:</Text>
                {selectedItems.map((item) => (
                  <Stack bgColor={'white'} p={1} shadow='md' borderRadius='md' key={item.id}>
                    <HStack  >
                      <AiOutlineCheck color="green" />
                      <Text fontWeight={500}>{item.name}</Text>
                      <Spacer />
                      <Text px={3} fontWeight={500}>Amount : {item.amount}</Text>
                      {/* <Spacer /> */}
                      <Button size={'sm'} colorScheme={'red'} onClick={() => removeFromSelectedItems(item.id)}>
                        <AiOutlineCloseCircle />
                      </Button> {/* Tombol "Hapus" */}


                    </HStack>
                    <Divider />
                  </Stack>

                ))}
              </Stack>
            )}
          </Stack>

          <Stack alignItems={'center'} justifyContent='center' py={5}>
            <Box>
              {itemList?.length > startIndex && <Button onClick={handleLoadMore} size='sm'>Load More</Button>}
            </Box>
          </Stack>

        </Stack>

        <Box>
          <FormControl mb={3} isRequired>
            <FormLabel>Menu name</FormLabel>

            <Input
              placeholder="Menu Name"
              isInvalid={data?.title?.length === 0}
              errorBorderColor="crimson"
              value={data?.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
          </FormControl>

          <FormControl mb={3} isRequired>
            <FormLabel>Stock</FormLabel>
            <Input
              placeholder="Stock Product"
              type={"number"}
              value={data?.stock}
              onChange={(e) =>
                setData({ ...data, stock: Number(e.target.value) })
              }
            />

            <FormErrorMessage>Menu Name is required.</FormErrorMessage>
          </FormControl>




          <FormControl mb={3} isRequired>
            <FormLabel>Category</FormLabel>
            <Stack>
              <Select
                m="0"
                isInvalid={data?.category?.length === 0}
                placeholder="station"
                value={data?.category}
                onChange={(e) => setData({ ...data, category: e.target.value })}
              >
                {categoryData?.map((x, i) => (
                  <option key={i} value={x}>
                    <Text textTransform={"capitalize"}>{x}</Text>
                  </option>
                ))}
              </Select>
            </Stack>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Variant Type</FormLabel>
            <Select
              isInvalid={data?.variant_type?.length === 0}
              placeholder="Price type"
              value={data?.variant_type}
              onChange={(e) =>
                setData({ ...data, variant_type: e.target.value })
              }
            >
              <option value={"single"}>
                <Text textTransform={"capitalize"}>Single Variant</Text>
              </option>
              <option value={"multiple"}>
                <Text textTransform={"capitalize"}>Multiple Variant</Text>
              </option>
            </Select>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Station</FormLabel>
            <SimpleGrid columns={3}>
              {stationsData ? (
                stationsData.map((x, i) => (
                  <Checkbox
                    key={i}
                    value={x}
                    isChecked={
                      data?.stations?.find((e) => x === e) ? true : false
                    }
                    onChange={(e) => {
                      data?.stations === undefined
                        ? setData({
                          ...data,
                          stations: [e.target.value],
                        })
                        : data?.stations?.find((e) => x === e)
                          ? setData({
                            ...data,
                            stations: [...data.stations, e.target.value].filter(
                              (item) => item !== x
                            ),
                          })
                          : setData({
                            ...data,
                            stations: [...data.stations, e.target.value],
                          });
                    }}
                  >
                    {x}
                  </Checkbox>
                ))
              ) : (
                <></>
              )}
            </SimpleGrid>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Outlet</FormLabel>
            <SimpleGrid columns={3}>
              {outLetsData.length > 0 ? (
                outLetsData.map((x, i) => {
                  return (
                    <Checkbox
                      key={i}
                      value={x.id}
                      isChecked={
                        data?.outlets?.find((e) => e === x.id) ? true : false
                      }
                      onChange={(e) => {
                        data?.outlets === undefined
                          ? setData({
                            ...data,
                            outlets: [e.target.value],
                          })
                          : data?.outlets?.find((e) => e === x.id)
                            ? setData({
                              ...data,
                              outlets: [...data.outlets, e.target.value].filter(
                                (item) => item !== x.id
                              ),
                            })
                            : setData({
                              ...data,
                              outlets: [...data.outlets, e.target.value],
                            });
                      }}
                    >
                      {x.name}
                    </Checkbox>
                  );
                })
              ) : (
                <></>
              )}
            </SimpleGrid>
          </FormControl>

          <HStack>
            <FormControl mb={3}>
              <FormLabel>Type Price</FormLabel>
              <Select
                m="0"
                isInvalid={data?.price_type?.length === 0}
                placeholder="Price type"
                value={data?.price_type}
                onChange={(e) =>
                  setData({ ...data, price_type: e.target.value })
                }
              >
                <option value={"single"}>
                  <Text textTransform={"capitalize"}>Single Price</Text>
                </option>
                <option value={"multiple"}>
                  <Text textTransform={"capitalize"}>Multiple Price</Text>
                </option>
              </Select>
            </FormControl>

            {data?.price_type === "multiple" ? (
              <FormControl>
                <FormLabel>Prices</FormLabel>

                {prices.map((price, index) => (
                  <Input
                    key={index}
                    placeholder={`Price ${index + 1}`}
                    isInvalid={data?.price?.length === 0}
                    type="number"
                    value={price}
                    onChange={(e) => handlePriceChange(index, e.target.value)}
                  />
                ))}

                <Button onClick={() => setPrices([...prices, ""])}>
                  Add Price
                </Button>
              </FormControl>
            ) : (
              <FormControl>
                <FormLabel>Price</FormLabel>
                <Input
                  isInvalid={data?.price?.length === 0}
                  placeholder="Price"
                  type="number"
                  value={data?.price}
                  onChange={(e) => setData({ ...data, price: e.target.value })}
                />
              </FormControl>
            )}

            <FormControl>
              <FormLabel>Pajak</FormLabel>
              <Input
                isInvalid={data?.tax?.length === 0}
                placeholder="Price"
                value={data?.tax}
                type="number"
                onChange={(e) => setData({ ...data, tax: e.target.value })}
              />
            </FormControl>
          </HStack>

          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              placeholder="Here is a sample placeholder"
              value={data?.description}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
          </FormControl>

          {/* <Button w="full" mt="2" onClick={() => console.log(data)}>
            Check Data
          </Button> */}

          <Button
            w="full"
            h={50}
            colorScheme="green"
            mt="2"
            leftIcon={<CheckIcon />}
            onClick={() => validateForm()}
          >
            Save
          </Button>
        </Box>
      </Flex>
    </Box >
  );
}

export default EditMenuPage;
