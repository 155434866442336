export function checkOrderValidity(paymentMethod, fixPrice) {
  if (fixPrice < 0 || fixPrice === isNaN)
    return {
      error: {
        title: "Warning",
        description: "you must check your order",
        status: "warning",
      },
      success: false,
    };

  if (!paymentMethod)
    return {
      error: {
        title: "Warning",
        description: "you must set payment method.",
        status: "warning",
      },
      success: false,
    };

  return {
    success: true,
    error: null,
  };
}

export function checkOrderEditValidity(
  paymentMethod,
  fixPrice,
  paymentStatus,
  role
) {
  if (fixPrice < 0 || fixPrice === isNaN)
    return {
      error: {
        title: "Warning",
        description: "you must check your order",
        status: "warning",
      },
      success: false,
    };

  if (!paymentMethod)
    return {
      error: {
        title: "Warning",
        description: "you must set payment method.",
        status: "warning",
      },
      success: false,
    };

  if (!paymentStatus)
    return {
      error: {
        title: "Warning",
        description: "you must set payment status.",
        status: "warning",
      },
      success: false,
    };

  if (role !== "admin" && role !== "manager" && role !== "owner")
    return {
      error: {
        title: "Warning",
        description: "You dont have access to edit the orders.",
        status: "warning",
      },
      success: false,
    };

  return {
    success: true,
    error: null,
  };
}

export function checkRefundValidity(paymentMethod, fixPrice, role) {
  if (fixPrice > 0 || fixPrice === isNaN)
    return {
      error: {
        title: "Warning",
        description: "you must check your order",
        status: "warning",
      },
      success: false,
    };

  if (!paymentMethod)
    return {
      error: {
        title: "Warning",
        description: "you must set payment method.",
        status: "warning",
      },
      success: false,
    };

  if (role !== "admin" && role !== "manager" && role !== "owner")
    return {
      error: {
        title: "Warning",
        description: "You dont have access to edit the orders.",
        status: "warning",
      },
      success: false,
    };

  return {
    success: true,
    error: null,
  };
}

export function checkWasteValidity(shift, fixPrice, role) {
  if (!shift)
    return {
      error: {
        title: "Warning",
        description: "you must input shift",
        status: "warning",
      },
      success: false,
    };

  if (fixPrice < 0 || fixPrice === isNaN)
    return {
      error: {
        title: "Warning",
        description: "you must check your order",
        status: "warning",
      },
      success: false,
    };

  if (role !== "admin" && role !== "manager" && role !== "owner")
    return {
      error: {
        title: "Warning",
        description: "You dont have access to edit the orders.",
        status: "warning",
      },
      success: false,
    };

  return {
    success: true,
    error: null,
  };
}

export function checkTransactionValidity(
  amount,
  type,
  paymentMethod,
  notes,
  image
) {
  if (
    amount === 0 ||
    type === 0 ||
    paymentMethod === 0 ||
    notes === 0 ||
    image === 0
  )
    return {
      error: {
        title: "Error",
        description: "lengkapi data terlebih dahulu",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      },
      success: false,
    };

  return {
    success: true,
    error: null,
  };
}

export function checkTransactionAssignValidity(fixPrice, role) {

  if (fixPrice < 0 || fixPrice === isNaN)
    return {
      error: {
        title: "Warning",
        description: "you must check your transaction",
        status: "warning",
      },
      success: false,
    };

  if (role !== "admin" && role !== "manager" && role !== "owner")
    return {
      error: {
        title: "Warning",
        description: "You dont have access to edit the orders.",
        status: "warning",
      },
      success: false,
    };

  return {
    success: true,
    error: null,
  };
}