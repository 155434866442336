import {
  Box,
  Button,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  useDisclosure,
  Stack,
  Text,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  SimpleGrid,
  Radio,
  RadioGroup,
  Heading,
  Divider,
} from "@chakra-ui/react";
import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
  updateDocumentFirebase,
  uploadFile,
} from "../../Apis/firebaseApi";

import { LuRefreshCw } from "react-icons/lu"
import { app, db } from "../../Config/firebase";
import { DragHandleIcon, AddIcon } from "@chakra-ui/icons";
import useUserStore from "../../Hooks/Zustand/Store";
import "firebase/firestore";
import moment from "moment/moment";
import { FcPlus } from "react-icons/fc";
import StockCard from "../../Components/Cards/StockCardComponent";
import DrawerStock from "../../Components/Drawer/DrawerStockComponent";
import ModalStock from "../../Components/Modal/ModalStockComponent";
import BackButtons from "../../Components/Buttons/BackButtons";
import LowStockTable from "../../Components/Tables/LowStockTable";
import axios from "axios";
import axiosInstance from "../../Apis/axiosWithNoBarier";

function StockPage() {
  const globalState = useUserStore();
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const [stockLowData, setStockLowData] = useState([])
  const [search, setSearch] = useState();
  const [search1, setSearch1] = useState();
  const [category, setCategory] = useState();
  const [cat, setCat] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const [names, setNames] = useState([]);
  const [history, setHistory] = useState("");
  const [ren, setRen] = useState([]);
  const firstField = useRef();
  const [detailStock, setDetailStock] = useState();
  const [stock, setStock] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const itemsPerPage = 5;

  const closeModal = () => setModalOpen(false);
  const [isModalOpen, setModalOpen] = useState(false);



  const openModal = async (x) => {
    try {
      setModalOpen(true);
      setDetailStock(x.id);
      setStock(x);
      getHistory()
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  };

  useEffect(() => {
    if (globalState?.currentCompany) {
      fetchDataProduct();
      getDataCollection();
    }
    return () => {
      setNames([]);
    };
  }, [globalState.currentOutlet, search, search1]);

  useEffect(() => {
    if (!startDate && !endDate) {
      getHistory();
    } else {
      getFilterHistory();
    }
  }, [startDate, endDate, globalState.currentOutlet, detailStock]);

  const fetchDataProduct = async () => {
    if (globalState?.currentProject) {
      try {
        const q = query(
          collection(db, 'suppliers'),
          where('companyId', '==', globalState.currentCompany),
          where('projectId', '==', globalState.currentProject),
          where('outletId', '==', globalState.currentOutlet)
        );
        const querySnapshot = await getDocs(q);
        const suppliersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCat(suppliersData);
      } catch (error) {
        console.error('Error fetching suppliers:', error);
      }
    }
  };

  const getDataCollection = async () => {
    try {
      const conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState.currentCompany,
        },
        {
          field: 'projectId',
          operator: '==',
          value: globalState.currentProject,
        },
        {
          field: 'outletId',
          operator: '==',
          value: globalState.currentOutlet,
        },
      ];

      if (search) {
        conditions.push({
          field: 'category',
          operator: '==',
          value: search,
        });
      }

      if (search1) {
        conditions.push({
          field: 'supplier',
          operator: '==',
          value: search1,
        });
      }

      const res = await getCollectionFirebase('warehouse', conditions);

      getLowStockData(res)


      const mappedData = {};
      res.forEach((menu) => {
        const category = menu.category;
        if (!mappedData[category]) {
          mappedData[category] = [];
        }
        mappedData[category].push(menu);
      });
      setNames(mappedData);

    } catch (error) {
      console.log(error, 'ini error');
    }
  };

  const getLowStockData = (data) => {
    const lowStock = data.filter((item) => item.stock < 10);
    setStockLowData(lowStock);

  }

  const handleCategoryClick = (value) => {
    setCategory(value);
    setData({ ...data, category: value });
  };

  const handleAddMenu = async () => {
    setLoading(true);

    const categoryShort = category.charAt(0).toUpperCase();

    const updatedData = {
      ...data,
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject,
      outletId: globalState.currentOutlet,
      sku: `${categoryShort}-${moment(new Date()).valueOf()}-${globalState.currentCompany}`,
    };

    const collectionName = 'warehouse';

    if (filesImage[0]) {
      try {
        const resImage = await uploadFile(
          `${data.name}-${moment(new Date()).valueOf()}`,
          'stock',
          filesImage[0]
        );
        updatedData.image_url = resImage;
      } catch (error) {
        console.log('Error uploading image:', error);
        setLoading(false);
        return;
      }
    }

    try {
      const docID = await addDocumentFirebase(
        collectionName,
        updatedData,
        globalState.currentCompany
      );
      getDataCollection();
      setData({});
    } catch (error) {
      console.log('An error occurred:', error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleDelete = async (itemToDelete) => {
    const hapus = itemToDelete.id;
    const collectionName = 'warehouse';
    const docName = hapus;

    try {
      const result = await deleteDocumentFirebase(collectionName, docName);
      getDataCollection(); // Pesan toast yang berhasil
    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    }
  };

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;
    if (newFiles.length) {
      const newFileArray = [...files];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);
        };
      }
      setFilesImage(newFiles);
    }
  };

  const page = Math.ceil((ren?.length || 0) / itemsPerPage) * 10;

  const getFilterHistory = async () => {
    const collectionName = 'warehouse';
    const docName = detailStock;

    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      {
        field: 'outletId',
        operator: '==',
        value: globalState.currentOutlet,
      },
      {
        field: 'createdAt',
        operator: '>=',
        value: startDate,
      },
      {
        field: 'createdAt',
        operator: '<=',
        value: endDate,
      },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };

    try {
      const res = await getCollectionFirebase(
        `${collectionName}/${detailStock}/history`,
        conditions,
        sortBy
      );
      setRen(res);
    } catch (error) {
      console.log(error, 'ini error');
    }
  };

  const getHistory = async () => {
    const collectionName = 'warehouse';
    const docName = detailStock;

    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      {
        field: 'outletId',
        operator: '==',
        value: globalState.currentOutlet,
      },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };

    try {
      const res = await getCollectionFirebase(
        `${collectionName}/${detailStock}/history`,
        conditions,
        sortBy
      );
      setRen(res);
    } catch (error) {
      console.log(error, 'ini error');
    }
  };

  const handleAddHistory = async () => {
    const collectionName = 'warehouse';
    const docName = detailStock;
    const val = {
      ...history,
      name: globalState.name,
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject,
      outletId: globalState.currentOutlet,
    };
    const number = history.value;
    const amount = stock.stock;

    if (history.status === 'inbound') {
      const sum = {
        stock: parseInt(number) + parseInt(amount),
      };
      const result = await updateDocumentFirebase(
        collectionName,
        docName,
        sum
      );
    } else {
      const sum = {
        stock: parseInt(amount) - parseInt(number),
      };
      const result = await updateDocumentFirebase(
        collectionName,
        docName,
        sum
      );
    }

    try {
      const docID = await addDocumentFirebase(
        `${collectionName}/${docName}/history`,
        val,
        globalState.currentCompany
      );


      const data = {
        id: docName,
        name_items: stock.name || "",
        image: stock.image_url || "",
        type: "warehouse",
        value: history.value || 0,
        status: history.status || "",
        companyId: globalState?.currentCompany,
        projectId: globalState?.currentProject,
        outletId: globalState?.currentOutlet,
        name: globalState?.name || "",
        uid: globalState?.uid || ""
      }



      if (docID) {
        const res = await axiosInstance.post("/stock_activity", data )
        if (res) {
          getDataCollection();
          setHistory("")
          setStock("")
          setModalOpen(false);
        }
      }

    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    }
  };

  const paginate = (array, page_size, page_number) => {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const handleDateRangeChange = (dateRange) => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
  };

  return (
    <>
      <Stack p={[1, 1, 5]} spacing={5}>

        <Stack >
          <Stack flexDirection={["column", "row", "row"]} gap={4}>
            <BackButtons />
            <Heading >
              Stock
            </Heading>
            <Spacer />

            <Button onClick={() => {
              setSearch("")
              setSearch1("")
            }}>
              <LuRefreshCw />
            </Button>


            <Menu>
              <MenuButton
                as={Button}
                color="gray.600"
                rightIcon={<DragHandleIcon />}
              >
                Filter
              </MenuButton>

              <MenuList>
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Text fontWeight={'bold'}>Type</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel >
                      <RadioGroup >
                        <SimpleGrid columns={[1, 2, 2]} gap={3}>

                          {["makanan", "minuman", "bumbu", "perlengkapan makan", "perlengkapan minum", "alat makan"].map((type, index) => (
                            <Radio
                              key={index}
                              textTransform="capitalize"
                              value={type}
                              onChange={(e) => setSearch(e.target.value)}
                            >
                              {type}
                            </Radio>
                          ))}
                        </SimpleGrid>

                      </RadioGroup>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Text fontWeight={"bold"}>Supplier</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel >
                      <RadioGroup>
                        <SimpleGrid gap={3} columns={[1, 2, 2]}>
                          {cat.map((k, index) => (
                            <Radio
                              key={index}
                              value={k.name}
                              onChange={(e) => setSearch1(e.target.value)}
                            >
                              {k.name}
                            </Radio>
                          ))}
                        </SimpleGrid>

                      </RadioGroup>

                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </MenuList>
            </Menu>
            <Button
              bgColor={"white"}
              onClick={onOpen}
              shadow="md"
              variant="outline"
              borderColor="#F05A28"
              color="#F05A28"
            >
              <HStack>
                <FcPlus />
                <Text>Stock</Text>
              </HStack>
            </Button>

          </Stack>
        </Stack>

        <Stack spacing={5}>
          <Text fontSize="xl" fontWeight="bold">Items Low of Stock</Text>
          <Box overflowX={'auto'} maxH={"500px"} overflowY="scroll">
            {stockLowData.length > 0 ? (
              <LowStockTable lowStockData={stockLowData} openModal={openModal} />
            ) : <Stack h={'300px'} alignItems={'center'} justifyContent='center'>
              <Text color={'gray.600'}>No have any data low of stock</Text></Stack>}

          </Box>
        </Stack>

        <Divider />



        {names &&
          Object.keys(names).map((category, index) => (
            <Stack key={index} spacing={3}>
              <Text fontWeight="bold" textTransform={'capitalize'} marginTop={"10px"} fontSize={"20px"}>
                {category}
              </Text>
              <SimpleGrid gap={2} columns={[1, null, 2]} w="full">
                {names[category].map((x, subIndex) => (
                  <StockCard
                    key={subIndex}
                    x={x}
                    navigate={navigate}
                    handleDelete={handleDelete}
                    openModal={openModal}
                  />
                ))}
              </SimpleGrid>
            </Stack>
          ))}

        <ModalStock
          isOpen={isModalOpen}
          onClose={closeModal}
          history={history}
          setHistory={setHistory}
          handleDateRangeChange={handleDateRangeChange}
          paginate={paginate}
          ren={ren}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          page={page}
          setCurrentPage={setCurrentPage}
          handleAddHistory={handleAddHistory}
          closeModal={closeModal}
        />

        <DrawerStock
          isOpen={isOpen}
          onClose={onClose}
          data={data}
          handleCategoryClick={handleCategoryClick}
          setData={setData}
          loading={loading}
          handleAddMenu={handleAddMenu}
          files={files}
          handleFileInputChange={handleFileInputChange}
          category={category}
          cat={cat}
        />
      </Stack>
    </>
  );
}

export default StockPage;
