import React from 'react'
import SupplierPage from '../Pages/Supplier/SupplierPage'

const SupplierRouter = [
	{
		path: "/suppliers",
		element: <SupplierPage />
	},

]

export default SupplierRouter