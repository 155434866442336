import {
  Box,
  Flex,
  Spacer,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabList,
  Tabs,
  Button,
  HStack,
  Heading,
  Image,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { ArrowBackIcon, SearchIcon } from "@chakra-ui/icons";
import { addDocumentFirebase, getCollectionFirebase } from "../../Apis/firebaseApi";
import { formatFrice } from "../../Utils/numberUtil";
import { FcPlus } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../Hooks/Zustand/Store";

function WasteNewPage({
  setShowAddWastePage,
  data,
  setData,
  categoryData,
  setCategoryData,
  inputSearch,
  setInputSearch,
  dataSearched,
  setDataSearched,
  openModalPriceType,
  setOpenModalPriceType,
  totalOrder,
  setTotalOrder,
  localData,
  setLocalData,
  waste,
  setWaste,
  selectedMenu,
  setSelectedMenu,
  selectedPrice,
  setSelectedPrice,
  wasteData,
  setWasteData,
}) {

  const globalState = useUserStore();

  const [notes, setNotes] = useState("")

  const navigate = useNavigate()

  const toast = useToast({
    position: "top",
    align: "center",
  });

  const getData = () => {
    getCollectionFirebase("menus", [
      {
        field: "companyId",
        operator: "==",
        value: globalState.currentCompany,
      },
    ])
      .then((menus) => {
        const filteredMenus = menus?.filter((menu) =>
          menu?.outlets?.includes(globalState.currentOutlet)
        );
        const sortData = filteredMenus.sort((a, b) =>
          a.title.localeCompare(b.title)
        );

        setData(sortData);

        // Mapping data berdasarkan kategori
        const mappedData = {};
        filteredMenus.forEach((menu) => {
          const category = menu.category;
          if (!mappedData[category]) {
            mappedData[category] = [];
          }
          mappedData[category].push(menu);
        });

        setCategoryData(mappedData);
        localStorage.setItem("categoryData", JSON.stringify(mappedData));
        setLocalData(mappedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // if (wasteData.length === 0) {
    getData();
    // }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.currentOutlet, globalState.currentCompany]);

  const handleTabSelection = (tabs) => {
    const element = document.getElementById(tabs);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  //   Search function :
  const searchFilterFunction = (text) => {
    if (text) {
      const newData = data.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataSearched(newData);
      setInputSearch(text);
    } else {
      setDataSearched([]);
      setInputSearch(text);
    }
  };

  const handleAddWaste = (menu, index) => {
    let newOrder = [...waste];

    const indexSameOrder = waste.findIndex((x) => x.id === menu.id); // check if the same menu item exists in waste, get the index

    if (indexSameOrder >= 0) {
      handleIncreaseQuantity(menu, indexSameOrder);
    } else {
      if (menu.price_type === "multiple") {
        // handle multiple prices
        if (selectedPrice) {
          setOpenModalPriceType(false);

          const priceIndex = parseInt(selectedPrice);
          if (priceIndex >= 0 && priceIndex < menu.prices.length) {
            let price_type = "";
            if (priceIndex === 0) {
              price_type = "komplit";
            } else if (priceIndex === 1) {
              price_type = "ala carte";
            }

            newOrder.push({
              id: menu.id,
              title: menu.title,
              base_price: menu.prices[priceIndex],
              price: menu.prices[priceIndex],
              stations: menu.stations,
              quantity: 1,
              price_type: price_type,
              category: menu.category,
              variant_type: menu.variant_type ? menu.variant_type : "multiple",
              stock: menu.stock - 1, // Decrease stock by 1
            });
            setSelectedPrice();
          } else {
            alert("Invalid price selection");
          }
        }
      } else {
        // handle single price
        newOrder.push({
          id: menu.id,
          title: menu.title,
          base_price: menu.price,
          price: menu.price,
          stations: menu.stations,
          quantity: 1,
          category: menu.category,
          variant_type: menu.variant_type ? menu.variant_type : "single",
          stock: menu.stock - 1, // Decrease stock by 1
        });
      }

      setWaste(newOrder);
      setLocalData((prevData) => ({
        ...prevData,
        [menu.category]: prevData[menu.category].map((item) =>
          item.id === menu.id ? { ...item, stock: item.stock - 1 } : item
        ),
      }));
    }
  };

  const handleAddWasteData = () => {
    const updatedWasteData = [...wasteData];

    waste.forEach((item) => {
      const existingItemIndex = updatedWasteData.findIndex(
        (x) => x.id === item.id
      );
      if (existingItemIndex >= 0) {
        // Item already exists, update stock and quantity
        updatedWasteData[existingItemIndex].stock -= item.quantity;
        updatedWasteData[existingItemIndex].quantity += item.quantity;
      } else {
        // Item doesn't exist, add it to wasteData
        updatedWasteData.push(item);
      }
    });


    const newWaste = { ...totalOrder, wastes: updatedWasteData };
    newWaste.outlet = globalState.currentOutlet;
    newWaste.outletId = globalState.currentOutlet;
    newWaste.projectId = globalState.currentProject;
    newWaste.module = "rms";
    newWaste.assignment_status = "pending";
    newWaste.type_reports = "waste";
    newWaste.notes = notes;




    addDocumentFirebase("wastes", newWaste, globalState.currentCompany).then(
      (x) =>
        //   createPaymentVA(x).then((x) => console.log(x))
        toast({
          title: "Berhasil Menambahkan",
          description: "Berhasil Menambahkan waste",
          status: "success",
          duration: 9000,
          isClosable: true,
        }));

    setShowAddWastePage(false);
    setWasteData(updatedWasteData);
    setWaste([]);
  };


  const calculateTotalPrice = useCallback(() => {
    const totalPrice = waste.reduce(
      (acc, item) => acc + parseInt(item.price),
      0
    );
    setTotalOrder((prev) => ({
      ...prev,
      totalPrice,
    }));
  }, [waste]);

  // Buat fungsi total harga ganti secara dynamic
  useEffect(() => {
    calculateTotalPrice();
  }, [calculateTotalPrice]);

  const handleIncreaseQuantity = (menu, index) => {
    const updatedWaste = [...waste];
    const quantityPerPrice =
      updatedWaste[index].price / updatedWaste[index].quantity; // Hitung harga item per quantity
    if (updatedWaste[index].stock > 0) {
      updatedWaste[index].quantity += 1;
      updatedWaste[index].stock -= 1;
      updatedWaste[index].price =
        quantityPerPrice * updatedWaste[index].quantity;
      setWaste(updatedWaste);
      setLocalData((prevData) => ({
        ...prevData,
        [menu.category]: prevData[menu.category].map((item) =>
          item.id === menu.id ? { ...item, stock: item.stock - 1 } : item
        ),
      }));

      // Update Stock to Firebase
      // const filteredMenu = data.filter((list) => list.id === menu.id);
      // filteredMenu.map((list) => {
      //   updateDocumentFirebase("menus", menu.id, {
      //     stock: list.stock - 1,
      //   });
      // });
    }
  };

  const handleDecreaseQuantity = (menu, index) => {
    const updatedWaste = [...waste];
    const quantity = updatedWaste[index].quantity;

    if (quantity > 1) {
      const quantityPerPrice =
        updatedWaste[index].price / updatedWaste[index].quantity;
      updatedWaste[index].quantity -= 1;
      updatedWaste[index].stock += 1;
      updatedWaste[index].price =
        quantityPerPrice * updatedWaste[index].quantity;
      setWaste(updatedWaste);

      setLocalData((prevData) => ({
        ...prevData,
        [menu.category]: prevData[menu.category].map((item) =>
          item.id === menu.id ? { ...item, stock: item.stock + 1 } : item
        ),
      }));

      // Update Stock to Firebase
      // const filteredMenu = data.filter((list) => list.id === menu.id);
      // filteredMenu.map((list) => {
      //   updateDocumentFirebase("menus", menu.id, {
      //     stock: list.stock + 1,
      //   });
      // });
    } else if (quantity === 1) {
      updatedWaste.splice(index, 1); // Menghapus orderan dari daftar
      setWaste(updatedWaste);

      setLocalData((prevData) => ({
        ...prevData,
        [menu.category]: prevData[menu.category].map((item) =>
          item.id === menu.id ? { ...item, stock: menu.stock + 1 } : item
        ),
      }));
      // Update Stock to Firebase
      // const filteredMenu = data.filter((list) => list.id === menu.id);
      // filteredMenu.map((list) => {
      //   updateDocumentFirebase("menus", menu.id, {
      //     stock: list.stock + 1,
      //   });
      // });
    }
  };

  const handleOpenModalPriceType = (menu, i) => {
    setOpenModalPriceType(true);
    setSelectedMenu({ menu, i });
  };

  return (
    <Box>
      <Flex direction="row" align="center" id="all">
        <Flex
          direction="row"
          alignItems="center"
          gap={2}
          onClick={() => setShowAddWastePage(false)}
          sx={{ cursor: "pointer" }}
        >
          <ArrowBackIcon boxSize={5} color="#808080" />
          <Text fontWeight={500} color="#808080">
            Back
          </Text>
        </Flex>
        <Spacer />
        {/* <Heading>Create New Order</Heading> */}
        {/* <Button onClick={() => console.log(totalOrder)}>check console</Button> */}
      </Flex>
      <Box position="sticky" top={0} backgroundColor="#F7FAFC" zIndex={1}>
        <InputGroup mt={15}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Cari Menu"
            onChange={(e) => {
              searchFilterFunction(e.target.value);
            }}
          />
        </InputGroup>

        <Tabs
          variant="soft-rounded"
          colorScheme="orange"
          mb={19}
          p={3}
          borderRadius="lg"
          align="center"
          isLazy
        >
          <TabList flexWrap="wrap" justifyContent="center">
            <Tab
              onClick={() => {
                handleTabSelection("all");
              }}
            >
              ALL
            </Tab>

            {Object.entries(localData).map(([category]) => (
              <Tab
                key={category}
                onClick={() => {
                  handleTabSelection(category);
                }}
                fontSize={{ base: "sm", md: "md" }}
              >
                {category?.toUpperCase()}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Box>
      <Flex direction={["column", "row", "row"]}  gap={5} justifyContent="space-between">
        {dataSearched.length !== 0 ? (
          <Stack
            width={waste.length === 0 && "60vw"}
            overflowY="auto"
            p="2"
          >
            <SimpleGrid columns={[2, null, 4]} gap={5}>
              {dataSearched.map((x, i) => (
                <Stack
                  key={i}
                  p="2"
                  spacing={2}
                  borderRadius="lg"
                  borderWidth={0.5}
                  shadow="lg"
                  // borderColor="blackAlpha.200"
                  borderColor="#F05A28"
                >
                  <Image src={x.image_url} alt={x.title} borderRadius="lg" />

                  <Stack spacing={1}>
                    <Text
                      fontSize="md"
                      textTransform="capitalize"
                      fontWeight={500}
                    >
                      {x.title}
                    </Text>

                    <Text fontSize="xs" textTransform="capitalize">
                      {x.description}
                    </Text>

                    {x.price_type === "multiple" ? (
                      <HStack>
                        {x.prices.map((y, index) => {
                          const priceTitle =
                            index === 0 ? "Komplit" : "Ala Carte";
                          return (
                            <Stack key={index}>
                              <Text fontSize={"sm"}>{priceTitle}</Text>
                              <Text fontSize={"sm"}>
                                Harga per satuan : Rp.{" "}
                                {formatFrice(parseFloat(y))}
                              </Text>
                            </Stack>
                          );
                        })}
                      </HStack>
                    ) : (
                      <Text fontSize={"sm"}>
                        Harga per satuan : Rp.{" "}
                        {formatFrice(parseFloat(x.price))}
                      </Text>
                    )}
                  </Stack>

                  <Spacer />

                  <Stack>
                    <Button
                      colorScheme="green"
                      size="sm"
                      borderRadius="lg"
                      width="full"
                      isDisabled={
                        x.stock === 0
                          ? true
                          : x.stock === undefined
                            ? true
                            : false
                      }
                      onClick={() => {
                        x.price_type === "multiple"
                          ? handleOpenModalPriceType(x, i)
                          : handleAddWaste(x, i);
                        // setRefreshData(true);
                      }}
                    >
                      {x.stock === 0
                        ? "Habis"
                        : x.stock === undefined
                          ? "Habis"
                          : "Waste"}
                    </Button>
                  </Stack>
                </Stack>
              ))}
            </SimpleGrid>
          </Stack>
        ) : (
          <Stack
            width={waste.length === 0 && "60vw"}
            overflowY="auto"
            p="2"

          >
            <Stack bgColor={"white"}>
              {Object.entries(localData).map(([category, categoryMenus]) => (
                <Stack
                  spacing={2}
                  key={category}
                  p={[2, 5]}
                  shadow="xl"
                  borderWidth="1px"
                  borderRadius="xl"
                  borderColor="gray.300"
                  id={category}
                >
                  <Heading size="lg" textAlign="center" mb={5}>
                    {category?.toUpperCase()}
                  </Heading>

                  <hr style={{ marginBottom: 10 }} />

                  <SimpleGrid columns={[1, 2, 4]} gap={5}>
                    {categoryMenus.map((x, i) => (
                      <Stack
                        key={i}
                        p="2"
                        spacing={2}
                        borderRadius="lg"
                        borderWidth={0.5}
                        shadow="lg"
                        borderColor="#F05A28"
                      >
                        <Image
                          src={x.image_url}
                          alt={x.title}
                          borderRadius="lg"
                        />

                        <Stack spacing={1}>
                          <Text
                            fontSize="md"
                            textTransform="capitalize"
                            fontWeight={500}
                          >
                            {x.title}
                          </Text>

                          <Text fontSize="xs" textTransform="capitalize">
                            {x.description}
                          </Text>

                          <Text fontSize="xs" textTransform="capitalize">
                            Tersisa: {x.stock}
                          </Text>
                          {x.price_type === "multiple" ? (
                            <HStack>
                              {x.prices.map((y, index) => {
                                const priceTitle =
                                  index === 0 ? "Komplit" : "Ala Carte";
                                return (
                                  <Stack key={index}>
                                    <Text fontSize={"sm"}>{priceTitle}</Text>
                                    <Text fontSize={"sm"}>
                                      Harga per satuan: Rp.{" "}
                                      {formatFrice(parseFloat(y))}
                                    </Text>
                                  </Stack>
                                );
                              })}
                            </HStack>
                          ) : (
                            <Text fontSize={"sm"}>
                              Harga per satuan: Rp.{" "}
                              {formatFrice(parseFloat(x.price))}
                            </Text>
                          )}
                        </Stack>

                        <Spacer />

                        <Stack>
                          <Button
                            colorScheme="green"
                            size="sm"
                            borderRadius="lg"
                            width="full"
                            isDisabled={
                              x.stock === 0
                                ? true
                                : x.stock === undefined
                                  ? true
                                  : false
                            }
                            leftIcon={<FcPlus />}
                            onClick={() => {
                              x.price_type === "multiple"
                                ? handleOpenModalPriceType(x, i)
                                : handleAddWaste(x, i);
                              // setRefreshData(true);
                            }}
                          >
                            {x.stock === 0
                              ? "Habis"
                              : x.stock === undefined
                                ? "Habis"
                                : "waste"}
                          </Button>
                        </Stack>
                      </Stack>
                    ))}
                  </SimpleGrid>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}

        <Stack
          minW={["100%", "30%", "30%"]}
          height="auto"
          maxH={600}
          overflowY="auto"
          p="5"
          mt={1}
          backgroundColor="white"
          shadow="lg"
          borderWidth="1px"
          borderRadius="xl"
          borderColor="#F05A28"
          position="sticky"
          top={100}
        >
          <Heading size={"md"} textAlign="center">
            WASTE FOOD
          </Heading>

          <hr />

          {waste?.map((x, i) => {
            return (
              <Stack key={i}>
                <HStack>
                  <Box>
                    <Text fontSize={"sm"} fontWeight={500}>
                      {x.title}
                    </Text>

                    {x.variant && (
                      <Text fontSize={"sm"}>
                        Type: {x.price_type && x.price_type}
                      </Text>
                    )}

                    <Text fontSize={"sm"}>
                      Rp.{formatFrice(parseFloat(x.price))}
                    </Text>
                  </Box>

                  <Spacer />

                  <Stack>
                    <HStack>
                      <Button
                        size={"sm"}
                        onClick={() => {
                          handleDecreaseQuantity(x, i);
                        }}
                      >
                        -
                      </Button>
                      <Text>{x.quantity}</Text>
                      <Button
                        size={"sm"}
                        onClick={() => {
                          handleIncreaseQuantity(x, i);
                        }}
                      >
                        +
                      </Button>
                    </HStack>
                  </Stack>
                </HStack>

                <Stack>
                  {x.variant_type === "multiple" &&
                    x.price_type === "komplit" && (
                      <RadioGroup
                        onChange={(e) => {
                          const updatedWaste = [...waste];
                          updatedWaste[i].variant = e;
                          if (e === "barra") {
                            updatedWaste[i].price =
                              (parseInt(updatedWaste[i].base_price) + 1000) *
                              updatedWaste[i].quantity;
                          } else {
                            updatedWaste[i].price =
                              parseInt(updatedWaste[i].base_price) *
                              updatedWaste[i].quantity;
                          }
                          setWaste(updatedWaste);
                        }}
                      >
                        <HStack align="start" fontSize={"sm"}>
                          {["bakka", "barra", "gajja"].map((value, index) => (
                            <Radio key={index} value={value}>
                              <Text>
                                {value.charAt(0).toUpperCase() +
                                  value.slice(1)}
                              </Text>
                            </Radio>
                          ))}
                        </HStack>
                      </RadioGroup>
                    )}
                </Stack>

                <hr style={{ marginTop: 20 }} />
              </Stack>
            );
          })}

          <Stack direction="row" align="center">
            <Text fontWeight={500}>Total:</Text>
            <Spacer />
            <Text fontWeight={700} fontSize={20}>
              Rp.{formatFrice(totalOrder.totalPrice)}
            </Text>
          </Stack>

          <Stack>
            <Textarea onChange={(e) => setNotes(e.target.value)} placeholder='berikan catatan' />
          </Stack>

          <Button
            onClick={handleAddWasteData}
            colorScheme="green"
            padding={5}
          >
            Add Waste
          </Button>

          {/* <Button onClick={handleCloseBill}>Close Bill</Button> */}
        </Stack>
      </Flex>

      {selectedMenu && (
        <Modal
          isOpen={openModalPriceType}
          onClose={() => setOpenModalPriceType(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedMenu?.menu?.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Pilih Tipe Menu</FormLabel>
                <RadioGroup
                  onChange={(value) => {
                    setSelectedPrice(String(value));
                  }}
                  value={selectedPrice}
                >
                  <Stack>
                    {selectedMenu.menu?.prices?.map((y, index) => {
                      const priceTitle = index === 0 ? "Komplit" : "Ala Carte";
                      return (
                        <Radio value={String(index)} key={index}>
                          {priceTitle}: Rp.{formatFrice(parseFloat(y))}
                        </Radio>
                      );
                    })}
                  </Stack>
                </RadioGroup>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() =>
                  handleAddWaste(selectedMenu.menu, selectedMenu?.i)
                }
              >
                Pilih
              </Button>
              <Button onClick={() => setOpenModalPriceType(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default WasteNewPage;
