import React from 'react'
import NewUserPage from '../Pages/Profile/NewUserPage'
import { ProfilePage } from '../Pages/Profile/ProfilePage'

const ProfileRouter = [
	{
		path: "/profiles",
		element: <ProfilePage />
	},
	{
		path: "/newuser",
		element: <NewUserPage />
	},

]

export default ProfileRouter