import {
  Badge,
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  useToast,
  Divider,
  SimpleGrid,
  Alert,
  IconButton,
  Input,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
  orderBy,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  addDocumentFirebase,
  getCollectionFirebase,
} from "../../Apis/firebaseApi";
import BackButtons from "../../Components/Buttons/BackButtons";
import { TableComponent } from "../../Components/Tables/TableComponent";
import { auth, db } from "../../Config/firebase";
import { formatFrice } from "../../Utils/numberUtil";
import { FcPlus } from "react-icons/fc";
import { getCompanyName, getStoreName } from "../../Utils/nameUtil";
import { RefundTable } from "../../Components/Tables/RefundTable";
import useUserStore from "../../Hooks/Zustand/Store";
import ReportOrderTable from "../../Components/Tables/ReportOrderTable";
import { FaRegCalendar } from "react-icons/fa";
import DatePicker from "../../Components/Calendar/DatePicker";

function OrdersPage() {
  const globalState = useUserStore();
  const [data, setData] = useState();
  const [dataRefunded, setDataRefunded] = useState([]);
  const [dataToday, setDataToday] = useState();
  const [dataRefundToday, setDataRefundToday] = useState();

  const [shift, setShift] = useState("");
  const [reportModal, setReportModal] = useState(false);
  const [reportDetailModal, setReportDetailModal] = useState(false);
  const [reportData, setReportData] = useState("");

  const [selectedDateRange, setSelectedDateRange] = useState();
  const [reportDetailOrder, setReportDetailOrder] = useState("");

  const [amountNow, setAmountNow] = useState(0);
  const [amountRefundNow, setAmountRefundNow] = useState(0);
  const [amountTaxNow, setAmountTaxNow] = useState(0);
  const [amountDiscountNow, setAmountDiscountNow] = useState(0);
  const [itemRefunded, setItemRefunded] = useState([]);
  const [view, setView] = useState("TABLE");
  const [selectedDate, setSelectedDate] = useState(new Date());


  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 10;

  const [originData, setOriginData] = useState([]);
  const [dataOrders, setDataOrders] = useState();

  const toast = useToast();
  const modalFilterDate = useDisclosure();

  const getData = async () => {
    try {
      const q = query(
        collection(db, "orders"),
        where("module", "==", "rms"),
        where("outlet", "==", globalState.currentOutlet),
        orderBy("createdAt", "desc"),
        limit(startIndex + itemsPerPage)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        });
        setData(data);
        setDataOrders(data);
        setOriginData(data);
        handleToday(data);
      });

      // Unsubscribe from the snapshot listener when the component unmounts
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage); // Tambahkan jumlah data per halaman saat tombol "Load More" diklik
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const getDataFilter = async () => {
    const conditions = [
      {
        field: "module",
        operator: "==",
        value: "rms",
      },
      {
        field: "createdAt",
        operator: ">=",
        value: selectedDateRange?.startDate,
      },
      {
        field: "createdAt",
        operator: "<=",
        value: selectedDateRange?.endDate,
      },
      {
        field: "outlet",
        operator: "==",
        value: globalState?.currentOutlet,
      },
    ];

    try {
      const filteredOrders = await getCollectionFirebase("orders", conditions);
      setDataOrders(filteredOrders);
      setData(filteredOrders);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const getDataRefund = async () => {
    try {
      const q = query(
        collection(db, "refunds"),
        where("module", "==", "rms"),
        where("outlet", "==", globalState.currentOutlet),
        orderBy("lastUpdated", "desc"),
        limit(25)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push(docData);
        });
        setDataRefunded(data);
        handleTodayRefund(data);
      });

      // Unsubscribe from the snapshot listener when the component unmounts
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleSearchFunction = (text) => {
    if (text !== "") {
      const newData = data.filter((order) => {
        const itemData = order.name
          ? order.name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataOrders(newData);
      // setInputSearch(text);
    } else {
      setDataOrders(originData);
      // setInputSearch(text);
    }
  };

  const handleTodayRefund = (data) => {
    const today = new Date(); // Mendapatkan tanggal hari ini
    const startOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ); // Waktu mulai hari ini (00:00)
    const endOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59
    ); // Waktu akhir hari ini (24:00)

    const filteredData = data.filter((order) => {
      const orderTime = new Date(order.lastUpdated.seconds * 1000); // Konversi timestamp order ke objek Date
      return orderTime >= startOfDay && orderTime <= endOfDay; // Filter data berdasarkan waktu hari ini
    });

    setDataRefundToday(filteredData);

    const totalOverall = filteredData.reduce(
      (total, order) => total + order.totalPrice,
      0
    );

    setAmountRefundNow(totalOverall);
  };

  const handleToday = (data) => {
    const today = new Date(); // Mendapatkan tanggal hari ini
    const startOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ); // Waktu mulai hari ini (00:00)
    const endOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59
    ); // Waktu akhir hari ini (24:00)

    const filteredData = data.filter((order) => {
      const orderTime = new Date(order.createdAt.seconds * 1000); // Konversi timestamp order ke objek Date
      return orderTime >= startOfDay && orderTime <= endOfDay; // Filter data berdasarkan waktu hari ini
    });

    setDataToday(filteredData);

    const totalOverall = filteredData.reduce(
      (total, order) => total + order.totalPrice,
      0
    );
    const totalOverallDiscount = filteredData.reduce(
      (total, order) => total + order.discount,
      0
    );
    const totalOverallTax = filteredData.reduce(
      (total, order) =>
        total + (order.totalPrice - (order.discount || 0)) * (order.tax / 100),
      0
    );

    setAmountNow(totalOverall);
    setAmountTaxNow(totalOverallTax);
    setAmountDiscountNow(totalOverallDiscount);
  };

  const handleReportModal = async () => {
    const today = new Date(selectedDate);
    today.setHours(0, 0, 0, 0);

    const tomorrow = new Date(selectedDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    try {
      const snapshot = await getDocs(
        query(
          collection(db, "orders"),
          where("outlet", "==", globalState.currentOutlet),
          where("module", "==", "rms"),
          where("createdAt", ">=", today),
          where("createdAt", "<", tomorrow)
        )
      );

      const dataReport = [];
      const promises = snapshot.docs.map(async (doc) => {
        const orderData = { id: doc.id, ...doc.data() };
        dataReport.push(orderData);
      });

      await Promise.all(promises);

      const filteredDataReport = dataReport.filter(
        (order) =>
          order.on_report_at === undefined &&
          order.on_report_person === undefined
      );

      const totalOverall = filteredDataReport.reduce(
        (total, order) => total + order.totalPrice,
        0
      );
      const totalOverallDiscount = filteredDataReport.reduce(
        (total, order) => total + order.discount,
        0
      );
      const totalOverallTax = filteredDataReport.reduce(
        (total, order) =>
          total +
          (order.totalPrice - (order.discount || 0)) * (order.tax / 100),
        0
      );

      const paymentTotals = {};

      filteredDataReport.forEach((order) => {
        const paymentMethod = order.paymentMethod;
        const totalPrice =
          order.totalPrice -
          (order.discount || 0) +
          ((order.totalPrice - (order.discount || 0)) * order.tax) / 100;

        if (paymentTotals[paymentMethod]) {
          paymentTotals[paymentMethod] += totalPrice;
        } else {
          paymentTotals[paymentMethod] = totalPrice;
        }
      });

      // Menghitung total kuantitas di setiap pesanan yang terjual
      const quantities = filteredDataReport.flatMap((order) =>
        order.orders.map((item) => item.quantity)
      );
      const totalQuantity = quantities.reduce(
        (total, quantity) => total + quantity,
        0
      );

      // Menambahkan field "assignment_status" dengan nilai "pending" pada setiap pesanan
      const updatedOrders = filteredDataReport.map((order) => ({
        ...order,
        assignment_status: "pending",
        on_report: true,
        on_report_at: new Date(),
        on_report_person: auth.currentUser.email,
      }));

      const snapshotRefund = await getDocs(
        query(
          collection(db, "refunds"),
          where("outlet", "==", globalState.currentOutlet),
          where("module", "==", "rms"),
          where("lastUpdated", ">=", today),
          where("lastUpdated", "<", tomorrow)
        )
      );

      const dataRefundReport = [];
      const promisesRefund = snapshotRefund.docs.map(async (doc) => {
        const orderData = { id: doc.id, ...doc.data() };
        dataRefundReport.push(orderData);
      });

      await Promise.all(promisesRefund);

      const filteredDataRefundReport = dataRefundReport.filter(
        (order) =>
          order.on_report_at === undefined &&
          order.on_report_person === undefined
      );

      const totalOverallRefund = filteredDataRefundReport.reduce(
        (total, order) => total + order.totalPrice,
        0
      );

      const paymentTotalsRefund = {};

      filteredDataRefundReport.forEach((order) => {
        const paymentMethod = order.paymentMethod;
        const totalPrice = order.totalPrice;

        if (paymentTotalsRefund[paymentMethod]) {
          paymentTotalsRefund[paymentMethod] += totalPrice;
        } else {
          paymentTotalsRefund[paymentMethod] = totalPrice;
        }
      });

      // Menghitung total kuantitas di setiap pesanan yang terjual
      const quantitiesRefund = filteredDataRefundReport.flatMap((order) =>
        order.order_refund.map((item) => item.quantity)
      );
      const totalQuantityRefund = quantitiesRefund.reduce(
        (total, quantity) => total + quantity,
        0
      );

      // Menambahkan field "assignment_status" dengan nilai "pending" pada setiap pesanan
      const updatedOrdersRefund = filteredDataRefundReport.map((order) => ({
        ...order,
        assignment_status: "pending",
        on_report: true,
        on_report_at: new Date(),
        on_report_person: auth.currentUser.email,
      }));

      const dataObject = {
        outlet: globalState.currentOutlet,
        companyId: globalState.currentCompany,
        payment_total_methods: paymentTotals,
        payment_total_refund_methods: paymentTotalsRefund,
        payment_total: totalOverall,
        payment_total_refund: totalOverallRefund,
        payment_total_tax: totalOverallTax,
        payment_total_discount: totalOverallDiscount,
        product_stock: totalQuantity,
        product_refund: totalQuantityRefund,
        detail_order: updatedOrders,
        detail_order_refund: updatedOrdersRefund,
        shift: shift,
        reported_at: new Date(),
        person_in_charge: auth.currentUser.email,
        person_in_charge_ID: auth.currentUser.uid,
        module: "rms",
        type_reports: "order",
      };

      setReportData(dataObject);
      console.log(dataObject, "yyy");
      setReportModal(true);
    } catch (error) {
      console.log("Error fetching orders:", error);
    }
  };

  const handleSaveReport = async () => {
    try {
      const reportDocRef = await addDocumentFirebase(
        "reports",
        reportData,
        globalState.currentCompany
      );

      // Update orders with report information
      const updatePromises = reportData.detail_order.map(async (order) => {
        const orderDocRef = doc(db, "orders", order.id);

        await updateDoc(orderDocRef, {
          on_report_at: new Date(),
          on_report_person: auth.currentUser.email,
          on_report_shift: shift,
        });

        // Remove order document from the "orders" collection
        // await deleteDoc(orderDocRef);
      });

      await Promise.all(updatePromises);

      const updateRefundPromises = reportData.detail_order_refund.map(
        async (refund) => {
          const orderDocRef = doc(db, "refunds", refund.id);

          await updateDoc(orderDocRef, {
            on_report_at: new Date(),
            on_report_person: auth.currentUser.email,
            on_report_shift: shift,
          });

          // Remove order document from the "orders" collection
          // await deleteDoc(orderDocRef);
        }
      );

      await Promise.all(updateRefundPromises);

      toast({
        title: "Success",
        description: "success add report",
        position: "top",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setReportData("");
      setReportModal(false);
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
      toast({
        title: "Error",
        description: "Terjadi kesalahan",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const valueOrderOnProcess = () => {
    const datas = dataToday.filter((datas) => {
      return datas.orderStatus === "onProcess";
    });

    let arr = [];

    datas.map((list) => {
      list.orders.map((val) => {
        arr.push(val.quantity);
      });
    });

    const value = arr.reduce((partialSum, a) => partialSum + a, 0);

    return value;
  };

  const valueOrderDone = () => {
    const datas = dataToday.filter((datas) => {
      return datas.orderStatus === "done";
    });

    let arr = [];

    datas.map((list) => {
      list.orders.map((val) => {
        arr.push(val.quantity);
      });
    });

    const value = arr.reduce((partialSum, a) => partialSum + a, 0);

    return value;
  };

  const valueOrderRefundDone = () => {
    const datas = dataRefundToday.filter((datas) => {
      return datas.paymentRefundStatus === "complete";
    });

    let arr = [];

    datas.map((list) => {
      list.order_refund.map((val) => {
        arr.push(val.quantity);
      });
    });

    const value = arr.reduce((partialSum, a) => partialSum + a, 0);

    return value;
  };

  const getRefundItem = () => {
    //GET data refunded from local storage
    const items = JSON.parse(localStorage.getItem("refund_items"));
    if (items) {
      setItemRefunded(items);
    } else {
      localStorage.setItem("refund_items", JSON.stringify([]));
    }
  };

  const totalItems = data?.length || dataOrders?.length || 0;
  const shouldShowLoadMore = totalItems >= startIndex + itemsPerPage;

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  useEffect(() => {
    if (globalState.currentCompany) {
      if (!selectedDateRange) {
        getData();
      } else {
        getDataFilter();
      }

      getRefundItem();
      getDataRefund();
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalState.currentOutlet,
    globalState.currentCompany,
    view,
    startIndex,
    selectedDateRange,
  ]);

  return (
    <Box>
      <Flex
        direction={["column", "row", "row"]}
        justifyContent="space-between"
        mt={3}
      >
        <BackButtons />

        <Flex
          direction={["column", "row", "row"]}
          py={3}
          justifyContent="end"
          gap={5}
        >
          <Link to={"new"}>
            <Button
              bgColor={"white"}
              shadow="md"
              variant="outline"
              borderColor="#F05A28"
              color="#F05A28"
            >
              <HStack>
                <FcPlus />
                <Text>Order</Text>
              </HStack>
            </Button>
          </Link>

          <Button
            onClick={() => {
              setView("Report_Refund");
            }}
            bgColor={"white"}
            shadow="md"
            variant="outline"
            borderColor="#F05A28"
            color="#F05A28"
          >
            <HStack>
              <FcPlus />
              <Text>Refund Order</Text>
            </HStack>
          </Button>

          <Button
            onClick={() => {
              setView("REPORT");
            }}
            bgColor={"white"}
            shadow="md"
            variant="outline"
            borderColor="#F05A28"
            color="#F05A28"
          >
            <HStack>
              <FcPlus />
              <Text>Report Order</Text>
            </HStack>
          </Button>
        </Flex>
      </Flex>

      {view === "TABLE" ? (
        <>
          <HStack my={3}>
            <Input
              bg={"white"}
              my={2}
              placeholder={"Search orders ... "}
              w={250}
              sx={inputStyles}
              onChange={(e) => handleSearchFunction(e.target.value)}
            />
            <Spacer />
            <Button
              onClick={modalFilterDate.onOpen}
              bgColor={"white"}
              shadow="md"
              variant="outline"
              borderColor="#F05A28"
              color="#F05A28"
            >
              <HStack>
                <FaRegCalendar />
                <Text>Filter date</Text>
              </HStack>
            </Button>
          </HStack>

          {data && <TableComponent data={dataOrders} />}

          {shouldShowLoadMore && (
            <Box align={"center"} my={3}>
              <Button
                onClick={handleLoadMore}
                colorScheme={"green"}
                size={"sm"}
              >
                Load More
              </Button>
            </Box>
          )}
        </>
      ) : view === "REPORT" ? (
        <>

         

          <ReportOrderTable
            amountNow={amountNow}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            amountTaxNow={amountTaxNow}
            amountDiscountNow={amountDiscountNow}
            dataToday={dataToday}
            valueOrderDone={valueOrderDone}
            valueOrderOnProcess={valueOrderOnProcess}
            amountRefundNow={amountRefundNow}
            setShift={setShift}
            dataRefundToday={dataRefundToday}
            handleReportModal={handleReportModal}
            valueOrderRefundDone={valueOrderRefundDone}
            setView={setView}
            setReportDetailModal={setReportDetailModal}
          />

        </>
      ) : view === "Report_Refund" ? (
        <>
          <Heading size={"md"} mt={5} mb={5} textAlign="center">
            Refund
          </Heading>

          <RefundTable data={dataRefunded} />

          <Button borderRadius="lg" onClick={() => setView("TABLE")}>
            Back to orders
          </Button>
        </>
      ) : null}

      {reportData && (
        <Modal isOpen={reportModal} onClose={() => setReportModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Reports</ModalHeader>

            <ModalCloseButton />

            <ModalBody pb={6}>
              <Stack>
                <HStack alignItems={"flex-end"} justifyContent="flex-end">
                  <Text fontSize={"sm"} color="gray.700">
                    {moment(reportData?.reported_at).format("MMMM Do YYYY")}
                  </Text>
                  <Text fontSize={"sm"} color="gray.700">
                    {moment(reportData?.reported_at).format("h:mm:ss a")}
                  </Text>
                </HStack>

                <HStack>
                  <Text color="gray.600">Company :</Text>
                  <Spacer />
                  <Text textTransform={"capitalize"} fontWeight={500}>
                    {getCompanyName(globalState, reportData.companyId)}
                  </Text>
                </HStack>

                <HStack>
                  <Text color="gray.600">Outlet :</Text>
                  <Spacer />
                  <Text textTransform={"capitalize"} fontWeight={500}>
                    {getStoreName(globalState, reportData.outlet)}
                  </Text>
                </HStack>

                <HStack>
                  <Text color="gray.600">Shift :</Text>
                  <Spacer />
                  <Text textTransform={"uppercase"} fontWeight={500}>
                    {reportData.shift}
                  </Text>
                </HStack>

                <HStack>
                  <Text color="gray.600">PIC :</Text>
                  <Spacer />
                  <Text fontWeight={500}>{reportData.person_in_charge}</Text>
                </HStack>

                <HStack>
                  <Text color="gray.600">Type Reports :</Text>
                  <Spacer />
                  <Text fontWeight={500}>{reportData.type_reports}</Text>
                </HStack>

                <HStack>
                  <Text color="gray.600">Sold Item:</Text>
                  <Spacer />
                  <Text textTransform={"capitalize"} fontWeight={500}>
                    {reportData.product_stock}
                  </Text>
                </HStack>

                <HStack>
                  <Text color="gray.600">Order Total :</Text>
                  <Spacer />
                  <Text textTransform={"capitalize"} fontWeight={500}>
                    {reportData.detail_order.length}
                  </Text>
                </HStack>

                <HStack>
                  <Text color="gray.600">Refund Item:</Text>
                  <Spacer />
                  <Text textTransform={"capitalize"} fontWeight={500}>
                    {reportData.product_refund}
                  </Text>
                </HStack>

                <HStack>
                  <Text color="gray.600">Refund Total :</Text>
                  <Spacer />
                  <Text textTransform={"capitalize"} fontWeight={500}>
                    {reportData.detail_order_refund.length}
                  </Text>
                </HStack>

                <Divider />

                <Text fontWeight={500}>INCOME</Text>

                <HStack>
                  <Text color="gray.600">Amount :</Text>
                  <Spacer />
                  <Text fontWeight={500}>
                    Rp. {formatFrice(reportData.payment_total)}
                  </Text>
                </HStack>
                <HStack>
                  <Text color="gray.600">Discount :</Text>
                  <Spacer />
                  <Text color={"red"} fontWeight={500}>
                    Rp. {formatFrice(reportData.payment_total_discount)}
                  </Text>
                </HStack>
                <HStack>
                  <Text color="gray.600">Tax :</Text>
                  <Spacer />
                  <Text fontWeight={500}>
                    Rp. {formatFrice(reportData.payment_total_tax)}
                  </Text>
                </HStack>
                <Divider />

                <HStack>
                  <Text color="gray.600">Amount Income :</Text>
                  <Spacer />
                  <Text fontWeight={"bold"} fontSize="lg">
                    Rp.{" "}
                    {formatFrice(
                      reportData.payment_total -
                      reportData.payment_total_discount +
                      reportData.payment_total_tax
                    )}
                  </Text>
                </HStack>
                <Divider />

                <Stack>
                  <Text color="gray.600" fontSize={"sm"}>
                    Method :
                  </Text>
                  <SimpleGrid columns={[2]} gap={1}>
                    {Object.entries(reportData.payment_total_methods).map(
                      ([method, amount]) => (
                        <Stack
                          key={method}
                          shadow="md"
                          p={2}
                          borderWidth={0.5}
                          alignItems="center"
                          justifyContent={"center"}
                          borderRadius="md"
                          borderColor={"green.500"}
                          spacing={0}
                        >
                          <Text fontSize={"xs"} textTransform="capitalize">
                            {method}
                          </Text>
                          <Text
                            fontWeight={"bold"}
                            variant="solid"
                            colorScheme="blue"
                          >
                            Rp. {formatFrice(amount)}
                          </Text>
                        </Stack>
                      )
                    )}
                  </SimpleGrid>
                </Stack>

                <Divider />

                <Text fontWeight={500}>OUTCOME</Text>

                <HStack>
                  <Text color="gray.600">Amount :</Text>
                  <Spacer />
                  <Text fontWeight={500}>
                    Rp. {formatFrice(reportData.payment_total_refund)}
                  </Text>
                </HStack>
                <Divider />

                <HStack>
                  <Text color="gray.600">Amount Outcome :</Text>
                  <Spacer />
                  <Text fontWeight={"bold"} fontSize="lg">
                    Rp. {formatFrice(reportData.payment_total_refund)}
                  </Text>
                </HStack>
                <Divider />

                <Stack>
                  <Text color="gray.600" fontSize={"sm"}>
                    Method :
                  </Text>
                  <SimpleGrid columns={[2]} gap={1}>
                    {Object.entries(
                      reportData.payment_total_refund_methods
                    ).map(([method, amount]) => (
                      <Stack
                        key={method}
                        shadow="md"
                        p={2}
                        borderWidth={0.5}
                        alignItems="center"
                        justifyContent={"center"}
                        borderRadius="md"
                        borderColor={"green.500"}
                        spacing={0}
                      >
                        <Text fontSize={"xs"} textTransform="capitalize">
                          {method}
                        </Text>
                        <Text
                          fontWeight={"bold"}
                          variant="solid"
                          colorScheme="blue"
                        >
                          Rp. {formatFrice(amount)}
                        </Text>
                      </Stack>
                    ))}
                  </SimpleGrid>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="green"
                mr={3}
                onClick={() => handleSaveReport()}
              >
                Save
              </Button>
              <Button colorScheme="red" onClick={() => setReportModal(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {dataToday && (
        <Modal
          size={"3xl"}
          isOpen={reportDetailModal}
          onClose={() => setReportDetailModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Reports Detail</ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <Stack>{dataToday && <TableComponent data={dataToday} />}</Stack>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <Modal
        isOpen={modalFilterDate.isOpen}
        onClose={modalFilterDate.onClose}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalBody>
            <Center>
              <DatePicker onDateChange={handleDateRangeChange} />
            </Center>

            <HStack>
              <HStack spacing={4}>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={"semibold"}>Start Date: </Text>
                  <Text>
                    {moment(selectedDateRange?.startDate).format("LLL")}
                  </Text>
                </Stack>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={"semibold"}>End Date:</Text>
                  <Text>
                    {moment(selectedDateRange?.endDate).format("LLL")}
                  </Text>
                </Stack>
              </HStack>
              <Spacer />
              <Button
                size={"xs"}
                colorScheme="blue"
                onClick={() => setSelectedDateRange()}
              >
                Clear Filter
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default OrdersPage;
