import { EditIcon, PhoneIcon } from '@chakra-ui/icons';
import { Button, HStack, Image, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import moment from 'moment';
import React from 'react'
import { getCollectionFirebase } from '../../Apis/firebaseApi';

function LowStockTable({ lowStockData, openModal }) {

    const toast = useToast()

    const handleCall = async (data) => {

        console.log(data.supplier, 'ini data')

        const conditions = [
            { field: "name", operator: "==", value: data.supplier },
        ];
        const sortBy = { field: "name", direction: "asc" };
        const limitValue = 1;

        try {
            const res = await getCollectionFirebase(
                "suppliers",
                conditions,
                sortBy,
                limitValue
            );

            if (res?.length > 0) {
                if (res[0].phoneNumber) {

                    const message = encodeURIComponent(`Hi Admin ${res[0].name},\n\n😊 .. kami dari RMS Deoapp,`);
                    const source = encodeURIComponent(window.location.href);
                    const url = `https://api.whatsapp.com/send?phone=${res[0].phoneNumber}&text=${message}%0A%0ASource:%20${source}`;
                    window.open(url, '_blank');

                } else {
                    toast({
                        status: "warning",
                        description: "Supplier cannot have phone number",
                        duration: 2000,
                    });
                }
            }
        } catch (error) {
            console.log(error, "ini error");
        }
    };


    return (
        <Table variant="striped" colorScheme="gray" bgcolor='white' shadow={'md'} borderRadius='md'>
            <Thead>
                <Tr>
                    <Th>Product</Th>
                    <Th>Action</Th>
                </Tr>
            </Thead>
            <Tbody>
                {lowStockData.map((item) => (
                    <Tr key={item.id}>
                        <Td>
                            <Stack flexDirection={["column", "column", "row"]} objectFit={'cover'} spacing={5} w='full'>
                                <Image src={item.image_url} width="200px" h={'100px'} alt={item.name} shadow='md' borderRadius={'md'} />
                                <Stack>

                                    <Text fontWeight={500} color='red.700'>{item.supplier}</Text>
                                    <Text fontWeight={500}>{item.name}</Text>
                                    <Text fontWeight={500} textTransform='capitalize'>Stock : {item.stock} {item.quantity_type}</Text>
                                    <Text color={'gray.600'} textTransform={'capitalize'} fontSize='xs'>
                                        {moment(item?.lastUpdated?.seconds * 1000 || item?.createdAt?.seconds * 1000).format("LLLL")}
                                    </Text>
                                </Stack>
                            </Stack>
                        </Td>
                        <Td>
                            <HStack spacing={3}>
                                <Button colorScheme={'green'} size='sm' onClick={() => handleCall(item)}>
                                    <PhoneIcon />
                                </Button>
                                <Button colorScheme={'blue'} size='sm' onClick={() => openModal(item)}>
                                    <EditIcon />
                                </Button>
                            </HStack>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}

export default LowStockTable