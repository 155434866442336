import { ReactNode } from "react";
import {
  Box,
  Flex,
  Button,
  Image,
  HStack,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
  FormLabel,
  useToast,
  Heading,
  Spacer,
} from "@chakra-ui/react";
import {
  SmallAddIcon,
  DeleteIcon,
  EditIcon,
  PhoneIcon,
} from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, ButtonGroup } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../Hooks/Zustand/Store";
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  setDocumentFirebase,
  uploadFile,
} from "../../Apis/firebaseApi";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../Config/firebase";
import { MdOutlinePermMedia } from "react-icons/md";
import { FcPlus } from "react-icons/fc";
import moment from "moment";
import BackButtons from "../../Components/Buttons/BackButtons";

function SupplierPage() {
  const toast = useToast();
  const [data, setData] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [modal, setModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [files, setFiles] = useState([]);
  const [filesImage, setFilesImage] = useState([]);

  const [supplierActive, setSupplierActive] = useState("");

  const [originData, setOriginData] = useState([]);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const globalState = useUserStore();

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;
    if (newFiles.length) {
      const newFileArray = [...files];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);
        };
      }
      setFilesImage(newFiles); // Mengubah state filesImage menjadi array baru dari selectedFiles
    }
  };

  const fetchDataProduct = async () => {
    try {
      const q = query(
        collection(db, "suppliers"),
        where("companyId", "==", globalState.currentCompany),
        where("projectId", "==", globalState.currentProject),
        where("outletId", "==", globalState.currentOutlet)
      );
      const querySnapshot = await getDocs(q);
      const suppliersData = [];

      querySnapshot.forEach((doc) => {
        suppliersData.push({ id: doc.id, ...doc.data() });
      });
      setDataProduct(suppliersData);
      setOriginData(suppliersData);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  };

  const handleSearchFunction = (text) => {
    if (text !== "") {
      const newData = dataProduct.filter((order) => {
        const itemData = order.name
          ? order.name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataProduct(newData);
      // setInputSearch(text);
    } else {
      setDataProduct(originData);
      // setInputSearch(text);
    }
  };

  useEffect(() => {
    fetchDataProduct();
  }, [globalState.currentOutlet]);

  const handleAddProduct = async () => {
    try {
      setEditItem(null);
      setModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = async (dataProduct) => {
    setSupplierActive(dataProduct);
    setModal(true);
  };

  const handleDelete = async (dataProduct) => {
    const collectionName = "suppliers";
    const docName = dataProduct; // Pastikan docName sudah memiliki nilai yang sesuai

    try {
      const result = await deleteDocumentFirebase(collectionName, docName);
      console.log("Dokumen berhasil dihapus:", result);

      fetchDataProduct();

      // Tambahkan logika tambahan jika perlu, seperti menampilkan pesan sukses atau pemuatan ulang data.
    } catch (error) {
      console.error("Gagal menghapus dokumen:", error);
      // Tambahkan logika tambahan jika perlu, seperti menampilkan pesan kesalahan kepada pengguna.
    }
  };

  const handleSubmitProduct = async () => {
    setLoading(true);
    const newProductData = {
      ...data,
      projectId: globalState.currentProject,
      outletId: globalState.currentOutlet,
    };

    let updateData = newProductData;

    if (filesImage[0]) {
      const resImage = await uploadFile(
        `${updateData.name}-${moment(new Date()).valueOf()}`,
        "suppliers",
        filesImage[0]
      );
      updateData.image_url = resImage;
    }

    const collectionName = "suppliers";
    try {
      const docID = await addDocumentFirebase(
        collectionName,
        updateData,
        globalState.currentCompany
      );
      console.log("ID Dokumen Baru:", docID);
      setModal(false);
      fetchDataProduct();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditProduct = async () => {
    setLoading(true);
    const newProductData = {
      ...data,
      projectId: globalState.currentProject,
      outletId: globalState.currentOutlet,
    };

    let updateData = newProductData;

    if (filesImage[0]) {
      const resImage = await uploadFile(
        `${updateData.name}-${moment(new Date()).valueOf()}`,
        "suppliers",
        filesImage[0]
      );
      updateData.image_url = resImage;
    }

    const collectionName = "suppliers";
    try {
      const docID = await setDocumentFirebase(
        collectionName,
        supplierActive.id,
        updateData
      );
      console.log("ID Dokumen Baru:", docID);
      setModal(false);
      fetchDataProduct();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModal(false);
    setSupplierActive("");
  };

  const handleCall = async (data) => {
    const message = encodeURIComponent(
      `Hi Admin ${data.name},\n\n😊 .. kami dari RMS Deoapp,`
    );
    const source = encodeURIComponent(window.location.href);
    const url = `https://api.whatsapp.com/send?phone=${data.phoneNumber}&text=${message}%0A%0ASource:%20${source}`;
    window.open(url, "_blank");
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <Stack
        flexDirection={["column", "row", "row"]}
        gap={5}
        justifyContent="space-between"
        w="full"
      >
        <HStack>
          <BackButtons />
          <Heading>Supplier</Heading>
        </HStack>
        <Spacer />

        <Button
          bgColor={"white"}
          onClick={() => handleAddProduct()}
          shadow="md"
          variant="outline"
          borderColor="#F05A28"
          color="#F05A28"
        >
          <HStack>
            <FcPlus />
            <Text>Supplier</Text>
          </HStack>
        </Button>
      </Stack>

      <Input
        my={2}
        w={250}
        type="text"
        placeholder="Search Orders ..."
        bgColor="white"
        color="black"
        sx={inputStyles}
        fontSize="sm"
        onChange={(e) => handleSearchFunction(e.target.value)}
      />

      <Box overflowX={"scroll"}>
        <Table variant="striped" bgcolor="white" shadow={"md"}>
          <Thead>
            <Tr>
              <Th>logo</Th>
              <Th>Supplier Name</Th>
              <Th>Address</Th>
              <Th>Description</Th>
              <Th>Phone Number</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dataProduct.map((k, index) => (
              <Tr key={index}>
                <Td>
                  <Box>
                    <Image
                      objectFit={"cover"}
                      w="300px"
                      src={
                        k?.image_url
                          ? k?.image_url
                          : "https://bit.ly/dan-abramov"
                      }
                      alt="Dan Abramov"
                    />
                  </Box>
                </Td>
                <Td>
                  <Stack>
                    <Text fontWeight={500} fontSize="sm">
                      {k.name}
                    </Text>
                  </Stack>
                </Td>
                <Td>
                  <Stack>
                    <Text fontWeight={500} fontSize="sm">
                      {k.address}
                    </Text>
                  </Stack>
                </Td>
                <Td>
                  <Stack>
                    <Text fontWeight={500} fontSize="sm">
                      {k.description}
                    </Text>
                  </Stack>
                </Td>
                <Td>
                  <Stack>
                    <Text fontWeight={500} fontSize="sm">
                      {k.phoneNumber}
                    </Text>
                  </Stack>
                </Td>
                <Td>
                  <ButtonGroup>
                    <Button
                      colorScheme="green"
                      size={"sm"}
                      onClick={() => handleCall(k)}
                    >
                      <PhoneIcon />
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme="blue"
                      onClick={() => handleEdit(k)}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme="red"
                      onClick={() => handleDelete(k.id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </ButtonGroup>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Modal
        size={"lg"}
        onClose={handleCloseModal}
        isOpen={modal}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Supplier</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Stack>
                <FormLabel>Name</FormLabel>
                <Input
                  defaultValue={supplierActive?.name || ""}
                  type="text"
                  placeholder="Supplier name"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Stack>

              <Stack>
                <FormLabel>Address</FormLabel>
                <Input
                  defaultValue={supplierActive?.address || ""}
                  type="text"
                  placeholder="Supplier name"
                  onChange={(e) =>
                    setData({ ...data, address: e.target.value })
                  }
                />
              </Stack>

              <Stack>
                <FormLabel>Description</FormLabel>
                <Input
                  defaultValue={supplierActive?.description || ""}
                  type="text"
                  placeholder="Supplier Description"
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                />
              </Stack>

              <Stack>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  defaultValue={supplierActive?.phoneNumber || ""}
                  placeholder="Supplier Phone"
                  type="text"
                  onChange={(e) =>
                    setData({ ...data, phoneNumber: e.target.value })
                  }
                />
              </Stack>

              <Stack>
                <Box py={3}>
                  <HStack>
                    {files.length > 0 ? (
                      <Stack>
                        <Image
                          src={files[0].file}
                          boxSize="100%"
                          maxWidth={300}
                          borderRadius="xl"
                          alt={files[0].name}
                          shadow="sm"
                        />
                      </Stack>
                    ) : (
                      <Image
                        src={
                          supplierActive?.image_url
                            ? supplierActive?.image_url
                            : "https://bit.ly/dan-abramov"
                        }
                        alt="Dan Abramov"
                      />
                    )}
                  </HStack>

                  <Stack>
                    <Input
                      type="file"
                      onChange={handleFileInputChange}
                      display="none"
                      id="fileInput"
                    />

                    <label htmlFor="fileInput">
                      <HStack cursor={"pointer"}>
                        <Stack>
                          <MdOutlinePermMedia />
                        </Stack>
                        <Text
                          fontSize={"sm"}
                          color="blue.600"
                          fontStyle={"italic"}
                        >
                          Add Image
                        </Text>
                      </HStack>
                    </label>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleCloseModal}>
              Close
            </Button>
            <Button
              isLoading={loading}
              colorScheme="blue"
              onClick={supplierActive ? handleEditProduct : handleSubmitProduct}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default SupplierPage;
