import React from 'react'
import StationsPage from '../Pages/Stations/StationsPage'
import StationViewPage from '../Pages/Stations/StationViewPage'
import StationViewServerPage from '../Pages/Stations/StationViewServerPage'

const StationRouter = [
	{
		path: "/stations",
		element: <StationsPage />
	},
	{
		path: "/stations/:id",
		element: < StationViewPage />
	},

	{
		path: "/stations/server",
		element: < StationViewServerPage />
	},

]

export default StationRouter